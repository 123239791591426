import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { AppFetchBaseQuery } from './apiBase';
import { Notification, NotificationCreateForm, NotificationEditForm } from '@backlinkit/shared';

export const notificationsApi = createApi({
  reducerPath: 'notificationsApi',
  baseQuery: AppFetchBaseQuery,
  endpoints: (build) => ({
    fetchNotifications: build.query<Notification[], void>({
      query: () => ({
        url: `/notification/`,
        method: 'GET',
      }),
    }),
    fetchNotificationByOrganization: build.query<Notification[], string>({
      query: (id) => ({
        url: `/notification/by/organization/${id}`,
        method: 'GET',
      }),
    }),
    fetchNotificationByUser: build.query<Notification[], string>({
      query: (id) => ({
        url: `/notification/by/organization/${id}`,
        method: 'GET',
      }),
    }),
    fetchNotificationById: build.query<Notification, string>({
      query: (id) => ({
        url: `/notification/${id}`,
        method: 'GET',
      }),
    }),
    createNotification: build.mutation<Notification, NotificationCreateForm>({
      query: (payload) => ({
        url: '/notification/create',
        method: 'POST',
        body: payload,
      }),
      transformResponse: (response: Notification) => {
        return response;
      },
    }),
    updateNotification: build.mutation<Notification, NotificationEditForm>({
      query: (payload) => ({
        url: `/notification/update/${payload.id}`,
        method: 'PUT',
        body: payload,
      }),
      transformResponse: (response: Notification) => {
        return response;
      },
    }),
    deleteNotification: build.mutation<Notification, string>({
      query: (id) => ({
        url: `/notification/delete/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useFetchNotificationsQuery,
  useCreateNotificationMutation,
  useDeleteNotificationMutation,
  useFetchNotificationByIdQuery,
  useFetchNotificationByOrganizationQuery,
  useFetchNotificationByUserQuery,
  useUpdateNotificationMutation,
} = notificationsApi;
