import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Links from '../pages/links/links';
import Login from '../pages/login/login';
import ProtectedRoute from './protected-route';
import { AuthOutlet, RouterOutlet } from './router-outlet';
import UserProfile from '../pages/userProfile/userProfile';
import Dashboard from '../pages/dashboard/dashboard';
import { UserPage } from '../pages/users/users';
import FAQPage from '../pages/faq/faq-crud';
import DomainsPage from '../pages/domains/domains';
import Organizations from '../pages/organization/organizations';
import LinkReportsPage from '../pages/link-reports/link-reports';
import ExternalReportsPage from '../pages/external-report/external-report';
import FAQCategoryPage from '../pages/faq-categories/faq-categories';
import Products from '../pages/products/products';
import NicheTypes from '../pages/niche-types/niche-types';
import DomainUserRoles from '../pages/domainUserRoleTypes/domain-user-roles';
import Batches from '../pages/backlink-batch/batches';
import BatchTypes from '../pages/batch-types/batch-types';
import OrganizationTypes from '../pages/organization-types/organization-types';
import OrganizationBudgetTypes from '../pages/organization-budget-types/organization-budget-types';
import OrganizationSEOExperienceTypes from '../pages/organization-seo-experience-type/organization-seo-experience-type';
import OrganizationLinkEstimationTypes from '../pages/organization-link-estimation-types/organization-link-estimation-types';
import OrganizationLinkBuildingStratergiesTypes from '../pages/organization-link-building-strategies-types/organization-link-building-strategies-types';
import Bugs from '../pages/bugs/bugs';
import Notifications from '../pages/notifications/notifications';
import OrganizationSourceEntityTypes from '../pages/organization-source-entity-type/organization-source-entity-types';

export enum RoutesList {
  Index = '/',
  Dashboard = '/dashboard',
  Links = '/links',
  LinksReports = '/links/reports',
  Domains = '/domains',
  Organizations = '/organizations',
  OrganizationTypes = '/organizaion/types',
  OrganizationBudgetTypes = '/organization/budget/types',
  OrganizationSEOExperienceTypes = '/organization/seo-experience/types',
  OrganizationLinkExperienceTypes = '/organization/link-experience/types',
  OrganziationLinkBuildingStrategiesTypes = '/organization/link-building-strategies/types',
  OrganizationSourceEntityTypes = '/organization/source-entity-types',
  Users = '/users',
  UserProfile = '/user-profile',
  FAQ = '/faq',
  ExternalReports = '/external-reports/:id',
  FaqCategories = '/faq-categories',
  Prodcuts = '/products',
  NicheTypes = '/niche-types',
  DomainUserRoles = '/domain/user-roles',
  Batches = '/batches',
  BatchTypes = '/batches/types',
  Bugs = '/bugs',
  Notifications = '/notifications',
}

const Router: React.FC = () => {
  return (
    <BrowserRouter basename={'/'}>
      <Routes>
        <Route path="/" element={<AuthOutlet />}>
          <Route index element={<Login />} />
        </Route>
        <Route path="/" element={<RouterOutlet />}>
          <Route
            path="dashboard"
            element={
              <ProtectedRoute route={RoutesList.Dashboard}>
                <Dashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="links"
            element={
              <ProtectedRoute route={RoutesList.Links}>
                <Links />
              </ProtectedRoute>
            }
          />
          <Route
            path="user-profile"
            element={
              <ProtectedRoute route={RoutesList.UserProfile}>
                <UserProfile />
              </ProtectedRoute>
            }
          />
          <Route
            path="users"
            element={
              <ProtectedRoute route={RoutesList.Users}>
                <UserPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="domains"
            element={
              <ProtectedRoute route={RoutesList.Domains}>
                <DomainsPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="organizations"
            element={
              <ProtectedRoute route={RoutesList.Organizations}>
                <Organizations />
              </ProtectedRoute>
            }
          />
          <Route
            path="organization-types"
            element={
              <ProtectedRoute route={RoutesList.OrganizationTypes}>
                <OrganizationTypes />
              </ProtectedRoute>
            }
          />
          <Route
            path="organization-budget-types"
            element={
              <ProtectedRoute route={RoutesList.OrganizationBudgetTypes}>
                <OrganizationBudgetTypes />
              </ProtectedRoute>
            }
          />
          <Route
            path="organization-seo-experience-types"
            element={
              <ProtectedRoute route={RoutesList.OrganizationSEOExperienceTypes}>
                <OrganizationSEOExperienceTypes />
              </ProtectedRoute>
            }
          />
          <Route
            path="organization-link-experience-types"
            element={
              <ProtectedRoute route={RoutesList.OrganizationLinkExperienceTypes}>
                <OrganizationLinkEstimationTypes />
              </ProtectedRoute>
            }
          />
          <Route
            path="organization-link-building-strategies-types"
            element={
              <ProtectedRoute route={RoutesList.OrganziationLinkBuildingStrategiesTypes}>
                <OrganizationLinkBuildingStratergiesTypes />
              </ProtectedRoute>
            }
          />
          <Route
            path="organization-source-entity-types"
            element={
              <ProtectedRoute route={RoutesList.OrganizationSourceEntityTypes}>
                <OrganizationSourceEntityTypes />
              </ProtectedRoute>
            }
          />
          <Route
            path="faq"
            element={
              <ProtectedRoute route={RoutesList.FAQ}>
                <FAQPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="links/reports"
            element={
              <ProtectedRoute route={RoutesList.LinksReports}>
                <LinkReportsPage />
              </ProtectedRoute>
            }
          />
          <Route path="external-report/:id" element={<ExternalReportsPage />} />
          <Route
            path="faq-categories"
            element={
              <ProtectedRoute route={RoutesList.FaqCategories}>
                <FAQCategoryPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="products"
            element={
              <ProtectedRoute route={RoutesList.Prodcuts}>
                <Products />
              </ProtectedRoute>
            }
          />
          <Route
            path="niche-types"
            element={
              <ProtectedRoute route={RoutesList.NicheTypes}>
                <NicheTypes />
              </ProtectedRoute>
            }
          />
          <Route
            path="domain/user-roles"
            element={
              <ProtectedRoute route={RoutesList.DomainUserRoles}>
                <DomainUserRoles />
              </ProtectedRoute>
            }
          />
          <Route
            path="batches"
            element={
              <ProtectedRoute route={RoutesList.Batches}>
                <Batches />
              </ProtectedRoute>
            }
          />
          <Route
            path="batches/types"
            element={
              <ProtectedRoute route={RoutesList.BatchTypes}>
                <BatchTypes />
              </ProtectedRoute>
            }
          />
          <Route
            path="bugs"
            element={
              <ProtectedRoute route={RoutesList.Bugs}>
                <Bugs />
              </ProtectedRoute>
            }
          />
          <Route
            path="notifications"
            element={
              <ProtectedRoute route={RoutesList.Notifications}>
                <Notifications />
              </ProtectedRoute>
            }
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
