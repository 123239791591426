import { Flex } from '@chakra-ui/react';
import React from 'react';

export const LoadingComponent: React.FC = ({}) => {
  return (
    <Flex
      position={'absolute'}
      top={0}
      bottom={0}
      right={0}
      left={0}
      zIndex={2}
      justifyContent="center"
      align={'center'}
      background="#0000003a"
    >
      <div className="loader"></div>
    </Flex>
  );
};
