import {
  Flex,
  Icon,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  useToast,
} from '@chakra-ui/react';
import React from 'react';
import { BaseBacklinkCell } from './base-backlink-cell';
import { HiOutlineClipboardCopy } from 'react-icons/hi';
import { BacklinkTypes, AppText, AppColors } from '@backlinkit/shared';
import { useNavigate } from 'react-router-dom';

type LandinPageCellProps = BaseBacklinkCell & {
  domainUrl: string;
};

const LandinPageCell: React.FC<LandinPageCellProps> = ({ item, domainUrl }) => {
  const toast = useToast();
  const navigate = useNavigate();

  const getFullUrl = () => {
    if (item.backlinkType === BacklinkTypes.tierOne) {
      if (domainUrl.endsWith('/') && item.landingPage?.startsWith('/')) {
        return `${domainUrl.substring(0, domainUrl.length - 1)}${item.landingPage}`;
      } else {
        return `${domainUrl}${item.landingPage}`;
      }
    } else {
      return item.landingPage;
    }
  };

  const handleLangingPageClick = async () => {
    window.open(getFullUrl(), 'blank');
  };

  const handleCopyToClipboardClick = () => {
    navigator.clipboard
      .writeText(getFullUrl() ?? '')
      .then(() => {
        toast({
          title: 'Copied to clipboard',
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
      })
      .catch((e) => {
        toast({
          title: 'Failed to copy to clipboard',
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      });
  };

  return (
    <Flex direction={'row'} alignItems={'center'}>
      {item?.url && (
        <Popover trigger="hover">
          <PopoverTrigger>
            <Flex>
              <AppText
                ml={2}
                maxW={'200px'}
                isTruncated
                _hover={{
                  cursor: 'pointer',
                }}
              >
                {item?.landingPage}
              </AppText>
            </Flex>
          </PopoverTrigger>
          <PopoverContent>
            <PopoverArrow />
            <PopoverBody display={'flex'} flexDir={'row'} alignItems={'center'}>
              <IconButton
                bg={AppColors.secondary}
                color={'white'}
                aria-label="column visibility button"
                isRound={false}
                _hover={{
                  transform: 'translateY(-2px)',
                  boxShadow: 'lg',
                }}
                size="xs"
                mr={'15px'}
                onClick={(event) => {
                  event.stopPropagation();
                  handleCopyToClipboardClick();
                }}
              >
                <Icon as={HiOutlineClipboardCopy} size="1rem" color={'white'} />
              </IconButton>
              <Flex>
                <AppText
                  ml={2}
                  whiteSpace={'pre-wrap'}
                  wordBreak={'break-word'}
                  cursor={'pointer'}
                  onClick={() => handleLangingPageClick()}
                >
                  {getFullUrl()}
                </AppText>
              </Flex>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      )}
    </Flex>
  );
};

export default LandinPageCell;
