import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ProductDto } from '@backlinkit/shared';
import { RootState } from '../store';
import { productsApi } from '../api/productsApi';

interface ProductState {
  products?: ProductDto[];
}

const initialState: ProductState = {
  products: [],
};

export const productsSlice = createSlice({
  name: 'products',
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(
      productsApi.endpoints.fetchAllProducts.matchFulfilled,
      (state, { payload }) => {
        return {
          ...state,
          products: payload,
        };
      }
    );
  },
  reducers: {},
});

export const {} = productsSlice.actions;
export const selectProducts = (state: RootState) => state.products.products;
