import React, { useEffect, useState } from 'react';
import {
  Flex,
  PopoverProps,
  Checkbox,
  Icon,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  useDisclosure,
  Box,
  Tooltip,
} from '@chakra-ui/react';
import { BiColumns } from 'react-icons/bi';
import { AppButton } from '../../app-button/app-button';
import { AppColors } from '../../../theme';
import { ColumnDefinitionType } from '../app-data-table-types';

export type ColumnVisibilityPopoverProps<T extends {}> = {
  columns: Array<ColumnDefinitionType<T>>;
  onSubmit?: (columns: Array<ColumnDefinitionType<T>>) => void;
} & PopoverProps;

export const ColumnVisibilityPopover = <T extends {}>({
  columns,
  onSubmit,
  ...props
}: ColumnVisibilityPopoverProps<T>) => {
  const { isOpen, onToggle, onClose } = useDisclosure();
  const [columnOptions, setColumnOptions] = useState<ColumnDefinitionType<T>[]>([]);
  const [filteredColumns, setFilteredColumns] = useState(columns);

  useEffect(() => {
    const newColumns = columns.filter((x) => x.header !== 'Actions');
    setColumnOptions(newColumns)
    setFilteredColumns(columns);
  }, [columns]);

  useEffect(() => {}, [filteredColumns]);

  const onColumnsSelectChange = (
    checked: boolean,
    column: ColumnDefinitionType<T>,
    index: number
  ) => {
    const newFilteredColumns = [...filteredColumns];
    newFilteredColumns.forEach((col, newIndex) => {
      if (col.key === column.key && index === newIndex) {
        col.isVisible = checked;
      }
    });
    setFilteredColumns(newFilteredColumns);
  };
  const onFilterSubmit = () => {
    onSubmit && onSubmit(filteredColumns);
  };

  return (
    <Popover placement="left-start">
      {({ isOpen, onClose }) => (
        <>
          <Tooltip aria-label="tooltip" placement="bottom" label="Column visibility options">
            <Box>
              <PopoverTrigger>
                <IconButton
                  bg={AppColors.appBackground}
                  color={'white'}
                  aria-label="column visibility button"
                  isRound={true}
                  onClick={onToggle}
                >
                  <Icon as={BiColumns} boxSize={'6'} color={AppColors.primary} />
                </IconButton>
              </PopoverTrigger>
            </Box>
          </Tooltip>

          <Portal>
            <PopoverContent>
              <PopoverArrow />
              <PopoverHeader>Filter Column Visibility</PopoverHeader>
              <PopoverCloseButton />
              <PopoverBody>
                <Flex flexDirection={'column'} p={2} gridGap={1}>
                  {columnOptions.map((col, index) => {
                    return (
                      <Box key={`select-col-visible-${col.key.toString()}-${index}`}>
                        <Tooltip label={col.headerMeta}>
                          <Box display={'inline-block'}>
                            <Checkbox
                              value={`${col.key.toString()}`}
                              defaultChecked={col.isVisible}
                              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                onColumnsSelectChange(event.currentTarget.checked, col, index);
                              }}
                            >
                              {col.header}
                            </Checkbox>
                          </Box>
                        </Tooltip>
                      </Box>
                    );
                  })}
                </Flex>
              </PopoverBody>
              <PopoverFooter display={'flex'} justifyContent={'space-between'}>
                <AppButton
                  minW={'100px'}
                  color={AppColors.primary}
                  onClick={() => {
                    onFilterSubmit();
                    onClose();
                  }}
                >
                  Ok
                </AppButton>
                <AppButton
                  color={AppColors.primary}
                  minW={'100px'}
                  variant={'outline'}
                  onClick={onClose}
                >
                  Cancel
                </AppButton>
              </PopoverFooter>
            </PopoverContent>
          </Portal>
        </>
      )}
    </Popover>
  );
};
