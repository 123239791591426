import {
  Flex,
  Text,
  useColorModeValue,
  useToast,
  Menu,
  MenuList,
  MenuItem,
  MenuButton,
  Button,
} from '@chakra-ui/react';
import BaseLayout from '../../components/layouts/base-layout';
import { ChevronDownIcon, DeleteIcon, SettingsIcon } from '@chakra-ui/icons';
import FAQCreateForm from '../../components/forms/faq-create-form';
import {
  useCreateFaqMutation,
  useFaqDeleteMutation,
  useFaqUpdateMutation,
  useFetchAllFaqsQuery,
} from '../../store/api/faqApi';
import {
  AppButton,
  AppColors,
  AppConfirm,
  AppDataTable,
  AppPaginator,
  AppText,
  ColumnDefinitionType,
  ColumnSpecialTypes,
  EditFAQForm,
  FAQ,
  FAQForm,
  useDataTable,
  useLoading,
  usePanel,
} from '@backlinkit/shared';

const FAQPage: React.FC = () => {
  const title = "FAQ's";
  const { setLoading } = useLoading();
  const toast = useToast();
  const panel = usePanel();
  const { data: faqs, refetch: refetchFaqs } = useFetchAllFaqsQuery({
    refetchOnMountArgChange: true,
  });
  const [createFaq] = useCreateFaqMutation();
  const [editFaq] = useFaqUpdateMutation();
  const [deleteFaq] = useFaqDeleteMutation();

  const {
    handleSearchChange,
    handleSortByColumnClick,
    handleOnPageChange,
    pagination,
    setPagination,
    nextPaginationQueryParams,
  } = useDataTable<FAQ>();

  const handleCreateFAQ = () => {
    panel({
      title: 'Create FAQ',
      size: 'lg',
      render: (onSubmit) => {
        return (
          <FAQCreateForm
            onSubmit={async (formData) => {
              await upsertFaq(formData);
              onSubmit();
            }}
          ></FAQCreateForm>
        );
      },
    });
  };

  const handleEditFAQ = (item: FAQ) => {
    const FAQEditForm: FAQ = {
      id: item.id,
      question: item!.question,
      answer: item!.answer,
      faqCategoryId: item.faqCategoryId,
      isFeatured: item!.isFeatured,
    };
    panel({
      title: 'Edit FAQ',
      size: 'lg',
      render: (onSubmit) => {
        return (
          <FAQCreateForm
            form={FAQEditForm}
            onSubmit={async (formData) => {
              await upsertFaq(formData);
              onSubmit();
            }}
          />
        );
      },
    });
  };

  const handleDeleteFAQ = async (faq: FAQ) => {
    setLoading(true);
    try {
      const handleDelete = deleteFaq(faq.id).unwrap();
      await handleDelete;
    } catch (err) {
      toast({
        title: 'FAQ delete error',
        description:
          "We've run into a problem deleting the selected FAQ's, contact support for help",
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }

    toast({
      title: "Selected FAQ's deleted",
      description: "We've deleted the FAQ's you selected",
      status: 'success',
      duration: 9000,
      isClosable: true,
    });
    refetchFaqs();
    setLoading(false);
  };

  const upsertFaq = async (formData: any) => {
    setLoading(true);
    let eddittedFaq = formData.id ? true : false;
    try {
      if (!eddittedFaq) {
        const createFormData = formData as FAQForm;
        await createFaq({
          faqCategoryId: createFormData.faqCategoryId,
          question: createFormData.question,
          answer: createFormData.answer,
          isFeatured: createFormData.isFeatured,
        }).unwrap();
        console.log('upsert create', formData);
      } else {
        const editFormData = formData as EditFAQForm;
        await editFaq({
          id: editFormData.id ?? '',
          faqCategoryId: editFormData.faqCategoryId,
          question: editFormData.question,
          answer: editFormData.answer,
          isFeatured: editFormData.isFeatured,
        }).unwrap();
      }
      toast({
        title: `FAQ ${!eddittedFaq ? 'added' : 'updated'}`,
        description: `We've ${
          !eddittedFaq ? 'added' : 'updated'
        } your FAQ for you, come back soon to monitor the status`,
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
      refetchFaqs();
    } catch (err) {
      toast({
        title: `FAQ ${!eddittedFaq ? 'adding' : 'updating'}. error= ${err}`,
        description: `We've run into a problem ${
          !eddittedFaq ? 'adding' : 'updating'
        } your FAQ, Contact support for help`,
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
    refetchFaqs();
    setLoading(false);
  };

  const handleDeleteConfirm = (item: FAQ) => {
    panel({
      title: 'Delete FAQ',
      size: 'lg',
      render: (onSubmit, onCancel) => {
        return (
          <AppConfirm
            message="Are you sure you want to delete the selected FAQ?"
            onConfirm={async () => {
              onSubmit();
              await handleDeleteFAQ(item);
            }}
            onCancel={() => {
              onCancel();
            }}
          />
        );
      },
    });
  };

  const renderDefaultTableActions = (item: FAQ) => {
    return (
      <Menu>
        <MenuButton
          color={AppColors.primary}
          border={`1px solid ${AppColors.appBorder}`}
          size="xs"
          bgColor={'white'}
          as={Button}
          rightIcon={<ChevronDownIcon />}
          borderRadius="full"
          _hover={{
            transform: 'translateY(-2px)',
            boxShadow: 'lg',
          }}
        >
          Actions
        </MenuButton>
        <MenuList>
          <MenuItem icon={<SettingsIcon />} onClick={() => handleEditFAQ(item)}>
            Edit
          </MenuItem>
          <MenuItem icon={<DeleteIcon />} onClick={() => handleDeleteConfirm(item)}>
            Delete
          </MenuItem>
        </MenuList>
      </Menu>
    );
  };

  const faqColumns: ColumnDefinitionType<FAQ>[] = [
    {
      type: ColumnSpecialTypes.date,
      key: 'dateCreated',
      header: 'Date Added',
      headerSortable: true,
      columnSearchable: true,
    },
    {
      key: 'question',
      header: 'Questions',
      headerSortable: true,
      columnSearchable: true,
    },
    {
      key: 'answer',
      header: 'Answers',
      headerSortable: true,
      columnSearchable: true,
    },
    {
      key: 'faqCategoryId',
      header: 'Category',
      headerSortable: true,
      columnSearchable: true,
      render: (item) => <AppText fontSize={'sm'}>{item.faqCategory?.name}</AppText>,
    },
    {
      key: 'custom',
      header: 'Actions',
      headerSortable: false,
      render: renderDefaultTableActions,
    },
  ];

  return (
    <BaseLayout>
      <Flex flex={1} direction="column">
        <Flex m={4} justifyContent={'space-between'} align={'center'} minH={'65px'}>
          <Text fontSize={'2xl'} fontWeight={'bold'} color={AppColors.primary}>
            {title}
          </Text>
          <AppButton
            bg={'white'}
            size={'sm'}
            border={`1px solid ${AppColors.appBorder}`}
            borderRadius={'full'}
            onClick={handleCreateFAQ}
          >
            Add FAQ
          </AppButton>
        </Flex>
        <Flex
          bg={useColorModeValue('white', 'gray.800')}
          rounded={'2xl'}
          overflow={'hidden'}
          padding={'4'}
          m={4}
          flexDir={'column'}
          flex={1}
        >
          <AppDataTable
            data={faqs || []}
            noDataMessage="No FAQ's yet, start by adding some!"
            columns={faqColumns}
            onSearchInputChange={handleSearchChange}
            onSortByColumnClick={handleSortByColumnClick}
          />
          <AppPaginator pagination={pagination} onPageChange={handleOnPageChange}></AppPaginator>
        </Flex>
      </Flex>
    </BaseLayout>
  );
};
export default FAQPage;
