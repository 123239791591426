import { AppColors } from '@backlinkit/shared';
import { Icon, Tooltip } from '@chakra-ui/react';
import React from 'react';
import { AiOutlineGoogle } from 'react-icons/ai';
import { FaTruckLoading } from 'react-icons/fa';

type GoogleIndexCellProps = {
  url: string;
  hasDomainIndex?: boolean;
  hasPageIndex?: boolean;
  googleCheckDate?: Date;
};

const GoogleIndexCell: React.FC<GoogleIndexCellProps> = ({
  url,
  hasDomainIndex,
  hasPageIndex,
  googleCheckDate,
}) => {
  if (hasDomainIndex === undefined && hasPageIndex === undefined) {
    return <></>;
  }

  const getToolTipLabel = () => {
    if (!googleCheckDate) {
      return 'In 24h';
    }
    if (hasDomainIndex && hasPageIndex) {
      return 'Page Indexed';
    } else if (hasDomainIndex) {
      return 'Domain Indexed';
    } else {
      return 'No Index';
    }
  };

  const getIconColor = () => {
    if (!googleCheckDate) {
      return 'grey';
    }
    if (hasDomainIndex && hasPageIndex) {
      return 'green';
    } else if (hasDomainIndex) {
      return AppColors.secondary;
    } else {
      return 'orange';
    }
  };

  const handleGoogleIndexCellClick = async () => {
    window.open(`https://www.google.com/search?q=site:${url}`, 'blank');
  };

  return (
    <Tooltip label={getToolTipLabel()}>
      <span onClick={handleGoogleIndexCellClick}>
        {googleCheckDate ? (
          <Icon color={getIconColor()} as={AiOutlineGoogle} />
        ) : (
          <Icon color={getIconColor()} as={FaTruckLoading} />
        )}
      </span>
    </Tooltip>
  );
};

export default GoogleIndexCell;
