import React from 'react';
import { Flex, Alert, Box } from '@chakra-ui/react';
import { AppText } from '../app-text/app-text';
import { CloseIcon } from '@chakra-ui/icons';

export type AlertListItem = {
  name: string;
  nameLabel?: string;
  alertType: 'info' | 'warning' | 'error';
  alertMessage: string;
  alertMessageLabel?: string;
  alertNote?: string;
  alertNoteLabel?: string;
  entityId?: string;
  alertKey?: number;
};

export type AppAlertItemProps = {
  item: AlertListItem;
  showName?: boolean;
  showType?: boolean;
  itemsClearable?: boolean;
  onItemClearClick?: (item: AlertListItem) => void;
};

export const AppAlertItem: React.FC<AppAlertItemProps> = ({
  item,
  showName = true,
  showType = true,
  itemsClearable = false,
  onItemClearClick,
}) => {
  const handleClearAlertClick = (item: AlertListItem) => {
    itemsClearable && onItemClearClick && onItemClearClick(item);
  };

  const getAlertTypeLabel = (): string => {
    switch (item.alertType) {
      case 'warning':
        return 'Warning';
      case 'error':
        return 'Earning';
      case 'info':
        return 'Info';
      default:
        break;
    }

    return '';
  };

  return (
    <Alert
      justifyContent="space-between"
      alignItems="center"
      mb={2}
      status={item.alertType}
    >
      <Flex direction={'column'}>
        {showName && (
          <Box>
            <Flex
              direction={'row'}
              justifyContent={'start'}
              alignItems="center"
            >
              <AppText mr={2} width={'80px'} fontWeight={'bold'}>
                {item.nameLabel ? item.nameLabel : getAlertTypeLabel()}:
              </AppText>{' '}
              {item.name}
            </Flex>
          </Box>
        )}

        {showType && (
          <Box mt={showName ? 2 : 0}>
            <Flex
              direction={'row'}
              justifyContent={'start'}
              alignItems="center"
            >
              <AppText mr={2} width={'80px'} fontWeight={'bold'}>
                {item.alertMessageLabel ? item.alertMessageLabel : 'Message'}:
              </AppText>{' '}
              {item.alertMessage}
            </Flex>
          </Box>
        )}

        {item.alertNote && (
          <Box mt={showName ? 2 : 0}>
            <Flex
              direction={'row'}
              justifyContent={'start'}
              alignItems="center"
            >
              <AppText mr={2} width={'80px'} fontWeight={'bold'}>
                {item.alertNoteLabel ? item.alertNoteLabel : 'Note'}:
              </AppText>{' '}
              {item.alertNote}
            </Flex>
          </Box>
        )}
      </Flex>
      {itemsClearable && (
        <Flex>
          <CloseIcon
            onClick={() => handleClearAlertClick(item)}
            _hover={{
              cursor: 'pointer',
            }}
          />
        </Flex>
      )}
    </Alert>
  );
};
