import { Button, Flex, Menu, MenuButton, MenuItem, MenuList, useToast } from '@chakra-ui/react';
import BaseLayout from '../../components/layouts/base-layout';
import {
  AppColors,
  AppConfirm,
  AppDataTable,
  ColumnDefinitionType,
  ColumnSpecialTypes,
  OrganizationTypeEditFormData,
  OrganizationTypeFormData,
  useLoading,
  usePanel,
  AppButton,
  useDataTable,
  AppPaginator,
  OrganizationBudgetTypeDto
} from '@backlinkit/shared';
import {
  useCreateOrganizationBudgetTypeMutation,
  useDeleteOrganizationBudgetTypeMutation,
  useFetchAllOrganizationBudgetTypesQuery,
  useUpdateOrganizationBudgetTypeMutation,
} from '../../store/api/organizationBudgetTypeApi';
import OrganizationTypeForm from '../../components/forms/orgnization-type-form';
import { ChevronDownIcon, SettingsIcon, DeleteIcon } from '@chakra-ui/icons';
import { Text, useColorModeValue } from '@chakra-ui/react';

const OrganizationBudgetTypes: React.FC = () => {
  const title = 'Organization Budget Types';
  const { setLoading } = useLoading();
  const toast = useToast();
  const panel = usePanel();
  const { data: organizationBudgetTypes, refetch: refetchOrganizationBudgetTypes } =
    useFetchAllOrganizationBudgetTypesQuery({
      refetchOnMountArgChange: true,
    });
  const [createOrganizationBudgetType] = useCreateOrganizationBudgetTypeMutation();
  const [updateOrganizationBudgetType] = useUpdateOrganizationBudgetTypeMutation();
  const [deleteOrganizationBudgetType] = useDeleteOrganizationBudgetTypeMutation();

  const {
    handleSearchChange,
    handleSortByColumnClick,
    handleOnPageChange,
    pagination,
    setPagination,
    nextPaginationQueryParams,
  } = useDataTable<OrganizationBudgetTypeDto>();

  const upsertOrganizationBudgetType = async (formData: any) => {
    setLoading(true);
    let eddittedOrganizationBudgetType = formData.id ? true : false;
    try {
      if (!eddittedOrganizationBudgetType) {
        const createFormData = formData as OrganizationTypeFormData;
        await createOrganizationBudgetType({
          name: createFormData.name,
          description: createFormData.description,
        }).unwrap();
      } else {
        debugger
        const editFormData = formData as OrganizationTypeEditFormData;
        await updateOrganizationBudgetType({
          id: editFormData.id ?? '',
          name: editFormData.name,
          description: editFormData.description,
        }).unwrap();
      }
      toast({
        title: `Organization Budget Type ${!eddittedOrganizationBudgetType ? 'added' : 'updated'}`,
        description: `We've ${
          !eddittedOrganizationBudgetType ? 'added' : 'updated'
        } your Organization Budget Type for you, come back soon to monitor the status`,
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
      refetchOrganizationBudgetTypes();
    } catch (err) {
      toast({
        title: `Organization Budget Type ${
          !eddittedOrganizationBudgetType ? 'adding' : 'updating'
        }. error= ${err}`,
        description: `We've run into a problem ${
          !eddittedOrganizationBudgetType ? 'adding' : 'updating'
        } your Organization Budget Type, Contact support for help`,
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
    refetchOrganizationBudgetTypes();
    setLoading(false);
  };

  const handleCreateOrganizationBudgetType = () => {
    panel({
      title: 'Create Organization Budget Type',
      size: 'lg',
      render: (onSubmit) => {
        return (
          <OrganizationTypeForm
            onSubmit={async (formData) => {
              await upsertOrganizationBudgetType(formData);
              onSubmit();
            }}
          />
        );
      },
    });
  };

  const handleEditOrganizationBudgetType = (item: OrganizationBudgetTypeDto) => {
    console.log('edit item', item)
    const editForm: OrganizationTypeEditFormData = {
      id: item.id,
      name: item.name,
      description: item.description,
    };
    panel({
      title: 'Edit Organization Budget Type',
      size: 'lg',
      render: (onSubmit) => {
        return (
          <OrganizationTypeForm
            form={editForm}
            onSubmit={async (formData) => {
              await upsertOrganizationBudgetType(formData);
              onSubmit();
            }}
          />
        );
      },
    });
  };

  const handleDeleteOrganizationBudgetType = async (item: OrganizationBudgetTypeDto) => {
    setLoading(true);
    try {
      const handleDelete = deleteOrganizationBudgetType(item.id).unwrap();
      await handleDelete;
    } catch (err) {
      toast({
        title: 'Organization Budget Type delete error',
        description:
          "We've run into a problem deleting the selected Organization Budget Type, contact support for help",
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }

    toast({
      title: 'Selected Organization Budget Type deleted',
      description: "We've deleted the Organization Budget Type you selected",
      status: 'success',
      duration: 9000,
      isClosable: true,
    });
    refetchOrganizationBudgetTypes();
    setLoading(false);
  };

  const handleDeleteConfirm = (item: OrganizationBudgetTypeDto) => {
    panel({
      title: 'Delete Organization Budget Type',
      size: 'lg',
      render: (onSubmit, onCancel) => {
        return (
          <AppConfirm
            message="Are you sure you want to delete the selected Organization Budget Type?"
            onConfirm={async () => {
              onSubmit();
              await handleDeleteOrganizationBudgetType(item);
            }}
            onCancel={() => {
              onCancel();
            }}
          />
        );
      },
    });
  };

  const renderDefaultTableActions = (item: OrganizationBudgetTypeDto) => {
    return (
      <Menu>
        <MenuButton
          color={AppColors.primary}
          border={`1px solid ${AppColors.appBorder}`}
          size="xs"
          bgColor={'white'}
          as={Button}
          rightIcon={<ChevronDownIcon />}
          borderRadius="full"
          _hover={{
            transform: 'translateY(-2px)',
            boxShadow: 'lg',
          }}
        >
          Actions
        </MenuButton>
        <MenuList>
          <MenuItem icon={<SettingsIcon />} onClick={() => handleEditOrganizationBudgetType(item)}>
            Edit
          </MenuItem>
          <MenuItem icon={<DeleteIcon />} onClick={() => handleDeleteConfirm(item)}>
            Delete
          </MenuItem>
        </MenuList>
      </Menu>
    );
  };

  const organizationBudgetTypeColumns: ColumnDefinitionType<OrganizationBudgetTypeDto>[] = [
    {
      type: ColumnSpecialTypes.date,
      key: 'dateCreated',
      header: 'Date Added',
      headerSortable: true,
      columnSearchable: true,
    },
    {
      header: 'Name',
      key: 'name',
      headerSortable: true,
      columnSearchable: true,
    },
    {
      header: 'Description',
      key: 'description',
      headerSortable: true,
      columnSearchable: true,
    },
    {
      key: 'custom',
      header: 'Actions',
      headerSortable: false,
      render: renderDefaultTableActions,
    },
  ];

  return (
    <BaseLayout>
      <Flex flex={1} direction={'column'}>
        <Flex m={4} justifyContent={'space-between'} align={'center'} minH={'65px'}>
          <Text fontSize={'2xl'} fontWeight={'bold'} color={AppColors.primary}>
            {title}
          </Text>
          <AppButton
            bg={'white'}
            size={'sm'}
            border={`1px solid ${AppColors.appBorder}`}
            borderRadius={'full'}
            onClick={handleCreateOrganizationBudgetType}
          >
            Add Organization Budget Type
          </AppButton>
        </Flex>
        <Flex
          bg={useColorModeValue('white', 'gray.800')}
          rounded={'2xl'}
          overflow={'hidden'}
          padding={'4'}
          m={4}
          flexDir={'column'}
          flex={1}
        >
          <AppDataTable
            data={organizationBudgetTypes || []}
            noDataMessage="No Organization Budget Type's yet, start by adding some!"
            columns={organizationBudgetTypeColumns}
          />
          <AppPaginator pagination={pagination} onPageChange={handleOnPageChange}></AppPaginator>
        </Flex>
      </Flex>
    </BaseLayout>
  );
};

export default OrganizationBudgetTypes;