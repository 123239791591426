import { Button, Flex, Menu, MenuButton, MenuItem, MenuList, useToast } from '@chakra-ui/react';
import BaseLayout from '../../components/layouts/base-layout';
import {
  AppColors,
  AppConfirm,
  AppDataTable,
  ColumnDefinitionType,
  ColumnSpecialTypes,
  FAQ,
  OrganizationTypeEditFormData,
  OrganizationTypeFormData,
  useLoading,
  usePanel,
  AppButton,
  useDataTable,
  AppPaginator,
  OrganizationLinkEstimationTypeDto,
} from '@backlinkit/shared';
import {
  useFetchAllOrganizationLinkEstimationTypesQuery,
  useCreateOrganizationLinkEstimationTypeMutation,
  useDeleteOrganizationLinkEstimationTypeMutation,
  useUpdateOrganizationLinkEstimationTypeMutation,
} from '../../store/api/organizationLinkEstimationTypeApi';
import OrganizationTypeForm from '../../components/forms/orgnization-type-form';
import { ChevronDownIcon, SettingsIcon, DeleteIcon } from '@chakra-ui/icons';
import { Text, useColorModeValue } from '@chakra-ui/react';

const OrganizationLinkEstimationTypes: React.FC = () => {
  const title = 'Organization Link Estimation Types';
  const { setLoading } = useLoading();
  const toast = useToast();
  const panel = usePanel();
  const { data: organizationLinkEstimationTypes, refetch: refetchOrganizationLinkEstimationTypes } =
  useFetchAllOrganizationLinkEstimationTypesQuery({
      refetchOnMountArgChange: true,
    });
  const [createOrganizationLinkEstimationType] = useCreateOrganizationLinkEstimationTypeMutation();
  const [updateOrganizationLinkEstimationType] = useUpdateOrganizationLinkEstimationTypeMutation();
  const [deleteOrganizationLinkEstimationType] = useDeleteOrganizationLinkEstimationTypeMutation();

  const {
    handleSearchChange,
    handleSortByColumnClick,
    handleOnPageChange,
    pagination,
    setPagination,
    nextPaginationQueryParams,
  } = useDataTable<FAQ>();

  const upsertOrganizationLinkEstimationType = async (formData: any) => {
    setLoading(true);
    let eddittedOrganizationLinkEstimationType = formData.id ? true : false;
    try {
      if (!eddittedOrganizationLinkEstimationType) {
        const createFormData = formData as OrganizationTypeFormData;
        await createOrganizationLinkEstimationType({
          name: createFormData.name,
          description: createFormData.description,
        }).unwrap();
      } else {
        const editFormData = formData as OrganizationTypeEditFormData;
        await updateOrganizationLinkEstimationType({
          id: editFormData.id ?? '',
          name: editFormData.name,
          description: editFormData.description,
        }).unwrap();
      }
      toast({
        title: `Organization Link Estimation Type ${
          !eddittedOrganizationLinkEstimationType ? 'added' : 'updated'
        }`,
        description: `We've ${
          !eddittedOrganizationLinkEstimationType ? 'added' : 'updated'
        } your Organization Link Estimation Type for you, come back soon to monitor the status`,
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
      refetchOrganizationLinkEstimationTypes();
    } catch (err) {
      toast({
        title: `Organization Link Estimation Type ${
          !eddittedOrganizationLinkEstimationType ? 'adding' : 'updating'
        }. error= ${err}`,
        description: `We've run into a problem ${
          !eddittedOrganizationLinkEstimationType ? 'adding' : 'updating'
        } your Organization Link Estimation Type, Contact support for help`,
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
    refetchOrganizationLinkEstimationTypes();
    setLoading(false);
  };

  const handleCreateOrganizationLinkEstimationType = () => {
    panel({
      title: 'Create Organization Link Estimation Type',
      size: 'lg',
      render: (onSubmit) => {
        return (
          <OrganizationTypeForm
            onSubmit={async (formData) => {
              await upsertOrganizationLinkEstimationType(formData);
              onSubmit();
            }}
          />
        );
      },
    });
  };

  const handleEditOrganizationLinkEstimationType = (item: OrganizationLinkEstimationTypeDto) => {
    const editForm: OrganizationTypeEditFormData = {
      id: item.id,
      name: item.name,
      description: item.description,
    };
    panel({
      title: 'Edit Organization Link Experience Type',
      size: 'lg',
      render: (onSubmit) => {
        return (
          <OrganizationTypeForm
            form={editForm}
            onSubmit={async (formData) => {
              await upsertOrganizationLinkEstimationType(formData);
              onSubmit();
            }}
          />
        );
      },
    });
  };

  const handleDeleteOrganizationLinkEstimationType = async (
    item: OrganizationLinkEstimationTypeDto
  ) => {
    setLoading(true);
    try {
      const handleDelete = deleteOrganizationLinkEstimationType(item.id).unwrap();
      await handleDelete;
    } catch (err) {
      toast({
        title: 'Organization Link Estimation Type delete error',
        description:
          "We've run into a problem deleting the selected Organization Link Estimation Type, contact support for help",
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }

    toast({
      title: 'Selected Organization Link Estimation Type deleted',
      description: "We've deleted the Organization Link Estimation Type you selected",
      status: 'success',
      duration: 9000,
      isClosable: true,
    });
    refetchOrganizationLinkEstimationTypes();
    setLoading(false);
  };

  const handleDeleteConfirm = (item: OrganizationLinkEstimationTypeDto) => {
    panel({
      title: 'Delete Organization Link Estimation Type',
      size: 'lg',
      render: (onSubmit, onCancel) => {
        return (
          <AppConfirm
            message="Are you sure you want to delete the selected Organization Link Estimation Type?"
            onConfirm={async () => {
              onSubmit();
              await handleDeleteOrganizationLinkEstimationType(item);
            }}
            onCancel={() => {
              onCancel();
            }}
          />
        );
      },
    });
  };

  const renderDefaultTableActions = (item: OrganizationLinkEstimationTypeDto) => {
    return (
      <Menu>
        <MenuButton
          color={AppColors.primary}
          border={`1px solid ${AppColors.appBorder}`}
          size="xs"
          bgColor={'white'}
          as={Button}
          rightIcon={<ChevronDownIcon />}
          borderRadius="full"
          _hover={{
            transform: 'translateY(-2px)',
            boxShadow: 'lg',
          }}
        >
          Actions
        </MenuButton>
        <MenuList>
          <MenuItem
            icon={<SettingsIcon />}
            onClick={() => handleEditOrganizationLinkEstimationType(item)}
          >
            Edit
          </MenuItem>
          <MenuItem icon={<DeleteIcon />} onClick={() => handleDeleteConfirm(item)}>
            Delete
          </MenuItem>
        </MenuList>
      </Menu>
    );
  };

  const organizationLinkEstimationTypeColumns: ColumnDefinitionType<OrganizationLinkEstimationTypeDto>[] =
    [
      {
        type: ColumnSpecialTypes.date,
        key: 'dateCreated',
        header: 'Date Added',
        headerSortable: true,
        columnSearchable: true,
      },
      {
        header: 'Name',
        key: 'name',
        headerSortable: true,
        columnSearchable: true,
      },
      {
        header: 'Description',
        key: 'description',
        headerSortable: true,
        columnSearchable: true,
      },
      {
        key: 'custom',
        header: 'Actions',
        headerSortable: false,
        render: renderDefaultTableActions,
      },
    ];

  return (
    <BaseLayout>
      <Flex flex={1} direction={'column'}>
        <Flex m={4} justifyContent={'space-between'} align={'center'} minH={'65px'}>
          <Text fontSize={'2xl'} fontWeight={'bold'} color={AppColors.primary}>
            {title}
          </Text>
          <AppButton
            bg={'white'}
            size={'sm'}
            border={`1px solid ${AppColors.appBorder}`}
            borderRadius={'full'}
            onClick={handleCreateOrganizationLinkEstimationType}
          >
            Add Organization Link Estimation Type
          </AppButton>
        </Flex>
        <Flex
          bg={useColorModeValue('white', 'gray.800')}
          rounded={'2xl'}
          overflow={'hidden'}
          padding={'4'}
          m={4}
          flexDir={'column'}
          flex={1}
        >
          <AppDataTable
            data={organizationLinkEstimationTypes || []}
            noDataMessage="No Organization Link Estimation Type's yet, start by adding some!"
            columns={organizationLinkEstimationTypeColumns}
          />
          <AppPaginator pagination={pagination} onPageChange={handleOnPageChange}></AppPaginator>
        </Flex>
      </Flex>
    </BaseLayout>
  );
};

export default OrganizationLinkEstimationTypes;
