import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import * as React from 'react';

import type { DialogModalOptions } from './dialog';
import { AppText } from '../../components';

export const DialogServiceContext = React.createContext<(options: DialogModalOptions) => void>(
  () => {
    console.error('Please ensure you register the dialog provider!');
    throw new Error('Please ensure you register the dialog provider!');
  }
);

export const DialogServiceProvider = ({ children }: any) => {
  const [dialogState, setDialogState] = React.useState<DialogModalOptions | null>(null);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const openConfirmation = (options: DialogModalOptions) => {
    setDialogState(options);
    onOpen();
  };

  const handleClose = () => {
    if (dialogState?.blocking) {
      return;
    }
    setDialogState(null);
    onClose();
  };

  const handleSubmit = () => {
    setDialogState(null);
    onClose();
  };

  return (
    <>
      <DialogServiceContext.Provider value={openConfirmation} children={children} />
      {Boolean(dialogState) && (
        <>
          <Modal isOpen={isOpen} size={dialogState?.size} onClose={onClose}>
            {dialogState?.showOverlay ? (
              <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" />
            ) : (
              <ModalOverlay />
            )}
            <ModalContent p={4} maxWidth={dialogState?.maxWidth}>
              {dialogState?.title && <ModalHeader>{dialogState?.title}</ModalHeader>}
              {dialogState?.showCancel && <ModalCloseButton />}
              <ModalBody>
                {dialogState?.render ? (
                  dialogState?.render(handleSubmit, handleClose)
                ) : (
                  <AppText>No Render Method</AppText>
                )}
              </ModalBody>
            </ModalContent>
          </Modal>
        </>
      )}
    </>
  );
};
