import React from 'react';
import {
  Flex,
  Button,
  Text,
  useColorModeValue,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useToast,
} from '@chakra-ui/react';
import BaseLayout from '../../components/layouts/base-layout';
import { ChevronDownIcon, DeleteIcon, SettingsIcon } from '@chakra-ui/icons';
import {
  useCreateNotificationMutation,
  useDeleteNotificationMutation,
  useFetchNotificationByUserQuery,
  useFetchNotificationsQuery,
  useUpdateNotificationMutation,
} from '../../store/api/notificationsApi';
import {
  AppButton,
  AppColors,
  AppConfirm,
  AppDataTable,
  ColumnDefinitionType,
  ColumnSpecialTypes,
  Notification,
  NotificationCreateForm,
  NotificationEditForm,
  useLoading,
  usePanel,
} from '@backlinkit/shared';
import { useSelector } from 'react-redux';
import { selectUser } from '../../store/slices/authentication.slice';
import NotificationForm from '../../components/forms/notifications';

const Notifications: React.FC = () => {
  const title = 'Notifications';
  const { setLoading } = useLoading();
  const panel = usePanel();
  const toast = useToast();
  const user = useSelector(selectUser);
  const { data: notifications, refetch: refetchNotifications } = useFetchNotificationsQuery();
  const [createNotification] = useCreateNotificationMutation();
  const [updateNotification] = useUpdateNotificationMutation();
  const [deleteNotification] = useDeleteNotificationMutation();

  const upsertNotification = async (formData: any) => {
    let edditted = formData.id ? true : false;
    setLoading(true);
    try {
      if (!edditted) {
        const createData = formData as NotificationCreateForm;
        await createNotification({
          title: createData.title,
          description: createData.description,
          dateExpired: createData.dateExpired,
          organizationId: createData.organizationId ?? '',
          userId: createData.userId ?? '',
          notificationType: createData.notificationType,
        }).unwrap();
      } else {
        const editData = formData as NotificationEditForm;
        await updateNotification({
          id: editData.id ?? '',
          title: editData.title,
          description: editData.description,
          dateExpired: editData.dateExpired,
          organizationId: editData.organizationId ?? '',
          userId: editData.userId ?? '',
          notificationType: editData.notificationType,
        }).unwrap();
      }
      toast({
        title: `Notification ${!edditted ? 'added' : 'updated'}`,
        description: `We've ${
          !edditted ? 'added' : 'updated'
        } your notification for you, come back soon to check the status`,
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
      refetchNotifications();
    } catch (error) {
      toast({
        title: `Notification ${!edditted ? 'adding' : 'updating'} error = ${error}`,
        description: `We've run into a problem ${
          !edditted ? 'adding' : 'updating'
        } your notification, Contact support for help`,
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
      setLoading(false);
    }
    refetchNotifications();
    setLoading(false);
  };

  const handleCreate = () => {
    panel({
      title: 'Create Notification',
      size: 'lg',
      render: (onSubmit) => {
        return (
          <NotificationForm
            onSubmit={async (formData) => {
              await upsertNotification(formData);
              onSubmit();
            }}
          />
        );
      },
    });
  };

  const handleEdit = (item: Notification) => {
    const editForm: NotificationEditForm = {
      id: item.id,
      title: item.title,
      description: item.description,
      isSent: item.isSent,
      dateExpired: item.dateExpired,
      organizationId: item.organizationId ?? '',
      userId: item.userId ?? '',
      notificationType: item.notificationType,
    };
    panel({
      title: 'Update Notification',
      size: 'lg',
      render: (onSubmit) => {
        return (
          <NotificationForm
            form={editForm}
            onSubmit={async (formData) => {
              await upsertNotification(formData);
              onSubmit();
            }}
          />
        );
      },
    });
  };

  const handleDelete = async (item: Notification) => {
    setLoading(true);
    try {
      const deletePromise = deleteNotification(item.id).unwrap();
      await deletePromise;
    } catch (e) {
      toast({
        title: `Notification deleting error. Error :${e}`,
        description:
          "We've run into an issue deleting the notification you selected. COntact support for help",
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
      setLoading(false);
    }
    toast({
      title: "We've deleted the notification you selected",
      description: "We've deleted the notification you selected",
      status: 'success',
      duration: 9000,
      isClosable: true,
    });
    setLoading(false);
    refetchNotifications();
  };

  const handleDeleteConfirm = (item: Notification) => {
    panel({
      title: 'Delete Notification category',
      size: 'lg',
      render: (onSubmit, onCancel) => {
        return (
          <AppConfirm
            message="Are you sure you want to delete the selected Notification?"
            onConfirm={async () => {
              onSubmit();
              await handleDelete(item);
            }}
            onCancel={() => {
              onCancel();
            }}
          />
        );
      },
    });
  };

  const renderDefaultActions = (item: Notification) => {
    return (
      <Menu>
        <MenuButton
          color={AppColors.primary}
          border={`1px solid ${AppColors.appBorder}`}
          size="xs"
          bgColor={'white'}
          as={Button}
          rightIcon={<ChevronDownIcon />}
          borderRadius="full"
          _hover={{
            transform: 'translateY(-2px)',
            boxShadow: 'lg',
          }}
        >
          Actions
        </MenuButton>
        <MenuList>
          <MenuItem
            icon={<SettingsIcon />}
            onClick={() => {
              handleEdit(item);
            }}
          >
            Edit
          </MenuItem>
          <MenuItem
            icon={<DeleteIcon />}
            onClick={() => {
              handleDeleteConfirm(item);
            }}
          >
            Delete
          </MenuItem>
        </MenuList>
      </Menu>
    );
  };

  const notificationColumns: ColumnDefinitionType<Notification>[] = [
    {
      type: ColumnSpecialTypes.date,
      key: 'dateCreated',
      header: 'Date Added:',
      headerSortable: true,
      columnSearchable: true,
    },
    {
      key: 'title',
      header: 'Title',
      columnSearchable: true,
      headerSortable: true,
    },
    {
      key: 'notificationType',
      header: 'Notification Type',
      columnSearchable: true,
      headerSortable: true,
    },
    {
      key: 'isSent',
      header: 'Sent',
      columnSearchable: true,
      headerSortable: true,
    },
    {
      key: 'custom',
      header: 'Actions',
      columnSearchable: false,
      headerSortable: false,
      render: renderDefaultActions,
    },
  ];

  return (
    <BaseLayout>
      <Flex flex={1} direction={'column'} w={'full'}>
        <Flex m={4} justify={'space-between'} align={'center'} minH={'65px'}>
          <Text fontSize={'2xl'} fontWeight={'bold'} color={AppColors.primary}>
            {title}
          </Text>
          <AppButton
            size={'sm'}
            bgColor={'white'}
            border={`1px solid ${AppColors.appBorder}`}
            borderRadius={'full'}
            onClick={handleCreate}
          >
            Add Notification
          </AppButton>
        </Flex>
        <Flex
          bg={useColorModeValue('white', 'gray.800')}
          justify={'space-evenly'}
          rounded={'2xl'}
          overflow={'hidden'}
          padding={4}
          m={4}
          flexDir={'column'}
          flex={1}
        >
          <AppDataTable
            data={notifications || []}
            noDataMessage="No Notifications yet!"
            columns={notificationColumns}
          />
        </Flex>
      </Flex>
    </BaseLayout>
  );
};

export default Notifications;
