import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { Bug, BugEditForm } from '@backlinkit/shared';
import { AppFetchBaseQuery } from './apiBase';

export const bugApi = createApi({
  reducerPath: 'bugApi',
  baseQuery: AppFetchBaseQuery,
  endpoints: (build) => ({
    fetchAllBugs: build.query<Bug[], any>({
      query: () => ({
        url: '/bugReport',
        method: 'GET',
      }),
    }),
    fetchBugById: build.query<Bug, string>({
      query: (id) => ({
        url: `/bugReport/${id}`,
        method: 'GET',
      }),
    }),
    updateBug: build.mutation<Bug, BugEditForm>({
      query: (body) => ({
        url: `/bugReport/update/${body.id}`,
        method: 'PUT',
        body: body
      })
    }),
    deleteBug: build.mutation<Bug, string>({
      query: (id) => ({
        url: `/bugReport/delete/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useUpdateBugMutation,
  useDeleteBugMutation,
  useFetchAllBugsQuery,
  useFetchBugByIdQuery,
} = bugApi;
