import React, { useEffect, useState } from 'react';
import {
  Box,
  Flex,
  FormLabel,
  Input,
  Stack,
  Table,
  TableContainer,
  Tag,
  TagLabel,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { BaseFormProps } from './base-forms';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  NotificationCreateForm,
  AppInput,
  AppButton,
  SelectListFilter,
  SelectOption,
  OrganizationSearchRequest,
  OrganizationSearchResponse,
  UserSearchResponse,
  AppColors,
  AppText,
} from '@backlinkit/shared';
import { useFetchAllOrganizationsQuery } from '../../store/api/organizationApi';
import { useFetchAllUsersQuery } from '../../store/api/userApi';

export const notificationDefaultValues: NotificationCreateForm = {
  title: '',
  description: '',
  isSent: false,
  notificationType: 0,
};

const notificationsFormDataSchema = yup.object({
  title: yup.string().required('Field is required'),
  description: yup.string().required('Field is required'),
  isSent: yup.boolean(),
  notificationType: yup.number().required('Field is required'),
});

type NotificationFormProps<T> = {
  form?: NotificationCreateForm;
} & BaseFormProps<T>;

const NotificationForm: React.FC<NotificationFormProps<NotificationCreateForm>> = ({
  form,
  onSubmit,
}) => {
  const { data: organizations, refetch: refetchOrganizations } = useFetchAllOrganizationsQuery({
    refetchOnMountArgChange: true,
  });
  const { data: users, refetch: refetchUsers } = useFetchAllUsersQuery({
    refetchOnMountArgChange: true,
  });

  const {
    control: notificationControl,
    formState: { isValid, errors },
    setValue,
    getValues,
    handleSubmit,
  } = useForm<NotificationCreateForm>({
    defaultValues: notificationDefaultValues,
    resolver: yupResolver(notificationsFormDataSchema),
    mode: 'onChange',
  });
  const [options, setOptions] = useState<SelectOption[]>([]);
  const [organizationSearchParam, setOrganizationSearchParam] = useState<string>();
  const [organizationSearchResponse, setOrganizationSearchResponse] = useState<
    OrganizationSearchResponse[]
  >([]);
  const [selectedOrganization, setSelectedOrganization] = useState<OrganizationSearchResponse>();
  const [userSearchParam, setUserSearchParam] = useState<string>();
  const [userSearchResponse, setUserSearchResponse] = useState<UserSearchResponse[]>([]);
  const [selectedUser, setSelectedUser] = useState<UserSearchResponse>();

  useEffect(() => {
    const selectors: SelectOption[] = [
      {
        label: 'Info',
        value: 0,
      },
      {
        label: 'Warning',
        value: 1,
      },
      {
        label: 'Error',
        value: 2,
      },
    ];
    setOptions(selectors);
  }, []);

  const organizationSearch = (param: string) => {
    if (!param) {
      setOrganizationSearchResponse(organizations ?? []);
    }
    const lowerCaseParam = param.toLowerCase();

    if (!organizations) return;

    const foundOrganizations = organizations?.filter((item) => {
      return item.name.toLowerCase().includes(lowerCaseParam);
    });
    setOrganizationSearchResponse(foundOrganizations);
  };

  const userSearch = (param: string) => {
    if (!param) {
      setUserSearchResponse(users ?? []);
    }
    const lowerCaseParam = param.toLowerCase();
    if (!users) return;
    const foundUsers = users.filter((user) => {
      return user.email.toLowerCase().includes(lowerCaseParam);
    });
    setUserSearchResponse(foundUsers);
  };

  const selectOrganizationResponse = (organizationSearchResponse: OrganizationSearchResponse) => {
    setSelectedOrganization(organizationSearchResponse);
    setOrganizationSearchResponse([]);
  };

  const selectUserResponse = (userSearchResponse: UserSearchResponse) => {
    setSelectedUser(userSearchResponse);
    setUserSearchResponse([]);
  };

  return (
    <Flex direction={'column'} gap={'15px'}>
      <AppText fontWeight={'semibold'}>Send to:</AppText>
      <Accordion allowToggle>
        <AccordionItem>
          <AccordionButton>Search Organizations</AccordionButton>
          <AccordionPanel>
            <Box>
              <form
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Flex direction={'row'} justifyContent={'space-between'} alignItems={'end'}>
                  <Input
                    w={'full'}
                    placeholder="Search By Organization Name"
                    onChange={(e) => {
                      e.preventDefault();
                      setOrganizationSearchParam(e.currentTarget.value);
                    }}
                  />
                  <AppButton
                    ml={'2'}
                    bgColor={AppColors.primary}
                    color={'white'}
                    onClick={() => {
                      organizationSearch(organizationSearchParam as string);
                    }}
                  >
                    Search
                  </AppButton>
                </Flex>
              </form>
              {organizationSearchResponse && organizationSearchResponse.length > 0 && (
                <Box maxH={'200px'} overflowY={'scroll'}>
                  <TableContainer>
                    <Table variant="simple">
                      <Thead>
                        <Tr>
                          <Th>Name</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {organizationSearchResponse.map((selectedOrganization, index) => {
                          return (
                            <Tr key={`${selectedOrganization.name}-${index}`}>
                              <Td>
                                <AppText>{selectedOrganization.name}</AppText>
                              </Td>
                              <Td>
                                <Flex>
                                  <AppButton
                                    mr={4}
                                    bgColor={AppColors.secondary}
                                    color={'white'}
                                    variant={'solid'}
                                    size="xs"
                                    borderRadius="full"
                                    onClick={() => {
                                      selectOrganizationResponse(selectedOrganization);
                                      setValue('organizationId', selectedOrganization!.id);
                                    }}
                                  >
                                    Select
                                  </AppButton>
                                </Flex>
                              </Td>
                            </Tr>
                          );
                        })}
                      </Tbody>
                    </Table>
                  </TableContainer>
                </Box>
              )}

              {selectedOrganization && (
                <Flex justifyContent={'start'} alignItems={'center'}>
                  <AppText isTruncated>Selected Organization:</AppText>

                  <Tag borderRadius="full" colorScheme={'gray'} ml={'4'}>
                    <TagLabel>{selectedOrganization.name}</TagLabel>
                  </Tag>
                </Flex>
              )}
            </Box>
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionButton>Search Users</AccordionButton>
          <AccordionPanel>
            <Box>
              <form
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Flex direction={'row'} justifyContent={'space-between'} alignItems={'end'}>
                  <Input
                    w={'full'}
                    placeholder="Search By Email"
                    onChange={(e) => {
                      e.preventDefault();
                      setUserSearchParam(e.currentTarget.value);
                    }}
                  />
                  <AppButton
                    ml={'2'}
                    bgColor={AppColors.primary}
                    color={'white'}
                    onClick={() => {
                      userSearch(userSearchParam as string);
                    }}
                  >
                    Search
                  </AppButton>
                </Flex>
              </form>
              {userSearchResponse && userSearchResponse.length > 0 && (
                <Box maxH={'200px'} overflowY={'scroll'}>
                  <TableContainer>
                    <Table variant="simple">
                      <Thead>
                        <Tr>
                          <Th>Email</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {userSearchResponse.map((selectedUser, index) => {
                          return (
                            <Tr key={`${selectedUser?.email}-${index}`}>
                              <Td>
                                <AppText>{selectedUser?.email}</AppText>
                              </Td>
                              <Td>
                                <Flex>
                                  <AppButton
                                    mr={4}
                                    bgColor={AppColors.secondary}
                                    color={'white'}
                                    variant={'solid'}
                                    size="xs"
                                    borderRadius="full"
                                    onClick={() => {
                                      selectUserResponse(selectedUser);
                                      setValue('userId', selectedUser.id);
                                      setValue('organizationId', selectedUser.organizationId)
                                    }}
                                  >
                                    Select
                                  </AppButton>
                                </Flex>
                              </Td>
                            </Tr>
                          );
                        })}
                      </Tbody>
                    </Table>
                  </TableContainer>
                </Box>
              )}

              {selectedUser && (
                <Flex justifyContent={'start'} alignItems={'center'}>
                  <AppText isTruncated>Selected User:</AppText>

                  <Tag borderRadius="full" colorScheme={'gray'} ml={'4'}>
                    <TagLabel>{selectedUser.email}</TagLabel>
                  </Tag>
                </Flex>
              )}
            </Box>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
      <form>
        <Stack spacing={4}>
          <AppInput<NotificationCreateForm>
            name="title"
            error={errors.title}
            control={notificationControl}
            label="Name:"
          />
          <AppInput<NotificationCreateForm>
            name="description"
            error={errors.description}
            control={notificationControl}
            label="Description:"
            textArea
          />
          <SelectListFilter
            isInModal
            showClear={false}
            name="notificationType"
            w={'full'}
            isMulti={false}
            options={options}
            onSelectionChange={(item: SelectOption[]) => {
              setValue('notificationType', item[0].value);
            }}
          />
          <AppButton disabled={!isValid} onClick={handleSubmit(onSubmit)}>
            Save
          </AppButton>
        </Stack>
      </form>
    </Flex>
  );
};

export default NotificationForm;
