import { Flex, Stack } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { BaseFormProps } from './base-forms';
import { yupResolver } from '@hookform/resolvers/yup';
import { FAQTypeForm, AppInput, AppButton } from '@backlinkit/shared';

export type BatchTypeFormData = {
  id?: string;
  name: string;
  description: string;
};

export const FAQCategoryFormDefaultValues: BatchTypeFormData = {
  name: '',
  description: '',
};

const batchTypeFormDataSchema = yup.object({
  name: yup.string().required('Field is required'),
  description: yup.string().required('Field is required'),
});

type BatchTypeFormProps<T> = {
  form?: BatchTypeFormData;
} & BaseFormProps<T>;

const BacklinkBatchTypeForm: React.FC<BatchTypeFormProps<BatchTypeFormData>> = ({ form, onSubmit }) => {
  const {
    control: faqCategoryControl,
    formState: { isValid, errors },
    handleSubmit,
  } = useForm<FAQTypeForm>({
    defaultValues: form || FAQCategoryFormDefaultValues,
    resolver: yupResolver(batchTypeFormDataSchema),
    mode: 'onChange',
  });

  return (
    <Flex direction={'column'}>
      <form>
        <Stack spacing={4}>
          <AppInput<BatchTypeFormData>
            name="name"
            error={errors.name}
            control={faqCategoryControl}
            label="Name:"
          />
          <AppInput<BatchTypeFormData>
            name="description"
            error={errors.description}
            control={faqCategoryControl}
            label="Description:"
          />
          <AppButton disabled={!isValid} onClick={handleSubmit(onSubmit)}>
            Save
          </AppButton>
        </Stack>
      </form>
    </Flex>
  );
};
export default BacklinkBatchTypeForm;
