import BaseLayout from '../../components/layouts/base-layout';
import { Box, Text, Flex, useColorModeValue, useToast } from '@chakra-ui/react';
import OrganizationTable from '../../components/app/app-organizations/organizations-table';
import {
  AppButton,
  AppColors,
  OrganizationEditForm,
  OrganizationForm,
  useLoading,
  usePanel,
} from '@backlinkit/shared';
import OrganizationsForm from '../../components/forms/organization-form';
import {
  useOrganizationCreateMutation,
  useEditOrganizationMutation,
} from '../../store/api/organizationApi';

const Organizations: React.FC = () => {
  const title = 'Organizations';
  const { setLoading } = useLoading();
  const toast = useToast();
  const panel = usePanel();
  const [saveOrganizationTrigger] = useOrganizationCreateMutation();
  const [editOrganizationTrigger] = useEditOrganizationMutation();

  const upsertOrganization = async (formData: any) => {
    setLoading(true);
    let isEdit = formData.id ? true : false;
    try {
      if (!isEdit) {
        const createForm = formData as OrganizationForm;
        await saveOrganizationTrigger({
          name: createForm.name,
          productId: createForm.productId,
          email: createForm.email,
          subscriptionIsActive: createForm.subscriptionIsActive,
          productExpiryDate: createForm.productExpiryDate,
          availableCredit: createForm.availableCredit,
        }).unwrap();
      } else {
        const editForm = formData as OrganizationEditForm;
        await editOrganizationTrigger({
          id: editForm.id ?? '',
          name: editForm.name,
          productId: editForm.productId,
          email: editForm.email,
          subscriptionIsActive: editForm.subscriptionIsActive,
          productExpiryDate: editForm.productExpiryDate,
          availableCredit: editForm.availableCredit,
          paymentCustomerId: editForm.paymentCustomerId,
        }).unwrap();
      }
      toast({
        title: 'Organization saved',
        description: `We've ${
          isEdit ? 'updated' : 'created'
        } your organization, come back soon to check the status`,
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
    } catch (e) {
      toast({
        title: 'Organization saving issue',
        description: `We've run into an issue ${
          isEdit ? 'updating' : 'creating'
        } your organization`,
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }

    setLoading(false);
  };

  const handleAdd = () => {
    return panel({
      title: 'Add Organization',
      size: 'lg',
      render: (onSubmit) => {
        return (
          <OrganizationsForm
            onSubmit={async (formData) => {
              onSubmit();
              await upsertOrganization(formData);
            }}
          />
        );
      },
    });
  };

  return (
    <BaseLayout>
      <Box w={'100%'} display={'flex'} flexDir={'column'}>
        <Flex minH={'65px'} align={'center'} m={4} justify={'space-between'}>
          <Text color={AppColors.primary} fontWeight="bold" fontSize="2xl">
            {title}
          </Text>
          <AppButton size={'sm'} onClick={handleAdd} borderRadius={'full'}>
            Add Organization
          </AppButton>
        </Flex>
        <Flex
          bg={useColorModeValue('white', 'gray.800')}
          justify={'center'}
          p={4}
          m={4}
          dir="column"
          flex={1}
          borderRadius={'2xl'}
        >
          <OrganizationTable />
        </Flex>
      </Box>
    </BaseLayout>
  );
};
export default Organizations;
