import { createApi } from '@reduxjs/toolkit/dist/query/react';
import {
  OrganizationLinkBuildingStratergiesTypeDto,
  OrganizationTypeFormData,
  OrganizationTypeEditFormData,
} from '@backlinkit/shared';
import { AppFetchBaseQuery } from './apiBase';

export const organizationLinkBuildingStratergiesTypeApi = createApi({
  reducerPath: 'orgaizationLinkBuildingStratergiesType',
  baseQuery: AppFetchBaseQuery,
  endpoints: (build) => ({
    fetchAllOrganizationLinkBuildingStategiesTypes: build.query<
      OrganizationLinkBuildingStratergiesTypeDto[],
      any
    >({
      query: () => ({
        url: '/organizationLinkBuildingStratergiesType',
        method: 'GET',
      }),
    }),
    fetchOrganizationLinkBuildingStrategiesTypeById: build.query<
      OrganizationLinkBuildingStratergiesTypeDto,
      string
    >({
      query: (id) => ({
        url: `/organizationLinkBuildingStratergiesType/${id}`,
        method: 'GET',
      }),
    }),
    createOrganizationLinkBuildingStrategiesType: build.mutation<
      OrganizationLinkBuildingStratergiesTypeDto,
      OrganizationTypeFormData
    >({
      query: (body) => ({
        url: '/organizationLinkBuildingStratergiesType/create',
        method: 'POST',
        body: body,
      }),
      transformResponse: (response: OrganizationLinkBuildingStratergiesTypeDto) => {
        return response;
      },
    }),
    updateOrganizationLinkBuildingStratergiesType: build.mutation<
      OrganizationLinkBuildingStratergiesTypeDto,
      OrganizationTypeEditFormData
    >({
      query: (body) => ({
        url: `/organizationLinkBuildingStratergiesType/update/${body.id}`,
        method: 'PUT',
        body: body,
      }),
    }),
    deleteOrganizationLinkBuildingStrategiesType: build.mutation<
      OrganizationLinkBuildingStratergiesTypeDto,
      string
    >({
      query: (id) => ({
        url: `/organizationLinkBuildingStratergiesType/delete/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useCreateOrganizationLinkBuildingStrategiesTypeMutation,
  useDeleteOrganizationLinkBuildingStrategiesTypeMutation,
  useFetchAllOrganizationLinkBuildingStategiesTypesQuery,
  useFetchOrganizationLinkBuildingStrategiesTypeByIdQuery,
  useUpdateOrganizationLinkBuildingStratergiesTypeMutation,
} = organizationLinkBuildingStratergiesTypeApi;
