import React from 'react';
import { AppText, LinkStatus } from '@backlinkit/shared';
import { BaseBacklinkCell } from './base-backlink-cell';

type LastLiveCellProps = BaseBacklinkCell & {};

const LastLiveCell: React.FC<LastLiveCellProps> = ({ item }) => {
  return (
    <AppText>
      {item?.backlinkMeta?.linkStatus === LinkStatus.Ok
        ? undefined
        : item?.backlinkMeta?.lastLiveDate?.toISOString()}
    </AppText>
  );
};

export default LastLiveCell;
