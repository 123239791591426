import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { isAuthenticated } from '../store/slices/authentication.slice';
import { useSelector } from 'react-redux';
import { RoutesList } from './router';

interface ProtectedRouteProps {
  route?: RoutesList;
  children: React.ReactNode | React.ReactNode[] | undefined;
}

const ProtectedRoute = ({ route, children }: ProtectedRouteProps) => {
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const userIsAuthenticated = useSelector(isAuthenticated);

  const checkUserToken = () => {
    console.log('isLoggedIn', isLoggedIn);

    if (!userIsAuthenticated) {
      console.log('NOT userIsAuthenticated', userIsAuthenticated);

      setIsLoggedIn(false);
      return navigate(RoutesList.Index);
    }
    setIsLoggedIn(true);
    routeConditionsGaurd();
  };

  const routeConditionsGaurd = () => {
    if (route && userIsAuthenticated) {
      switch (route) {
        case RoutesList.Batches:
          return navigate(RoutesList.Batches);
        default:
          break;
      }
    }
  };

  useEffect(() => {
    checkUserToken();
    //routeConditionsGaurd();
  }, [isLoggedIn]);

  return <React.Fragment>{isLoggedIn ? children : null}</React.Fragment>;
};
export default ProtectedRoute;
