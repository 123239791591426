import { BackLink } from './backlink';
import { Base, PaginationFilter, SearchParam, SortParam } from './base';
import { NicheType } from './niche-type';
import { Organization } from './organization';

export enum DomainStatuses {
  OK = 'OK',
}

export interface DomainByOptionsRequest extends PaginationFilter {
  organizationId: string;
  searchParam?: SearchParam;
  sortParams?: SortParam[];
}

export interface Domain extends Base {
  name: string;
  url: string;
  organizationId: string;
  backlinks?: BackLink[];
  organization: Organization;
  nicheTypeId?: string;
  nicheType?: NicheType;
  domainUserRoleTypeId?: string;
  domainUserRoleType?: DomainUserRoleTypeDto;
  domainCreatedDate?: string;
}

export interface DomainUserRoleTypeDto extends Base {
  name: string;
  description: string;
}

export interface DomainUserRoleFormData {
  name: string;
  description?: string;
}

export interface DomainUserRoleEditFormData extends DomainUserRoleFormData {
  id: string;
}

export type DomainForm = {
  name: string;
  url: string;
  organizationId: string;
  nicheTypeId?: string;
  domainUserRoleTypeId?: string;
};

export type DomainEditForm = {
  id: string;
  form: DomainForm;
};

export type DomainListItem = Domain & {
  backLinkCount: number;
  errorCount: number;
};
