import { Flex, Text, useBreakpointValue, useColorModeValue } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { selectDomain } from '../../store/slices/domain.slice';
import { useFetchBacklinkReportByIdQuery } from '../../store/api/backlinkReportApi';
import { AppCard } from '@backlinkit/shared';

const LinkReportHeader: React.FC = () => {
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
  const textColorSecondary = 'gray.400';
  const domain = useSelector(selectDomain);
  const variant = useBreakpointValue({
    base: '12px',
    md: '15px',
    lg: '18px',
  });

  const { id } = useParams();
  const { data: backlinkReport } = useFetchBacklinkReportByIdQuery(id!);
  const backlinks = backlinkReport?.backlinks;
  return (
    <AppCard maxH={'65px'} ml={'20px'} m={4}>
      <Flex dir="row" justify={'space-between'} align={'center'}>
        <Text color={textColorPrimary} fontWeight={'bold'} fontSize={variant} mr={8}>
          {'Link Report'}
        </Text>
        <Flex gap={10} align={'center'}>
          <Text color={textColorSecondary} fontSize="sm" fontWeight="400">
            BackLinks
          </Text>
          <Text color={textColorPrimary} fontSize={variant} fontWeight="700">
            {backlinks?.length ?? 0}
          </Text>
          <Text color={textColorSecondary} fontSize="sm" fontWeight="400">
            Errors
          </Text>
          <Text color={textColorPrimary} fontSize={variant} fontWeight="700">
            {domain ? domain.errorCount : 0}
          </Text>
        </Flex>
      </Flex>
    </AppCard>
  );
};
export default LinkReportHeader;
