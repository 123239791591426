import { Stack } from '@chakra-ui/react';
import {
  AppInput,
  AppButton,
  EditOrganizationSourceEntityTypeForm,
} from '@backlinkit/shared';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { BaseFormProps } from './base-forms';
import { useForm } from 'react-hook-form';

export interface OrganizationSourceTypeFormData {
  name: string;
  description: string;
}

export const organizationSourceTypeDataSchema = yup.object({
  name: yup.string().required('Field is required'),
  description: yup.string().required('Field is required'),
});

const organizationSourceTypeDefaultValues: OrganizationSourceTypeFormData = {
  name: '',
  description: '',
};

type OrganizationSourceFormProps<T> = {
  form?: EditOrganizationSourceEntityTypeForm;
} & BaseFormProps<T>;

export const OrganizationSourceTypeForm: React.FC<
  OrganizationSourceFormProps<OrganizationSourceTypeFormData>
> = ({ form, onSubmit }) => {
  const {
    control,
    formState: { isValid, errors },
    handleSubmit,
  } = useForm<OrganizationSourceTypeFormData>({
    defaultValues: form || organizationSourceTypeDefaultValues,
    resolver: yupResolver(organizationSourceTypeDataSchema),
    mode: 'onChange',
  });

  return (
    <Stack spacing={4} borderRadius={'15px'} p={4}>
      <AppInput<OrganizationSourceTypeFormData>
        name="name"
        control={control}
        error={errors.name}
        label="Name"
        placeHolder="Name"
      />
      <AppInput<OrganizationSourceTypeFormData>
        name="description"
        control={control}
        error={errors.description}
        label="Description"
        placeHolder="Description"
      />
      <AppButton isDisabled={!isValid} onClick={handleSubmit(onSubmit)}>
        Save
      </AppButton>
    </Stack>
  );
};
