import React from 'react';
import {
  Flex,
  Box,
  Card,
  Heading,
  CardBody,
  StackDivider,
  Stack,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverBody,
} from '@chakra-ui/react';
import { AppText } from '../app-text/app-text';
import { AppColors } from '../../theme';
import DOMPurify from 'dompurify';

export type CardListItem = {
  name: string;
  nameLabel?: string;
  landing?: string;
  landingLabel?: string;
  message?: string;
  messageLabel?: string;
  note?: string;
  noteLabel?: string;
  htmlLabel?: string;
  renderHtml?: string;
  renderHtmlPopover?: boolean;
  highLightItem?: boolean;
  highLightColor?: string;
  handleNavigate?: () => void;
  handleNav?: () => void;
};

export type AppCardListProps = {
  items: CardListItem[];
};

export const AppCardList: React.FC<AppCardListProps> = ({ items }) => {
  const renderCard = (item: CardListItem, index: number) => {
    return (
      <Card
        key={`${index}-${item.name}`}
        mb={2}
        textColor={item.highLightItem ? 'white' : AppColors.primary}
        bg={
          item.highLightItem
            ? item.highLightColor
              ? item.highLightColor
              : AppColors.tertiary
            : AppColors.appBackground
        }
      >
        <CardBody>
          <Stack divider={<StackDivider />} spacing="4">
            <Box>
              <Heading size="xs" textTransform="uppercase">
                {item.nameLabel ? item.nameLabel : 'Name'}
              </Heading>
              {/* <AppText cursor={'pointer'} onClick={item.handleNav}>
                {item.name}
              </AppText> */}

              <AppText
                onClick={item.handleNav}
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(item.name),
                }}
              ></AppText>
            </Box>
            {item.landing && (
              <Box>
                <Heading size="xs" textTransform="uppercase">
                  {item.landingLabel ? item.landingLabel : 'Landing'}
                </Heading>
                <AppText cursor={'pointer'} onClick={item.handleNavigate}>
                  {item.landing}
                </AppText>
              </Box>
            )}
            {item.message && (
              <Box>
                <Heading size="xs" textTransform="uppercase">
                  {item.messageLabel ? item.messageLabel : 'Message'}:
                </Heading>
                <AppText>{item.message}</AppText>
              </Box>
            )}

            {item.note && (
              <Box>
                <Heading size="xs" textTransform="uppercase">
                  {item.noteLabel ? item.noteLabel : 'Note'}:
                </Heading>
                <AppText>{item.note}</AppText>
              </Box>
            )}
            {item.renderHtml && !item.renderHtmlPopover && (
              <Box>
                <Heading size="xs" textTransform="uppercase">
                  {item.htmlLabel ? item.htmlLabel : 'Context'}:
                </Heading>
                <AppText
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(item.renderHtml),
                  }}
                ></AppText>
              </Box>
            )}
            {item.renderHtml && item.renderHtmlPopover && (
              <Popover trigger="hover">
                <PopoverTrigger>
                  <Box>
                    <Heading size="xs" textTransform="uppercase">
                      {item.htmlLabel ? item.htmlLabel : 'Context'}:
                    </Heading>
                    <AppText
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(item.renderHtml),
                      }}
                    ></AppText>
                  </Box>
                </PopoverTrigger>
                <PopoverContent>
                  <PopoverArrow />
                  <PopoverBody>
                    <Flex alignItems={'center'}>
                      <AppText
                        ml={2}
                        whiteSpace={'pre-wrap'}
                        wordBreak={'break-word'}
                        cursor={'pointer'}
                      >
                        {item.renderHtml}
                      </AppText>
                    </Flex>
                  </PopoverBody>
                </PopoverContent>
              </Popover>
            )}
          </Stack>
        </CardBody>
      </Card>
    );
  };

  return (
    <Box>
      <Flex direction={'column'}>{items.map((item, index) => renderCard(item, index))}</Flex>
    </Box>
  );
};
