import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { backlinksApi } from '../api/backlinksApi';
import { RootState } from '../store';
import { BackLink } from '@backlinkit/shared';

interface BacklinkState {
  backlink: BackLink | null;
  domainProfileBacklinks: BackLink[];
  tempBacklinkList: string[];
  tempBacklinkGoogleList: string[];
}

const initialState: BacklinkState = {
  backlink: null,
  domainProfileBacklinks: [],
  tempBacklinkList: [],
  tempBacklinkGoogleList: [],
};

export const backlinkSlice = createSlice({
  name: 'backlink',
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(
      backlinksApi.endpoints.fetchBacklinksByDomain.matchFulfilled,
      (state, { payload }) => {
        console.log('Setting slice', payload);
        return { ...state, domainProfileBacklinks: payload.data };
      }
    );
  },
  reducers: {
    setSelectedBacklink: (state, action: PayloadAction<BackLink>) => {
      return { ...state, backlink: action.payload };
    },
    resetTempBacklinkList: (state) => {
      return { ...state, tempBacklinkList: [] };
    },
    resetTempBacklinkGoogleList: (state) => {
      return { ...state, tempBacklinkGoogleList: [] };
    },
    updateTempBacklinkList: (state, action: PayloadAction<string>) => {
      let currentList: string[] = JSON.parse(JSON.stringify(state.tempBacklinkList));

      currentList.push(action.payload);

      return { ...state, tempBacklinkList: currentList };
    },
    updateTempBacklinkGoogleList: (state, action: PayloadAction<string>) => {
      let currentList: string[] = JSON.parse(JSON.stringify(state.tempBacklinkGoogleList));

      currentList.push(action.payload);

      return { ...state, tempBacklinkGoogleList: currentList };
    },
  },
});

export const {
  setSelectedBacklink,
  updateTempBacklinkList,
  resetTempBacklinkList,
  resetTempBacklinkGoogleList,
  updateTempBacklinkGoogleList,
} = backlinkSlice.actions;

export const selectTempBacklinkList = (state: RootState) => state.backlink.tempBacklinkList;
export const selectTempBacklinkGoogleList = (state: RootState) =>
  state.backlink.tempBacklinkGoogleList;
export const selectBacklink = (state: RootState) => state.backlink.backlink;
export const selectDomainProfileBacklinks = (state: RootState) =>
  state.backlink.domainProfileBacklinks;
