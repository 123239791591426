import { Tooltip, Image, Box } from '@chakra-ui/react';
import React from 'react';
import ReactCountryFlag from 'react-country-flag';
import { BaseBacklinkCell } from './base-backlink-cell';
import GlobalIcon from '../../../../assets/globalIcon.png';

type IpCellProps = BaseBacklinkCell & {};

const IpCell: React.FC<IpCellProps> = ({ item }) => {
  if (!item.backlinkMeta) {
    return <></>;
  }

  const handleDomainAgeCellClick = async () => {
    if (item?.backlinkMeta?.ip)
      window.open(
        `https://www.whois.com/whois/${item?.backlinkMeta?.ip}`,
        'blank'
      );
  };

  return (
    <Tooltip
      label={
        item?.backlinkMeta?.ipCountry &&
        item.backlinkMeta.ipCountry !== 'not found'
          ? item?.backlinkMeta?.ip
          : `${item?.backlinkMeta?.ip} - Country not found`
      }
    >
      <Box cursor="pointer" onClick={handleDomainAgeCellClick}>
        {item?.backlinkMeta?.ipCountry &&
        item?.backlinkMeta?.ipCountry !== 'not found' ? (
          <span>
            <ReactCountryFlag countryCode={item?.backlinkMeta?.ipCountry} />
          </span>
        ) : (
          <Image src={GlobalIcon} />
        )}
      </Box>
    </Tooltip>
  );
};

export default IpCell;
