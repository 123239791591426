import {
  Flex,
  Icon,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Tooltip,
  useToast,
} from '@chakra-ui/react';
import React from 'react';
import { FaAnchor } from 'react-icons/fa';
import { HiOutlineClipboardCopy } from 'react-icons/hi';
import { BaseBacklinkCell } from './base-backlink-cell';
import {
  useDialog,
  BackLinkListItem,
  CardListItem,
  LinkType,
  AppCardList,
  AppColors,
  AppText,
} from '@backlinkit/shared';

type AnchorCellProps = BaseBacklinkCell & {};

const AnchorCell: React.FC<AnchorCellProps> = ({ item }) => {
  const toast = useToast();
  const dialog = useDialog();

  const handleCopyToClipboardClick = (value: string) => {
    navigator.clipboard
      .writeText(value)
      .then(() => {
        toast({
          title: 'Copied to clipboard',
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
      })
      .catch((e) => {
        toast({
          title: 'Failed to copy to clipboard',
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      });
  };

  const handleAdditionalAnchorsClick = (item: BackLinkListItem) => {
    // if (item.backlinkMeta.linkType !== LinkType.Image) {
    const list: CardListItem[] = [];
    if (item.backlinkMeta?.additionalDomainAnchors) {
      const alertItem: CardListItem[] = item.backlinkMeta?.additionalDomainAnchors?.map(
        (anchor) => {
          return {
            name: `${anchor?.anchorText && `${anchor?.anchorText} - `} ${
              anchor.anchorRef ? `${anchor.anchorRef}` : ''
            }`,
            nameLabel: 'Anchor',
            message: anchor.linkType,
            messageLabel: 'Link Type',
            htmlLabel: 'Link Context',
            note: anchor.anchorRelValue,
            noteLabel: 'Link Rel',
            renderHtml:
              item.backlinkMeta?.linkType === LinkType.Redirect
                ? item.backlinkMeta?.pageDescription || ''
                : anchor?.anchorContext,
            renderHtmlPopover: item.backlinkMeta?.linkType === LinkType.Image,
          };
        }
      );
      list.push(...alertItem);
    }

    dialog({
      title: `Anchor Snippet View`,
      size: '4xl',
      render: (onSubmit) => {
        console.log('ANCHOR ITEM', item)
        return (
          <>
            <AppCardList
              items={[
                {
                  name: item.url,
                  nameLabel: 'Page Url',
                  message: item.backlinkMeta?.pageTitle,
                  messageLabel: 'Page Title',
                  highLightItem: true,
                },
              ]}
            ></AppCardList>
            <AppCardList items={list}></AppCardList>
          </>
        );
      },
    });
  };

  const hasAdditionalAnchors = () => {
    return (
      item?.backlinkMeta?.additionalDomainAnchors &&
      item?.backlinkMeta?.additionalDomainAnchors.length > 0
    );
  };

  return (
    <Flex flexDir={'row'} justifyContent={'start'} alignItems={'center'} onClick={() => item.anchor}>
      {hasAdditionalAnchors() && (
        <Tooltip label={'Anchor Snippet View'}>
          <IconButton
            bg={AppColors.primary}
            color={'white'}
            aria-label="column visibility button"
            isRound={false}
            _hover={{
              transform: 'translateY(-2px)',
              boxShadow: 'lg',
            }}
            mr={'10px'}
            size="xs"
            onClick={() => {
              handleAdditionalAnchorsClick(item);
            }}
          >
            <Icon as={FaAnchor} size="2rem" color={'white'} />
          </IconButton>
        </Tooltip>
      )}
      <Popover trigger="hover">
        <PopoverTrigger>
          <Flex>
            <AppText
              maxW={'200px'}
              isTruncated
              _hover={{
                cursor: 'pointer',
              }}
            >
              {item?.backlinkMeta?.linkType !== LinkType.Image && item?.anchor}
            </AppText>
          </Flex>
        </PopoverTrigger>
        <PopoverContent>
          <PopoverArrow />
          <PopoverBody>
            <Flex alignItems={'center'}>
              {item?.anchor && item?.backlinkMeta?.linkType !== LinkType.Image && (
                <Tooltip label={'Copy to clipboard'}>
                  <IconButton
                    bg={AppColors.secondary}
                    color={'white'}
                    aria-label="column visibility button"
                    isRound={false}
                    _hover={{
                      transform: 'translateY(-2px)',
                      boxShadow: 'lg',
                    }}
                    size="xs"
                    mr={hasAdditionalAnchors() ? '5px' : '15px'}
                    onClick={() => {
                      handleCopyToClipboardClick(item?.anchor ?? '');
                    }}
                  >
                    <Icon as={HiOutlineClipboardCopy} size="1rem" color={'white'} />
                  </IconButton>
                </Tooltip>
              )}

              <AppText ml={2} whiteSpace={'normal'}>
                {item?.backlinkMeta?.linkType !== LinkType.Image && item?.anchor}
              </AppText>
            </Flex>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Flex>
  );
};

export default AnchorCell;
