import { Box, Flex, Tooltip } from '@chakra-ui/react';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useLazyFetchBacklinkEflsQuery } from '../../../../store/api/backlinkEflApi';
import { setSelectedBacklink } from '../../../../store/slices/backlink.slice';
import { BaseBacklinkCell } from './base-backlink-cell';
import {
  useDialog,
  BackLinkListItem,
  CardListItem,
  AppCardList,
  AppText,
} from '@backlinkit/shared';

type EflCellProps = BaseBacklinkCell & {
  parentUrl: string;
};

const EflCell: React.FC<EflCellProps> = ({ parentUrl, item }) => {
  const dialog = useDialog();
  const dispatch = useDispatch();

  const [getBacklinkEflsData] = useLazyFetchBacklinkEflsQuery();

  const handleEflListDialog = async (item: BackLinkListItem) => {
    const backlinkEfl = await getBacklinkEflsData(item.id).unwrap();

    const requiredList = backlinkEfl ? backlinkEfl : undefined;

    const cardList: CardListItem[] = [];

    if (requiredList) {
      const filteredLinks = requiredList.links.filter((x) => x.href && x.href.includes('http'));

      const alertItem: CardListItem[] = filteredLinks.map((backlinkEfl) => {
        return {
          name: backlinkEfl.href,
          nameLabel: 'HREF',
          message: backlinkEfl.type,
          messageLabel: 'Type',
          highLightItem: backlinkEfl.href ? backlinkEfl.href.includes(parentUrl) : false,
        };
      });
      cardList.push(...alertItem);
    }

    await dispatch(setSelectedBacklink(item));

    dialog({
      title: `${item.url} EFL's`,
      size: 'xl',
      render: (onSubmit) => {
        return (
          <Box>
            <AppCardList items={cardList}></AppCardList>
          </Box>
        );
      },
    });
  };

  if (!item.backlinkMeta) {
    return <></>;
  }

  return (
    <Box>
      <Tooltip
        label={
          <Flex flexDirection={'column'}>
            <AppText>External Follow: {item?.backlinkMeta?.externalFollowCount}</AppText>
            <AppText>External NoFollow: {item?.backlinkMeta?.externalNoFollowCount}</AppText>
            <AppText>Internal: {item?.backlinkMeta?.internalFollowCount}</AppText>
          </Flex>
        }
      >
        <span>
          <AppText color={'green'} cursor={'pointer'} onClick={() => handleEflListDialog(item)}>
            {item?.backlinkMeta?.externalFollowCount}
          </AppText>
        </span>
      </Tooltip>
    </Box>
  );
};

export default EflCell;
