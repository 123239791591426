import { Button, Flex, Menu, MenuButton, MenuItem, MenuList, useToast } from '@chakra-ui/react';
import BaseLayout from '../../components/layouts/base-layout';
import {
  AppColors,
  AppConfirm,
  AppDataTable,
  ColumnDefinitionType,
  ColumnSpecialTypes,
  FAQ,
  OrganizationTypeEditFormData,
  OrganizationTypeFormData,
  useLoading,
  usePanel,
  AppButton,
  useDataTable,
  AppPaginator,
  OrganizationLinkBuildingStratergiesTypeDto,
} from '@backlinkit/shared';
import {
  useFetchAllOrganizationLinkBuildingStategiesTypesQuery,
  useCreateOrganizationLinkBuildingStrategiesTypeMutation,
  useDeleteOrganizationLinkBuildingStrategiesTypeMutation,
  useUpdateOrganizationLinkBuildingStratergiesTypeMutation,
} from '../../store/api/organizationLinkBuilidingStrategiesTypeApi';
import OrganizationTypeForm from '../../components/forms/orgnization-type-form';
import { ChevronDownIcon, SettingsIcon, DeleteIcon } from '@chakra-ui/icons';
import { Text, useColorModeValue } from '@chakra-ui/react';

const OrganizationLinkBuildingStratergiesTypes: React.FC = () => {
  const title = 'Organization Link Building Types';
  const { setLoading } = useLoading();
  const toast = useToast();
  const panel = usePanel();
  const {
    data: organizationLinkBuildingStrategiesTypes,
    refetch: refetchOrganizationLinkBuildingStrategiesTypes,
  } = useFetchAllOrganizationLinkBuildingStategiesTypesQuery({
    refetchOnMountArgChange: true,
  });
  const [createOrganizationLinkBuildingType] = useCreateOrganizationLinkBuildingStrategiesTypeMutation();
  const [updateOrganizationLinkBuildingType] = useUpdateOrganizationLinkBuildingStratergiesTypeMutation();
  const [deleteOrganizationLinkBuildingType] = useDeleteOrganizationLinkBuildingStrategiesTypeMutation();

  const {
    handleSearchChange,
    handleSortByColumnClick,
    handleOnPageChange,
    pagination,
    setPagination,
    nextPaginationQueryParams,
  } = useDataTable<FAQ>();

  const upsertOrganizationLinkBuildingStrategiesType = async (formData: any) => {
    setLoading(true);
    let eddittedOrganizationLinkBuildingStrategiesType = formData.id ? true : false;
    try {
      if (!eddittedOrganizationLinkBuildingStrategiesType) {
        const createFormData = formData as OrganizationTypeFormData;
        await createOrganizationLinkBuildingType({
          name: createFormData.name,
          description: createFormData.description,
        }).unwrap();
      } else {
        const editFormData = formData as OrganizationTypeEditFormData;
        await updateOrganizationLinkBuildingType({
          id: editFormData.id ?? '',
          name: editFormData.name,
          description: editFormData.description,
        }).unwrap();
      }
      toast({
        title: `Organization Link Building Strategies Type ${
          !eddittedOrganizationLinkBuildingStrategiesType ? 'added' : 'updated'
        }`,
        description: `We've ${
          !eddittedOrganizationLinkBuildingStrategiesType ? 'added' : 'updated'
        } your Organization Link Building Strategies Type for you, come back soon to monitor the status`,
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
      refetchOrganizationLinkBuildingStrategiesTypes();
    } catch (err) {
      toast({
        title: `Organization Link Building Strategies Type ${
          !eddittedOrganizationLinkBuildingStrategiesType ? 'adding' : 'updating'
        }. error= ${err}`,
        description: `We've run into a problem ${
          !eddittedOrganizationLinkBuildingStrategiesType ? 'adding' : 'updating'
        } your Organization Link Building Strategies Type, Contact support for help`,
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
    refetchOrganizationLinkBuildingStrategiesTypes();
    setLoading(false);
  };

  const handleCreateOrganizationLinkBuildingStrategiesType = () => {
    panel({
      title: 'Create Organization Link Building Strategies Type',
      size: 'lg',
      render: (onSubmit) => {
        return (
          <OrganizationTypeForm
            onSubmit={async (formData) => {
              await upsertOrganizationLinkBuildingStrategiesType(formData);
              onSubmit();
            }}
          />
        );
      },
    });
  };

  const handleEditOrganizationLinkBuildingStrategiesType = (
    item: OrganizationLinkBuildingStratergiesTypeDto
  ) => {
    const editForm: OrganizationTypeEditFormData = {
      id: item.id,
      name: item.name,
      description: item.description,
    };
    console.log('item', item)
    panel({
      title: 'Edit Organization Link Experience Type',
      size: 'lg',
      render: (onSubmit) => {
        return (
          <OrganizationTypeForm
            form={editForm}
            onSubmit={async (formData) => {
              await upsertOrganizationLinkBuildingStrategiesType(formData);
              onSubmit();
            }}
          />
        );
      },
    });
  };

  const handleDeleteOrganizationLinkBuildingStrategiesType = async (
    item: OrganizationLinkBuildingStratergiesTypeDto
  ) => {
    setLoading(true);
    try {
      const handleDelete = deleteOrganizationLinkBuildingType(item.id).unwrap();
      await handleDelete;
    } catch (err) {
      toast({
        title: 'Organization Link Building Strategies Type delete error',
        description:
          "We've run into a problem deleting the selected Organization Link Building Strategies Type, contact support for help",
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }

    toast({
      title: 'Selected Organization Link Building Strategies Type deleted',
      description: "We've deleted the Organization Link Building Strategies Type you selected",
      status: 'success',
      duration: 9000,
      isClosable: true,
    });
    refetchOrganizationLinkBuildingStrategiesTypes();
    setLoading(false);
  };

  const handleDeleteConfirm = (item: OrganizationLinkBuildingStratergiesTypeDto) => {
    panel({
      title: 'Delete Organization Link Building Strategies Type',
      size: 'lg',
      render: (onSubmit, onCancel) => {
        return (
          <AppConfirm
            message="Are you sure you want to delete the selected Organization Link Building Strategies Type?"
            onConfirm={async () => {
              onSubmit();
              await handleDeleteOrganizationLinkBuildingStrategiesType(item);
            }}
            onCancel={() => {
              onCancel();
            }}
          />
        );
      },
    });
  };

  const renderDefaultTableActions = (item: OrganizationLinkBuildingStratergiesTypeDto) => {
    return (
      <Menu>
        <MenuButton
          color={AppColors.primary}
          border={`1px solid ${AppColors.appBorder}`}
          size="xs"
          bgColor={'white'}
          as={Button}
          rightIcon={<ChevronDownIcon />}
          borderRadius="full"
          _hover={{
            transform: 'translateY(-2px)',
            boxShadow: 'lg',
          }}
        >
          Actions
        </MenuButton>
        <MenuList>
          <MenuItem
            icon={<SettingsIcon />}
            onClick={() => handleEditOrganizationLinkBuildingStrategiesType(item)}
          >
            Edit
          </MenuItem>
          <MenuItem icon={<DeleteIcon />} onClick={() => handleDeleteConfirm(item)}>
            Delete
          </MenuItem>
        </MenuList>
      </Menu>
    );
  };

  const organizationLinkBuildingStrategiesTypeColumns: ColumnDefinitionType<OrganizationLinkBuildingStratergiesTypeDto>[] =
    [
      {
        type: ColumnSpecialTypes.date,
        key: 'dateCreated',
        header: 'Date Added',
        headerSortable: true,
        columnSearchable: true,
      },
      {
        header: 'Name',
        key: 'name',
        headerSortable: true,
        columnSearchable: true,
      },
      {
        header: 'Description',
        key: 'description',
        headerSortable: true,
        columnSearchable: true,
      },
      {
        key: 'custom',
        header: 'Actions',
        headerSortable: false,
        render: renderDefaultTableActions,
      },
    ];

  return (
    <BaseLayout>
      <Flex flex={1} direction={'column'}>
        <Flex m={4} justifyContent={'space-between'} align={'center'} minH={'65px'}>
          <Text fontSize={'2xl'} fontWeight={'bold'} color={AppColors.primary}>
            {title}
          </Text>
          <AppButton
            bg={'white'}
            size={'sm'}
            border={`1px solid ${AppColors.appBorder}`}
            borderRadius={'full'}
            onClick={handleCreateOrganizationLinkBuildingStrategiesType}
          >
            Add Organization Link Building Strategies Type
          </AppButton>
        </Flex>
        <Flex
          bg={useColorModeValue('white', 'gray.800')}
          rounded={'2xl'}
          overflow={'hidden'}
          padding={'4'}
          m={4}
          flexDir={'column'}
          flex={1}
        >
          <AppDataTable
            data={organizationLinkBuildingStrategiesTypes || []}
            noDataMessage="No Organization Link Building Strategies Type's yet, start by adding some!"
            columns={organizationLinkBuildingStrategiesTypeColumns}
          />
          <AppPaginator pagination={pagination} onPageChange={handleOnPageChange}></AppPaginator>
        </Flex>
      </Flex>
    </BaseLayout>
  );
};

export default OrganizationLinkBuildingStratergiesTypes;
