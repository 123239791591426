import React, { useEffect, useState } from 'react';
import { Flex, FormLabel } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { BaseFormProps } from './base-forms';
import {
  OrganizationForm,
  AppInput,
  SelectOption,
  SelectListFilter,
  AppButton,
  AppCheckbox,
  AppDatePicker,
} from '@backlinkit/shared';
import { useSelector } from 'react-redux';
import { selectProducts } from '../../store/slices/products.slice';

export interface OrganizationFormData {
  name: string;
  productId: string;
  email: string;
  subscriptionIsActive?: boolean;
  expiryDate?: Date;
  availableCredit: number;
}

export const organizationFormDataSchema = yup.object({
  name: yup.string().required('Field is required'),
  productId: yup.string().required('Field is required'),
  email: yup.string().required('Field is required'),
  subscriptionIsActive: yup.boolean(),
  expiryDate: yup.date(),
  availableCredit: yup.number(),
});

const organizationFormDefaultValues: OrganizationForm = {
  name: '',
  productId: '',
  email: '',
  subscriptionIsActive: false,
  productExpiryDate: undefined,
  availableCredit: 0,
};

type OrganizationFormProps<T> = {
  form?: OrganizationForm;
} & BaseFormProps<T>;

const OrganizationsForm: React.FC<OrganizationFormProps<OrganizationForm>> = ({
  form,
  onSubmit,
}) => {
  const {
    control: organizationControl,
    formState: { isValid, errors },
    setValue,
    handleSubmit,
  } = useForm<OrganizationFormData>({
    defaultValues: form || organizationFormDefaultValues,
    resolver: yupResolver(organizationFormDataSchema),
    mode: 'all',
  });

  const products = useSelector(selectProducts);
  const [productOptions, setProductOptions] = useState<SelectOption[]>([]);
  const [subscriptionActive, setSubscriptionActive] = useState<boolean>(false);

  useEffect(() => {
    const selectors: SelectOption[] = [];
    products?.map((x) => {
      selectors.push({
        label: x.name,
        value: x.id,
      });
    });
    setProductOptions(selectors);
  }, []);

  const handleSelect = (checked: boolean) => {
    if (checked) {
      setSubscriptionActive(true);
    } else {
      setSubscriptionActive(false);
    }
  };

  return (
    <Flex w={'full'} gap={4} direction={'column'} p={4}>
      <AppInput<OrganizationFormData>
        name="name"
        control={organizationControl}
        error={errors.name}
        label={'Organization Name'}
        placeHolder="Organization Name"
      />
      <AppInput<OrganizationFormData>
        name="email"
        control={organizationControl}
        error={errors.email}
        label="Email"
        placeHolder="Email"
      />
      <FormLabel mb={-2}>Product</FormLabel>
      <SelectListFilter
        options={productOptions}
        placeholder="Product"
        name="productId"
        isInModal
        isMulti={false}
        showClear={false}
        onSelectionChange={(item: SelectOption[]) => {
          setValue('productId', item[0].value!);
        }}
      />
      <AppCheckbox<OrganizationFormData>
        name="subscriptionIsActive"
        control={organizationControl}
        error={errors.subscriptionIsActive}
        checkboxLabel="Activate Subscription?"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          handleSelect(e.currentTarget.checked);
        }}
      />
      <AppDatePicker<OrganizationFormData>
        name="expiryDate"
        control={organizationControl}
        error={errors.expiryDate}
        label="Product Expiry Date"
      />
      <AppInput<OrganizationFormData>
        name="availableCredit"
        inputType="number"
        control={organizationControl}
        error={errors.availableCredit}
        label="Available Credit"
      />
      <AppButton w={'full'} onClick={handleSubmit(onSubmit)}>
        Save
      </AppButton>
    </Flex>
  );
};

export default OrganizationsForm;
