import { OrganizationTypeDto, OrganizationTypeFormData } from '@backlinkit/shared';
import { AppButton, AppInput } from '@backlinkit/shared';
import { Stack } from '@chakra-ui/react';
import * as yup from 'yup';
import { BaseFormProps } from './base-forms';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

export const orgnizationTypeFormDefaultValues: OrganizationTypeFormData = {
  name: '',
  description: '',
};

const organizationTypeFormDataSchema = yup.object({
  name: yup.string().required('Field is required'),
  description: yup.string().required('Field is required'),
});

type OrganizationTypeFormProps<T> = {
  form?: OrganizationTypeDto;
} & BaseFormProps<T>;

const OrganizationTypeForm: React.FC<OrganizationTypeFormProps<OrganizationTypeFormData>> = ({
  form,
  onSubmit,
}) => {
  const {
    control: organizationTypeControl,
    formState: { isValid, errors },
    handleSubmit,
  } = useForm<OrganizationTypeFormData>({
    defaultValues: form || orgnizationTypeFormDefaultValues,
    resolver: yupResolver(organizationTypeFormDataSchema),
    mode: 'onChange',
  });

  return (
    <Stack spacing={4} padding={4}>
      <AppInput<OrganizationTypeFormData>
        name="name"
        error={errors.name}
        control={organizationTypeControl}
        label="Name:"
      />
      <AppInput<OrganizationTypeFormData>
        name="description"
        error={errors.description}
        control={organizationTypeControl}
        label="Description:"
      />
      <AppButton disabled={!isValid} onClick={handleSubmit(onSubmit)}>
        Save
      </AppButton>
    </Stack>
  );
};

export default OrganizationTypeForm;
