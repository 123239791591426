import { Flex, useColorModeValue, Text, Box } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import BaseLayout from '../../components/layouts/base-layout';
import { useFetchBacklinkReportByIdQuery } from '../../store/api/backlinkReportApi';
import { useSelector } from 'react-redux';
import { selectDomain } from '../../store/slices/domain.slice';
import LinkReportHeader from '../../components/layouts/link-report-header';
import { useLazyFetchBacklinksByIdsQuery } from '../../store/api/backlinksApi';
import { useParams } from 'react-router-dom';
import ExternalReportTable from './external-report-table/external-report-table';
import { BackLinkListItem } from '@backlinkit/shared';

const ExternalReportsPage: React.FC = () => {
  const title = 'Link Report';
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
  const { id } = useParams();
  const [data, setData] = useState<BackLinkListItem[]>();
  const getReport = useFetchBacklinkReportByIdQuery;
  const [getBacklinks] = useLazyFetchBacklinksByIdsQuery();
  const domain = useSelector(selectDomain);
  const { data: report } = getReport(id!);

  // const setUpBacklinks = async () => {
  //   console.log(report!.backlinkIds);
  //   const backlinks = await getBacklinks(report?.backlinkIds || []).unwrap();
  //   console.log(backlinks);
  //   setData(backlinks);
  // };

  // useEffect(() => {
  //   if (report) {
  //     setUpBacklinks();
  //   }
  // }, [report]);

  return (
    <>
      <Box>
        <BaseLayout>
          <Flex align={'center'} justifyContent={'center'} flex={1}>
            <Flex direction={'column'} w={'100%'}>
              <LinkReportHeader />
              <Flex mx={4}>
                <Text
                  color={textColorPrimary}
                  fontWeight={'bold'}
                  fontSize={'2xl'}
                  mt={'10px'}
                  mb={'4px'}
                >
                  {title}
                </Text>
              </Flex>
              <Flex
                bg={useColorModeValue('white', 'gray.800')}
                rounded={'2xl'}
                overflow={'hidden'}
                p={4}
                m={4}
                flexDir={'column'}
                flex={1}
              >
                <ExternalReportTable data={data ?? []} parentUrl={domain?.url || ''} />
              </Flex>
            </Flex>
          </Flex>
        </BaseLayout>
      </Box>
    </>
  );
};
export default ExternalReportsPage;
