import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { AppFetchBaseQuery } from './apiBase';
import { BatchType, BatchTypeForm, BatchTypeEditForm } from '@backlinkit/shared';

export const batchTypesApi = createApi({
  reducerPath: 'batchTypeApi',
  baseQuery: AppFetchBaseQuery,
  endpoints: (build) => ({
    fetchAllBatchTypes: build.query<BatchType[], any>({
      query: () => ({
        url: '/batchtype',
        method: 'GET',
      }),
    }),
    createBatchType: build.mutation<BatchType, BatchTypeForm>({
      query: (body) => ({
        url: `/batchtype/create`,
        method: 'POST',
        body: body,
      }),
    }),
    fetchBatchTypeById: build.query<BatchType, string>({
      query: (id) => ({
        url: `/batchType/${id}`,
        method: 'GET',
      }),
    }),
    editBatchType: build.mutation<BatchType, BatchTypeEditForm>({
      query: (payload) => ({
        url: `/batchtype/update/${payload.id}`,
        method: 'PUT',
        body: payload,
      }),
    }),
    deleteBatchType: build.mutation<BatchType, string>({
      query: (id) => ({
        url: `/batchtype/delete/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useCreateBatchTypeMutation,
  useDeleteBatchTypeMutation,
  useEditBatchTypeMutation,
  useFetchAllBatchTypesQuery,
  useFetchBatchTypeByIdQuery,
} = batchTypesApi;
