import { Base } from './base';

export interface PlatformExternalKeyDto extends Base {
  type: PlatformExternalTypes;
  externalKey: string;
}

export enum PlatformExternalTypes {
  BACKLINKEXTERNALREPORT,
  ORGANIZATIONVERIFICATION,
}
