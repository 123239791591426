import { Flex, Tag, TagLabel } from '@chakra-ui/react';
import React from 'react';
import { BaseBacklinkCell } from './base-backlink-cell';
import { AppColors } from '@backlinkit/shared';

type SecondTierLinkCountCellProps = BaseBacklinkCell & {};

const SecondTierLinkCountCell: React.FC<SecondTierLinkCountCellProps> = ({ item }) => {
  return (
    <Flex direction={'row'}>
      <Tag borderRadius="full" bgColor={AppColors.secondary} textColor={'white'}>
        <TagLabel>{item?.secondTierLinkCount}</TagLabel>
      </Tag>
    </Flex>
  );
};

export default SecondTierLinkCountCell;
