import React from 'react';
import { Tooltip } from '@chakra-ui/react';
import { format } from 'date-fns';
import { AppText } from '@backlinkit/shared';

type LastScrapeCellProps = {
  updatedDate?: Date;
  scrapeCount?: number;
};

const LastScrapeCell = React.memo<LastScrapeCellProps>(({ updatedDate, scrapeCount }) => {
  if (updatedDate) {
    const updatedAt = format(new Date(updatedDate), 'dd MMM yyyy');

    return (
      <Tooltip label={`Last Processed - ${updatedAt} `}>
        <span>
          <AppText>{scrapeCount}</AppText>
        </span>
      </Tooltip>
    );
  } else {
    return <></>;
  }
});

export default LastScrapeCell;
