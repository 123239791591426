import { Stack, Image, Checkbox, FormLabel } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { BugEditForm, AppInput, AppButton } from '@backlinkit/shared';
import { BaseFormProps } from './base-forms';

const bugFormDefaultValues: BugEditForm = {
  id: '',
  name: '',
  description: '',
  organizationId: '',
  userId: '',
  isFixed: false,
};

const bugFormDataSchema = yup.object({
  id: yup.string(),
  name: yup.string().required('Field is required'),
  description: yup.string().required('Field is required'),
  organizationId: yup.string(),
  userId: yup.string(),
  isFixed: yup.boolean(),
});

type BugEditFormProps<T> = {
  form?: BugEditForm;
} & BaseFormProps<T>;

const EditBugForm: React.FC<BugEditFormProps<BugEditForm>> = ({ form, onSubmit }) => {
  const {
    control: bugControl,
    formState: { isValid, errors },
    setValue,
    getValues,
    handleSubmit,
  } = useForm<BugEditForm>({
    defaultValues: form || bugFormDefaultValues,
    resolver: yupResolver(bugFormDataSchema),
    mode: 'onChange',
  });

  const handleFormSubmit = () => {
    const formData = getValues();
    onSubmit(formData)
  }

  return (
    <Stack spacing={4} p={4}>
      <AppInput<BugEditForm> control={bugControl} error={errors.name} label="Name" name="name" />
      <AppInput<BugEditForm>
        control={bugControl}
        error={errors.description}
        textArea
        label="Description"
        name="description"
      />
      <FormLabel mb={'-10px'}>Bug Fixed?</FormLabel>
      <Checkbox
        name="isFixed"
        defaultChecked={form?.isFixed}
        onChange={(e) => {
          setValue('isFixed', e.target.checked);
        }}
      />
      <Image src={form?.screenshot} objectFit={'contain'} objectPosition={'center'} />
      <AppButton w={'full'} mt={8} disabled={!isValid} onClick={handleFormSubmit}>
        Save
      </AppButton>
    </Stack>
  );
};

export default EditBugForm;
