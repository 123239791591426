import { createApi } from '@reduxjs/toolkit/query/react';
import { BacklinkReport, PaginatedData } from '@backlinkit/shared';
import { AppFetchBaseQuery } from './apiBase';

export const backlinkReportApi = createApi({
  reducerPath: 'backlinkReportApi',
  baseQuery: AppFetchBaseQuery,
  endpoints: (build) => ({
    fetchBacklinkReports: build.query<PaginatedData<BacklinkReport>, any>({
      query: () => ({
        url: `/userbacklinkexternalreport/`,
        method: 'GET',
      }),
      transformResponse: (response: BacklinkReport[]) => {
        return {
          data: response,
          // pagination: response.meta.pagination,
        } as PaginatedData<BacklinkReport>;
      },
    }),
    fetchBacklinkReportById: build.query<BacklinkReport, string>({
      query: (id) => ({
        url: `/userbacklinkexternalreport/${id}`,
        method: 'GET',
      }),
      transformResponse: (response: BacklinkReport) => {
        return response;
      },
    }),
    saveBacklinkReport: build.mutation<BacklinkReport, BacklinkReport>({
      query: (payload) => ({
        url: `/userbacklinkexternalreport/create`,
        method: 'POST',
        body: payload,
      }),
    }),
    deleteBacklinkReport: build.mutation<BacklinkReport, string>({
      query: (id) => ({
        url: `/userbacklinkexternalreport/delete/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useFetchBacklinkReportsQuery,
  useLazyFetchBacklinkReportByIdQuery,
  useFetchBacklinkReportByIdQuery,
  useSaveBacklinkReportMutation,
  useDeleteBacklinkReportMutation,
} = backlinkReportApi;
