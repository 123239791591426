import React, { useState } from 'react';
import { Flex } from '@chakra-ui/react';
import { AlertListItem, AppAlertItem } from './app-alert-item';
import { AppButton } from '../app-button/app-button';

export type AppAlertListProps = {
  items: AlertListItem[];
  showName: boolean;
  showType: boolean;
  itemsClearable?: boolean;
  onSaveClearedAlerts?: (items: AlertListItem[]) => void;
};

export const AppAlertList: React.FC<AppAlertListProps> = ({
  items,
  showName = true,
  showType = true,
  itemsClearable = false,
  onSaveClearedAlerts,
}) => {
  const [itemsToClear, setitemsToClear] = useState<AlertListItem[]>([]);
  const [alertItems, setAlertItems] = useState<AlertListItem[]>(items);

  const handleOnItemClear = (item: AlertListItem) => {
    console.log(alertItems);
    setitemsToClear([...itemsToClear, item]);
    setAlertItems(alertItems.filter((x) => x !== item));
  };

  const handleSaveClearedAlerts = () => {
    onSaveClearedAlerts && onSaveClearedAlerts(alertItems);
  };

  return (
    <Flex flexDir={'column'}>
      {alertItems.some((x) => x.alertType === 'error') && (
        <Flex direction={'column'}>
          {alertItems
            .filter((x) => x.alertType === 'error')
            .map((item, index) => {
              return (
                <AppAlertItem
                  key={`${index}-${item}`}
                  item={item}
                  showName={showName}
                  showType={showType}
                  itemsClearable={itemsClearable}
                  onItemClearClick={handleOnItemClear}
                ></AppAlertItem>
              );
            })}
        </Flex>
      )}

      {alertItems.some((x) => x.alertType === 'warning') && (
        <Flex direction={'column'}>
          {alertItems
            .filter((x) => x.alertType === 'warning')
            .map((item, index) => {
              return (
                <AppAlertItem
                  key={index}
                  item={item}
                  showName={showName}
                  showType={showType}
                  itemsClearable={itemsClearable}
                  onItemClearClick={handleOnItemClear}
                ></AppAlertItem>
              );
            })}
        </Flex>
      )}

      {alertItems.some((x) => x.alertType === 'info') && (
        <Flex direction={'column'}>
          {alertItems
            .filter((x) => x.alertType === 'info')
            .map((item, index) => {
              return (
                <AppAlertItem
                  key={index}
                  item={item}
                  showName={showName}
                  showType={showType}
                  itemsClearable={itemsClearable}
                  onItemClearClick={handleOnItemClear}
                ></AppAlertItem>
              );
            })}
        </Flex>
      )}
      {itemsClearable && (
        <Flex>
          <AppButton
            mt={8}
            w={'full'}
            isDisabled={!itemsClearable && itemsToClear.length === 0}
            onClick={() => {
              handleSaveClearedAlerts();
            }}
          >
            {'Save!'}
          </AppButton>
        </Flex>
      )}
    </Flex>
  );
};
