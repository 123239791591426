import { NavItemCollapseProps, AppColors } from '@backlinkit/shared';
import { Box, Collapse, Flex, Icon, Link, Stack, useDisclosure } from '@chakra-ui/react';
import { FiChevronDown } from 'react-icons/fi';
import { NavLink as RouterLink } from 'react-router-dom';

export const NavItemCollapse: React.FC<NavItemCollapseProps> = ({ name, children }) => {
  const { isOpen, onToggle } = useDisclosure();
  return (
    <Box>
      <Flex
        justifyContent={'space-between'}
        align="center"
        p="4"
        mx="4"
        borderRadius="lg"
        role="group"
        cursor="pointer"
        _hover={{
          bg: 'white',
          color: AppColors.primary,
        }}
        onClick={onToggle}
      >
        <Flex>
          <Box
            color={'white'}
            _groupHover={{
              color: AppColors.primary,
            }}
          >
            {name}
          </Box>
        </Flex>
        <PopoverIcon isOpen={isOpen} />
      </Flex>

      <Collapse in={isOpen} animateOpacity>
        <Stack spacing="1" alignItems="stretch" ps="8" py="1">
          {children?.map((item) => (
            <Link
              key={item.label}
              as={RouterLink}
              to={item.href ?? ''}
              style={{ textDecoration: 'none' }}
              _focus={{ boxShadow: 'none' }}
            >
              <Flex
                align="center"
                p="4"
                mx="4"
                borderRadius="lg"
                role="group"
                cursor="pointer"
                _hover={{
                  bg: 'white',
                  color: AppColors.primary,
                }}
              >
                <Box
                  color={'white'}
                  _groupHover={{
                    color: AppColors.primary,
                  }}
                >
                  {item.label}
                </Box>
              </Flex>
            </Link>
          ))}
        </Stack>
      </Collapse>
    </Box>
  );
};

export const PopoverIcon = (props: { isOpen: boolean }) => {
  const iconStyles = {
    transform: props.isOpen ? 'rotate(-180deg)' : undefined,
    transition: 'transform 0.2s',
    transformOrigin: 'center',
  };
  return <Icon color={'white'} aria-hidden as={FiChevronDown} __css={iconStyles} />;
};
