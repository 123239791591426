import {
  AppColors,
  AppConfirm,
  AppDataTable,
  AppPaginator,
  AppText,
  Organization,
  OrganizationEditForm,
  OrganizationForm,
  PageChangeEvent,
  useDataTable,
  useLoading,
  usePanel,
} from '@backlinkit/shared';
import { ColumnDefinitionType, ColumnSpecialTypes } from '@backlinkit/shared';
import { useEffect, useState } from 'react';
import {
  useDeleteOrganizationMutation,
  useEditOrganizationMutation,
  useFetchAllOrganizationsQuery,
  useOrganizationCreateMutation,
} from '../../../store/api/organizationApi';
import { Button, Flex, Menu, MenuButton, MenuItem, MenuList, useToast } from '@chakra-ui/react';
import { ChevronDownIcon, DeleteIcon, SettingsIcon } from '@chakra-ui/icons';
import OrganizationsForm from '../../forms/organization-form';
import addDays from 'date-fns/addDays';
import { useFetchAllProductsQuery } from '../../../store/api/productsApi';

const OrganizationTable: React.FC = () => {
  const { data: organizations, refetch: refetchOrganizations } = useFetchAllOrganizationsQuery({
    refetchOnMountOrArgChange: false,
  });
  const { data: products, refetch: refetchProducts } = useFetchAllProductsQuery({
    refetchOnMountArgChange: true,
  });

  const { setLoading } = useLoading();
  const toast = useToast();
  const panel = usePanel();
  const [saveOrganizationTrigger] = useOrganizationCreateMutation();
  const [editOrganizationTrigger] = useEditOrganizationMutation();
  const [deleteOrganizationTrigger] = useDeleteOrganizationMutation();

  const [organizationData, setOrganizationData] = useState<Organization[]>();

  useEffect(() => {
    refetchOrganizations();
  }, [organizations]);

  const upsertOrganization = async (formData: any) => {
    setLoading(true);
    let isEdit = formData.id ? true : false;
    try {
      if (!isEdit) {
        const createForm = formData as OrganizationForm;
        await saveOrganizationTrigger({
          name: createForm.name,
          productId: createForm.productId,
          email: createForm.email,
          subscriptionIsActive: createForm.subscriptionIsActive,
          productExpiryDate: createForm.productExpiryDate,
          availableCredit: createForm.availableCredit,
        }).unwrap();
      } else {
        const editForm = formData as OrganizationEditForm;
        await editOrganizationTrigger({
          id: editForm.id ?? '',
          name: editForm.name,
          productId: editForm.productId,
          email: editForm.email,
          subscriptionIsActive: editForm.subscriptionIsActive,
          productExpiryDate: editForm.productExpiryDate,
          availableCredit: editForm.availableCredit,
          paymentCustomerId: editForm.paymentCustomerId,
        }).unwrap();
      }
      toast({
        title: 'Organization saved',
        description: `We've ${
          isEdit ? 'updated' : 'created'
        } your organization, come back soon to check the status`,
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
    } catch (e) {
      toast({
        title: 'Organization saving issue',
        description: `We've run into an issue ${
          isEdit ? 'updating' : 'creating'
        } your organization`,
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
    refetchOrganizations();
    setLoading(false);
  };

  const handleEdit = (item: Organization) => {
    const organizationForm: OrganizationEditForm = {
      id: item.id ?? '',
      name: item.name,
      email: item.email,
      subscriptionIsActive: item.subscriptionIsActive,
      productExpiryDate: item.subscriptionExpiryDate ?? addDays(new Date(), 30).toDateString(),
      productId: item.productId,
      availableCredit: item.availableCredit,
      paymentCustomerId: item.paymentCustomerId,
    };
    panel({
      title: 'Edit Organization',
      size: 'lg',
      render: (onSubmit) => {
        return (
          <OrganizationsForm
            form={organizationForm}
            onSubmit={async (formData) => {
              onSubmit();
              await upsertOrganization(formData);
            }}
          />
        );
      },
    });
  };

  const handleDelete = async (item: Organization) => {
    setLoading(true);
    try {
      const deletePromise = deleteOrganizationTrigger(item.id).unwrap();
      await deletePromise;
    } catch (e) {
      toast({
        title: 'Organization deleting issue',
        description:
          "We've run into a problem deleting the organization you selected. Contact support for help",
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
    toast({
      title: 'Organization deleted',
      status: 'success',
      description: "We've deleted the organization you selected",
      duration: 9000,
      isClosable: true,
    });
    setLoading(false);
    refetchOrganizations();
  };

  useEffect(() => {
    setOrganizationData(organizations);
  }, [organizations]);

  const {
    handleOnPageChange,
    handleSearchChange,
    nextPaginationQueryParams,
    pagination,
    selected,
    setPagination,
  } = useDataTable<Organization>();

  const handleDeleteConfirm = (item: Organization) => {
    panel({
      title: 'Delete Organization',
      size: 'lg',
      render: (onSubmit, onCancel) => {
        return (
          <AppConfirm
            message="Are you sure you want to delete the selected organization?"
            onConfirm={async () => {
              onSubmit();
              await handleDelete(item);
            }}
            onCancel={() => {
              onCancel();
            }}
          />
        );
      },
    });
  };

  const renderDefaultTableActions = (organization: Organization) => {
    return (
      <Menu>
        <MenuButton
          color={AppColors.primary}
          border={`1px solid ${AppColors.appBorder}`}
          size="xs"
          bgColor={'white'}
          as={Button}
          rightIcon={<ChevronDownIcon />}
          borderRadius="full"
          _hover={{
            transform: 'translateY(-2px)',
            boxShadow: 'lg',
          }}
        >
          Actions
        </MenuButton>
        <MenuList>
          <MenuItem
            icon={<SettingsIcon />}
            onClick={() => {
              handleEdit(organization);
            }}
          >
            Edit
          </MenuItem>
          <MenuItem
            icon={<DeleteIcon />}
            onClick={() => {
              handleDeleteConfirm(organization);
            }}
          >
            Delete
          </MenuItem>
        </MenuList>
      </Menu>
    );
  };

  const organizationColumns: ColumnDefinitionType<Organization>[] = [
    {
      key: 'name',
      header: 'Organization Name',
      headerSortable: false,
      columnSearchable: true,
    },
    {
      key: 'product',
      header: 'Product',
      headerSortable: false,
      columnSearchable: true,
      render: (item) => {
        return <AppText>{item.product?.name ? item.product?.name : 'None Selected'}</AppText>;
      },
    },
    {
      key: 'availableCredit',
      header: 'Credit',
      headerSortable: false,
      columnSearchable: false,
      render: (item) => {
        return <AppText>{`$${item.availableCredit}`}</AppText>;
      },
    },
    {
      key: 'dateCreated',
      type: ColumnSpecialTypes.date,
      header: 'Date Added',
      headerSortable: true,
      columnSearchable: true,
    },
    {
      key: 'custom',
      header: 'Actions',
      headerSortable: false,
      columnSearchable: false,
      render: renderDefaultTableActions,
    },
  ];

  return (
    <Flex flex={1} flexDir={'column'} justify={'space-evenly'}>
      <AppDataTable data={organizationData || []} columns={organizationColumns} />
      <AppPaginator
        pagination={pagination}
        onPageChange={(event: PageChangeEvent) => handleOnPageChange(event)}
      ></AppPaginator>
    </Flex>
  );
};
export default OrganizationTable;
