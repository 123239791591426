import { createApi } from '@reduxjs/toolkit/query/react';
import { AppFetchBaseQuery } from './apiBase';
import { Organization, OrganizationEditForm, OrganizationForm } from '@backlinkit/shared';

export const organizationApi = createApi({
  reducerPath: 'organizationApi',
  baseQuery: AppFetchBaseQuery,
  endpoints: (build) => ({
    organizationCreate: build.mutation<Organization, OrganizationForm>({
      query: (body) => ({
        url: `/organization/create`,
        method: 'POST',
        body: body,
      }),
      transformResponse: (response: Organization) => {
        return response;
      },
    }),
    editOrganization: build.mutation<Organization, OrganizationEditForm>({
      query: (body) => ({
        url: `/organization/update/${body.id}`,
        method: 'PUT',
        body: body,
      }),
      transformResponse: (response: Organization) => {
        return response;
      },
    }),
    deleteOrganization: build.mutation<string, string>({
      query: (id) => ({
        url: `/organization/delete/${id}`,
        method: 'DELETE',
      }),
    }),
    fetchOrgnizationById: build.query<Organization, string>({
      query: (id) => ({
        url: `/organization/${id}`,
        method: 'GET',
      }),
      transformResponse: (response: Organization) => {
        const transformedData: Organization = response;
        return transformedData;
      },
    }),
    fetchAllOrganizations: build.query<Organization[], any>({
      query: () => ({
        url: `/organization`,
        method: 'GET',
      }),
      transformResponse: (response: Organization[]) => {
        const transformedData: Organization[] = response;
        return transformedData;
      },
    }),
  }),
});

export const {
  useFetchOrgnizationByIdQuery,
  useOrganizationCreateMutation,
  useEditOrganizationMutation,
  useDeleteOrganizationMutation,
  useFetchAllOrganizationsQuery,
} = organizationApi;
