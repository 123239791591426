import { WarningIcon } from '@chakra-ui/icons';
import { Icon, Tooltip } from '@chakra-ui/react';
import { format } from 'date-fns';
import React from 'react';
import { FaTruckLoading } from 'react-icons/fa';
import { FcGoogle } from 'react-icons/fc';

type GoogleCacheCellProps = {
  url: string;
  cacheState?: boolean;
  cacheDate?: Date;
  googleCheckDate?: Date;
};

const GoogleCacheCell: React.FC<GoogleCacheCellProps> = ({
  url,
  cacheState,
  cacheDate,
  googleCheckDate,
}) => {
  if (url?.length === 0 || cacheState === undefined) {
    return <></>;
  }

  const getCacheDate = () => {
    return cacheDate ? format(new Date(cacheDate), 'dd MMM yyyy') : '';
  };

  const handleGoogleCacheCellClick = async () => {
    window.open(
      `http://webcache.googleusercontent.com/search?q=cache:${url}`,
      'blank'
    );
  };

  if (!googleCheckDate) {
    return (
      <Tooltip label={`In 24h`}>
        <span>
          <Icon
            onClick={handleGoogleCacheCellClick}
            color={'grey'}
            as={FaTruckLoading}
          />
        </span>
      </Tooltip>
    );
  }
  if (cacheState) {
    return (
      <Tooltip label={`Cached - ${getCacheDate()}`}>
        <span>
          <Icon onClick={handleGoogleCacheCellClick} as={FcGoogle} />
        </span>
      </Tooltip>
    );
  } else {
    return (
      <Tooltip label={`Not Cached`}>
        <span>
          <Icon
            onClick={handleGoogleCacheCellClick}
            color={'orange'}
            as={WarningIcon}
          />
        </span>
      </Tooltip>
    );
  }
};

export default GoogleCacheCell;
