import React from 'react';
import { Link, Stack } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { BaseFormProps } from './base-forms';
import { AppInput, AppButton } from '@backlinkit/shared';

export type LoginFormData = {
  username: string;
  password: string;
};

export const loginFormDataDefaultValues: LoginFormData = {
  username: '',
  password: '',
};

const loginFormDataSchema = yup.object({
  username: yup.string().required('Field is required'),
  password: yup.string().required('Field is required'),
});

type LoginFormProps<T> = BaseFormProps<T>;

const LoginForm: React.FC<LoginFormProps<LoginFormData>> = ({ isLoading, onSubmit }) => {
  const {
    control,
    handleSubmit,
    formState: { isValid, errors },
  } = useForm<LoginFormData>({
    defaultValues: loginFormDataDefaultValues,
    resolver: yupResolver(loginFormDataSchema),
    mode: 'all',
  });

  const handleFormSubmit = (formData: LoginFormData) => {
    onSubmit(formData);
  };

  return (
    <Stack spacing={4}>
      <AppInput<LoginFormData>
        control={control}
        name="username"
        label="Username"
        error={errors.username}
      />
      <AppInput<LoginFormData>
        inputType="password"
        control={control}
        label="Password"
        error={errors.password}
        name="password"
      />
      <Stack spacing={10}>
        <Stack
          direction={{ base: 'column', sm: 'row' }}
          align={{ base: 'start', sm: 'center' }}
          justify={'center'}
        >
          <Link color={'blue.400'}>Forgot password?</Link>
        </Stack>
        <AppButton
          isLoading={isLoading}
          loadingText="Logging in"
          isDisabled={!isValid}
          onClick={handleSubmit(handleFormSubmit)}
        >
          Login
        </AppButton>
      </Stack>
    </Stack>
  );
};

export default LoginForm;
