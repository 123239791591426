import {
  differenceInCalendarYears,
  differenceInCalendarDays,
  differenceInCalendarMonths,
} from 'date-fns';

export const stripTrailingSlash = (str: string) => {
  return str.endsWith('/') ? str.slice(0, -1) : str;
};

export const calculateAge = (fromDate: string): string => {
  if (fromDate) {
    const date = new Date(fromDate);
    const yearAge = differenceInCalendarYears(new Date(), date);
    if (yearAge === 0) {
      const monthAge = differenceInCalendarMonths(new Date(), date);
      if (monthAge === 0) {
        const dayAge = differenceInCalendarDays(new Date(), date);
        return `${dayAge.toString()} days`;
      } else {
        return `${monthAge.toString()} months`;
      }
    } else {
      return `${yearAge.toString()} years`;
    }
  }
  return '-';
};
