import { Box } from '@chakra-ui/react';
import BaseLayout from '../../components/layouts/base-layout';

const Dashboard: React.FC = () => {
  return (
    <BaseLayout>
      <Box p={'20px'} w="100%"></Box>
    </BaseLayout>
  );
};

export default Dashboard;
