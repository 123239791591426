import React, { useEffect } from 'react';
import { Flex, FormLabel } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { BaseFormProps } from './base-forms';
import { useState } from 'react';
import { useFetchAllFaqCategoriesQuery } from '../../store/api/faqCategories';
import {
  SelectOption,
  SelectListFilter,
  AppInput,
  AppCheckbox,
  AppButton,
  FAQ,
} from '@backlinkit/shared';

export type FAQFormData = {
  id: string;
  question: string;
  answer: string;
  faqCategoryId?: string;
  isFeatured?: boolean;
} & FAQ;

export const FAQFormDefaultVales: FAQFormData = {
  id: '',
  faqCategoryId: '',
  question: '',
  answer: '',
  isFeatured: false,
};

const FAQFormDataSchema = yup.object({
  question: yup.string().required('Field is required'),
  answer: yup.string().required('Field is required'),
  faqCategoryId: yup.string().required('Field is required'),
  isFeatured: yup.boolean(),
});

type FAQCreateFormProps<T> = {
  form?: FAQ;
} & BaseFormProps<T>;

const FAQCreateForm: React.FC<FAQCreateFormProps<FAQFormData>> = ({ form, onSubmit }) => {
  const { data: faqCategories } = useFetchAllFaqCategoriesQuery({
    refetchOnMountArgsChange: true,
  });
  const [selectOptions, setSelectOptions] = useState<SelectOption[]>([]);

  useEffect(() => {
    if (faqCategories) {
      const selectors = faqCategories.map((x) => {
        return { label: x.name, value: x.id } as SelectOption;
      });
      setSelectOptions(selectors);
    }
  }, [faqCategories]);

  const {
    control: FAQControl,
    formState: { isValid, errors },
    handleSubmit,
    setValue,
  } = useForm<FAQFormData>({
    defaultValues: form || FAQFormDefaultVales,
    resolver: yupResolver(FAQFormDataSchema),
    mode: 'onChange',
  });
  const [checkBoxValue, setCheckBoxValue] = useState<boolean>(false);

  const handleOptionSelect = (x: SelectOption) => {
    setValue('faqCategoryId', x.value);
  };

  const handleSelect = (checked: boolean, checkboxLabel: string) => {
    if (checkboxLabel === 'Yes' && checked) {
      setCheckBoxValue(true);
    } else {
      setCheckBoxValue(false);
    }
  };

  return (
    <Flex dir="column">
      <form
        style={{
          width: '100%',
        }}
      >
        <FormLabel>Category:</FormLabel>
        <SelectListFilter
          name="type"
          isInModal
          isMulti={false}
          options={selectOptions}
          onSelectionChange={(value: SelectOption[]) => {
            handleOptionSelect(value[0]);
          }}
        />
        <AppInput<FAQFormData>
          control={FAQControl}
          name="question"
          label="Question"
          error={errors.question}
          mt={6}
        />
        <AppInput<FAQFormData>
          control={FAQControl}
          name="answer"
          label="Answer"
          error={errors.answer}
          mt={6}
        />
        <FormLabel mt={6}>Featured FAQ</FormLabel>
        <Flex dir="row" gap={6}>
          <AppCheckbox<FAQFormData>
            name={'isFeatured'}
            id="chechboxTrue"
            control={FAQControl}
            error={errors.isFeatured}
            label=""
            checkboxLabel="Yes"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              handleSelect(e.currentTarget.checked, 'true');
            }}
          />
        </Flex>
        <AppButton w={'full'} disabled={!isValid} onClick={handleSubmit(onSubmit)} mt={6}>
          Save
        </AppButton>
      </form>
    </Flex>
  );
};

export default FAQCreateForm;
