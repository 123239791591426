import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { domainApi } from '../api/domainApi';
import { RootState } from '../store';
import { tagApi } from '../api/tagApi';
import { DomainListItem, OrganizationTag } from '@backlinkit/shared';

interface DomainState {
  domain: DomainListItem | null;
  domains: DomainListItem[];
  domainTags: OrganizationTag[];
  tempGoogleCheckerList: string[];
}

const initialState: DomainState = {
  domain: null,
  domains: [],
  domainTags: [],
  tempGoogleCheckerList: [],
};

export const domainSlice = createSlice({
  name: 'domain',
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(
      tagApi.endpoints.fetchTagsByOrganizationId.matchFulfilled,
      (state, { payload }) => {
        return { ...state, domainTags: payload };
      }
    );
    builder.addMatcher(
      domainApi.endpoints.fetchDomainsByOptions.matchFulfilled,
      (state, { payload }) => {
        return { ...state, domains: payload.data };
      }
    );
  },
  reducers: {
    setSelectedDomain: (state, action: PayloadAction<DomainListItem>) => {
      return {
        ...state,
        ...{
          domain: action.payload,
        },
      };
    },
    resetTempGoogleCheckerList: (state) => {
      return { ...state, tempGoogleCheckerList: [] };
    },
    updateTempGoogleCheckerList: (state, action: PayloadAction<string>) => {
      let currentList: string[] = JSON.parse(JSON.stringify(state.tempGoogleCheckerList));

      currentList.push(action.payload);

      return { ...state, tempGoogleCheckerList: currentList };
    },
    clearDomainState: (state) => {
      return initialState;
    },
  },
});

export const {
  setSelectedDomain,
  clearDomainState,
  resetTempGoogleCheckerList,
  updateTempGoogleCheckerList,
} = domainSlice.actions;
export const selectDomain = (state: RootState) => state.domain.domain;
export const selectDomains = (state: RootState) => state.domain.domains;
export const selectTags = (state: RootState) => state.domain.domainTags;
export const selectTempGoogleCheckerList = (state: RootState) => state.domain.tempGoogleCheckerList;
