import { createApi } from '@reduxjs/toolkit/dist/query/react';
import {
  OrganizationBudgetTypeDto,
  OrganizationTypeEditFormData,
  OrganizationTypeFormData,
} from '@backlinkit/shared';
import { AppFetchBaseQuery } from './apiBase';

export const organizationBudgetTypeApi = createApi({
  reducerPath: 'organizationBudgetType',
  baseQuery: AppFetchBaseQuery,
  endpoints: (build) => ({
    fetchAllOrganizationBudgetTypes: build.query<OrganizationBudgetTypeDto[], any>({
      query: () => ({
        url: '/organizationBudgetType',
        method: 'GET',
      }),
      transformResponse: (response: OrganizationBudgetTypeDto[]) => {
        return response;
      },
    }),
    fetchOrganizationBudgetTypeById: build.query<OrganizationBudgetTypeDto, string>({
      query: (id) => ({
        url: `/organizationBudgetType/${id}`,
        method: 'GET',
      }),
    }),
    createOrganizationBudgetType: build.mutation<OrganizationBudgetTypeDto, OrganizationTypeFormData>({
      query: (body) => ({
        url: `/organizationBudgetType/create`,
        method: 'POST',
        body: body,
      }),
      transformResponse: (response: OrganizationBudgetTypeDto) => {
        return response;
      },
    }),
    updateOrganizationBudgetType: build.mutation<
      OrganizationBudgetTypeDto,
      OrganizationTypeEditFormData
    >({
      query: (body) => ({
        url: `organizationBudgetType/update/${body.id}`,
        method: 'PUT',
        body: body,
      }),
    }),
    deleteOrganizationBudgetType: build.mutation<OrganizationBudgetTypeDto, string>({
      query: (id) => ({
        url: `organizationBudgetType/delete/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useFetchAllOrganizationBudgetTypesQuery,
  useCreateOrganizationBudgetTypeMutation,
  useDeleteOrganizationBudgetTypeMutation,
  useFetchOrganizationBudgetTypeByIdQuery,
  useUpdateOrganizationBudgetTypeMutation,
} = organizationBudgetTypeApi;
