import React, { useEffect, useRef, useState } from 'react';
import { Flex, Icon, IconButton, Box, FlexProps, Tooltip } from '@chakra-ui/react';
import { AppColors } from '../../theme';
import {
  chakraComponents,
  InputActionMeta,
  MultiValue,
  MultiValueGenericProps,
  OptionBase,
  OptionProps,
  Select,
  SingleValue,
  NoticeProps,
} from 'chakra-react-select';
import { CloseIcon, AddIcon } from '@chakra-ui/icons';
import { AppText } from '../app-text/app-text';

export interface SelectOption extends OptionBase {
  label?: string;
  value?: any;
  icon?: React.ReactNode;
  count?: number;
  colorScheme?: string;
}

export type SelectListFilterProps = {
  options?: SelectOption[];
  placeholder?: string;
  name?: string;
  isMulti?: boolean;
  isInModal?: boolean;
  showClear?: boolean;
  defaultOption?: SelectOption | SelectOption[];
  isEditable?: boolean;
  onSelectionChange?: (selection: SelectOption[]) => void;
  onNewValueReceived?: (selection: SelectOption) => void;
} & FlexProps;

const customComponents = {
  Option: ({ children, ...props }: OptionProps<SelectOption>) => (
    <chakraComponents.Option {...props}>
      <Flex flex-direction={'row'} alignItems={'center'}>
        <>
          {props.data.icon && <Box mr={2}>{props.data.icon}</Box>}
          {children}
          {props.data.count && <Box ml={2}>{props.data.count}</Box>}
        </>
      </Flex>
    </chakraComponents.Option>
  ),
  MultiValueContainer: ({ children, ...props }: MultiValueGenericProps<SelectOption>) => (
    <chakraComponents.MultiValueContainer {...props}>
      <>
        <Tooltip label={props.data.label}>
          {props.data.icon && <Box mr={2}>{props.data.icon}</Box>}
          {children}
        </Tooltip>
      </>
    </chakraComponents.MultiValueContainer>
  ),
};

export const SelectListFilter: React.FC<SelectListFilterProps> = ({
  name,
  placeholder = 'Select an option',
  options = [],
  isMulti = false,
  isInModal = false,
  showClear = true,
  defaultOption,
  isEditable = false,
  onSelectionChange,
  onNewValueReceived,
  ...props
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [showSingleSelectClear, setShowSingleSelectClear] = useState(false);
  const [value, setValue] = useState<MultiValue<SelectOption> | SingleValue<SelectOption>>(
    defaultOption || null
  );
  const [currentInputValue, setCurrentInputValue] = useState('');

  useEffect(() => {
    setValue(defaultOption || null);
  }, [defaultOption]);

  const handleSelectionChange = (
    newSelection: MultiValue<SelectOption> | SingleValue<SelectOption>
  ) => {
    let newSelectionList: SelectOption[] = [];
    if (Array.isArray(newSelection)) {
      newSelectionList = [...newSelection];
    } else {
      if (newSelection !== null) {
        newSelectionList = [newSelection as SelectOption];
      }
    }
    setValue(newSelectionList);
    onSelectionChange && onSelectionChange(newSelectionList);

    if (!isMulti && newSelectionList.length > 0) {
      setShowSingleSelectClear(true);
    } else {
      setShowSingleSelectClear(false);
    }
  };

  const handleOnSingleSelectClearClick = () => {
    onSelectionChange && onSelectionChange([]);
    setShowSingleSelectClear(false);
    setValue(null);
  };

  const handleAddClick = () => {
    let newSelectionList: SelectOption[] = [];
    const valueArray = value as SelectOption[];
    const newValue = {
      label: currentInputValue,
      value: `${currentInputValue}-added`, //we need a better way to track the new items
    } as SelectOption;

    if (valueArray.some((x) => x.label === newValue.label)) {
      // duplicate
      return;
    }

    if (value !== null) {
      newSelectionList = [...(value as SelectOption[]), newValue];
    } else {
      newSelectionList = [newValue];
    }

    setValue(newSelectionList);
    selectRef.current.blur();
    onNewValueReceived && onNewValueReceived(newValue);
    onSelectionChange && onSelectionChange(newSelectionList);
    console.log('add click');
    console.log(newSelectionList);
  };

  const menuStyle = { top: '100%', left: '0px' };
  const selectRef = useRef<any>(null);

  return (
    <Flex position={isInModal ? 'relative' : 'initial'} {...props} ref={ref}>
      <Select
        ref={selectRef}
        onKeyDown={(event) => {
          if (event.code === 'Enter' || event.code === 'NumpadEnter') {
            event.preventDefault();
            // handleAddClick();
          }
        }}
        isMulti={isMulti}
        name={name}
        options={options}
        placeholder={placeholder}
        components={{
          Option: ({ children, ...props }: OptionProps<SelectOption>) => (
            <chakraComponents.Option {...props}>
              <Flex flex-direction={'row'} alignItems={'center'}>
                <>
                  {props.data.icon && <Box mr={2}>{props.data.icon}</Box>}
                  {children}
                  {props.data.count && <Box ml={2}>{props.data.count}</Box>}
                </>
              </Flex>
            </chakraComponents.Option>
          ),
          MultiValueContainer: ({ children, ...props }: MultiValueGenericProps<SelectOption>) => (
            <chakraComponents.MultiValueContainer {...props}>
              <>
                {props.data.icon && <Box mr={2}>{props.data.icon}</Box>}
                {children}
              </>
            </chakraComponents.MultiValueContainer>
          ),
          MultiValueLabel: ({ children, ...props }: MultiValueGenericProps<SelectOption>) => (
            <chakraComponents.MultiValueLabel {...props}>
              <>
                <Tooltip label={props.data.label}>{children}</Tooltip>
              </>
            </chakraComponents.MultiValueLabel>
          ),
          NoOptionsMessage: ({ children, ...props }: NoticeProps<SelectOption>) => (
            <chakraComponents.NoOptionsMessage {...props}>
              <>
                {isEditable ? (
                  <Flex
                    flexDirection={'row'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    paddingRight={2}
                    paddingLeft={2}
                  >
                    <AppText whiteSpace={'pre-wrap'} wordBreak={'break-word'} paddingRight={2}>
                      {`Would you like to add "${currentInputValue}"?`}
                    </AppText>
                    <IconButton
                      _hover={{
                        transform: 'translateY(-2px)',
                        boxShadow: 'lg',
                      }}
                      size={'sm'}
                      bg={AppColors.secondary}
                      color={'white'}
                      aria-label="single select clear button"
                      isRound={false}
                      onClick={handleAddClick}
                    >
                      <Icon as={AddIcon} boxSize={'1em'} color={'white'} />
                    </IconButton>
                  </Flex>
                ) : (
                  <Flex
                    flexDirection={'row'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    paddingRight={2}
                    paddingLeft={2}
                  >
                    <AppText whiteSpace={'pre-wrap'} wordBreak={'break-word'}>
                      {`No options.`}
                    </AppText>
                  </Flex>
                )}
              </>
            </chakraComponents.NoOptionsMessage>
          ),
        }}
        menuPortalTarget={ref?.current}
        value={value}
        closeMenuOnSelect={true}
        onInputChange={(newValue: string, actionMeta: InputActionMeta) => {
          setCurrentInputValue(newValue);
        }}
        onChange={(newValue: MultiValue<SelectOption> | SingleValue<SelectOption>) => {
          handleSelectionChange(newValue);
        }}
        styles={{
          menuPortal: (provided) => {
            return isInModal
              ? { ...provided, ...menuStyle, zIndex: '999' }
              : { ...provided, zIndex: '999' };
          },
        }}
        chakraStyles={{
          dropdownIndicator: (provided) => ({
            ...provided,
            p: 0,
            w: '40px',
            bg: AppColors.secondary,
            color: 'white',
          }),
          menu: (provided) => ({
            ...provided,
            zIndex: 999,
          }),
          menuList: (provided) => ({
            ...provided,
          }),
          container: (provided) => ({
            ...provided,
            width: '100%',
          }),
          valueContainer: (provided) => ({
            ...provided,
            flexDirection: 'row',
            //flexWrap: 'nowrap',
          }),
          multiValueLabel: (provided) => ({
            ...provided,
          }),
        }}
      />
      {showClear && showSingleSelectClear && (
        <IconButton
          _hover={{
            transform: 'translateY(-2px)',
            boxShadow: 'lg',
          }}
          bg={AppColors.secondary}
          color={'white'}
          aria-label="single select clear button"
          isRound={false}
          onClick={handleOnSingleSelectClearClick}
        >
          <Icon as={CloseIcon} boxSize={'1em'} color={'white'} />
        </IconButton>
      )}
    </Flex>
  );
};
