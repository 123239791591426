import { Base } from './base';

export enum NotificationType {
  Info,
  Warning,
  Error
}

export interface Notification extends Base {
  title: string;
  description: string;
  isSent: boolean;
  dateExpired: string;
  organizationId?: string;
  userId?: string;
  notificationType: NotificationType;
}

export interface NotificationCreateForm {
  title: string;
  description: string;
  isSent?: boolean;
  dateExpired?: string;
  organizationId?: string;
  userId?: string;
  notificationType: NotificationType;
}

export interface NotificationEditForm extends NotificationCreateForm {
  id: string;
}
