import { createSlice } from '@reduxjs/toolkit';
import { Bug } from '@backlinkit/shared';
import { RootState } from '../store';
import { bugApi } from '../api/bugsApi';

interface BugState {
  bugs?: Bug[];
}

const initialState: BugState = {
  bugs: [],
};

export const bugSlice = createSlice({
  name: 'bugs',
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(
      bugApi.endpoints.fetchAllBugs.matchFulfilled,
      (state, { payload }) => {
        return {
          ...state,
          products: payload,
        };
      }
    );
  },
  reducers: {},
});

export const {} = bugSlice.actions;
export const selectProducts = (state: RootState) => state.products.products;