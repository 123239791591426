import { Base } from './base';

export interface ProductDto extends Base {
  name: string;
  description: string;
  price: number;
  isInitial: boolean;
  type: ProductType;
  amount: number;
  stripeKey: string;
}

export enum ProductType {
  SUBSCRIPTION,
  CHECKERCREDITS,
}

export interface ProductForm {
  name: string;
  description: string;
  price: number;
  isInitial: boolean;
  type: ProductType;
  amount: number;
  stripeKey: string;
}

export interface ProductEditForm extends ProductForm {
  id: string;
}
