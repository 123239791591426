import { Tooltip } from '@chakra-ui/react';
import React from 'react';
import { BaseBacklinkCell } from './base-backlink-cell';
import { RelStatus, AppText } from '@backlinkit/shared';

type RelCellProps = BaseBacklinkCell & {};

const RelCell: React.FC<RelCellProps> = ({ item }) => {
  if (!item.backlinkMeta) {
    return <></>;
  }

  const getRelStatus = () => {
    if (item?.backlinkMeta?.relStatus === RelStatus.follow) {
      return 'FL';
    } else if (item?.backlinkMeta?.relStatus === RelStatus.noFollow) {
      return 'NF';
    } else {
      return '-';
    }
  };

  const getRelColor = () => {
    if (item?.backlinkMeta?.relStatus === RelStatus.follow) {
      return 'green';
    } else if (item?.backlinkMeta?.relStatus === RelStatus.noFollow) {
      return 'orange';
    } else {
      return 'gray';
    }
  };

  return (
    <Tooltip label={item?.backlinkMeta?.relValue}>
      <span>
        <AppText color={getRelColor()}>{getRelStatus()}</AppText>
      </span>
    </Tooltip>
  );
};

export default RelCell;
