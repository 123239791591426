import { ComponentStyleConfig, extendTheme } from '@chakra-ui/react';

const AppColors = {
  primary: '#222222',
  secondary: '#00a4ff',
  secondary2: '#037DFC',
  tertiary: '#606060',
  contentColor: '#b1b1b1',
  textColor: '#4A4F54',
  innerContentColor: '#ffffff',
  appBackground: '#eeeeee',
  appBorder: '#F1F4F7',
  iconColorOne: '#2DD6FA',

  successHighlightColor: '#a6e1c5',
  warningHighlightColor: '#fde995',
  errorHighlightColor: '#ff9fae',
  infoHighlightColor: '#a7e0f6',

  successColor: '#6dea26',
  warningColor: '#e8ff00',
  errorColor: '	#ff1837',
  infoColor: '#14aed5',
};

const textStyles = {
  h1: {
    fontSize: ['36px'],
  },
  h2: {
    fontSize: ['30px'],
  },
  h3: {
    fontSize: ['24px'],
  },
  body: {
    fontSize: ['14px'],
    fontWeight: '400',
  },
};

const buttonComponentStyle: ComponentStyleConfig = {
  baseStyle: {
    fontWeight: 'bold',
    color: AppColors.primary,
    rounded: 'md',
    border: 'none',
    cursor: 'pointer',
  },
  sizes: {
    xs: {
      fontSize: '12px',
      px: 2,
      py: 1,
    },
    sm: {
      fontSize: '12px',
    },
    md: {
      fontSize: '14px',
      px: 8,
      py: 4,
    },
    lg: {
      fontSize: '18px',
    },
    xl: {
      fontSize: '20px',
      px: 8,
      py: 4,
    },
    xxl: {
      fontSize: '26px',
      px: 8,
      py: 4,
    },
  },
  variants: {},
  defaultProps: {
    size: 'md',
    variant: 'solid',
  },
};

const tooltipTheme = {
  baseStyle: {
    borderRadius: '4px',
    padding: '20px',
    backgroundColor: '#fff',
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.06), 0 4px 16px 0 rgba(0, 0, 0, 0.08)',
    color: AppColors.textColor,
    fontSize: '12px',
  },
};

const checkboxTheme: ComponentStyleConfig = {
  baseStyle: {
    control: {
      '&[data-checked]': {
        background: AppColors.primary,
        borderColor: AppColors.primary,
      },
    },
  },
};

const theme = extendTheme({
  textStyles: textStyles,
  components: {
    Button: buttonComponentStyle,
    Tooltip: tooltipTheme,
    Checkbox: checkboxTheme,
  },
});

type AppTextStyles = keyof typeof textStyles;
type AppButtonVariants =
  | 'underline'
  | 'solid'
  | 'outline'
  | 'ghost'
  | 'withStartIcon'
  | 'roundedUnderline';
type AppColorsType = keyof typeof AppColors;

export { theme, AppColors };
export type { AppTextStyles, AppButtonVariants, AppColorsType };
