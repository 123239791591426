import { createApi } from '@reduxjs/toolkit/dist/query/react';
import {
  OrganizationSEOExperienceTypeDto,
  OrganizationTypeEditFormData,
  OrganizationTypeFormData,
} from '@backlinkit/shared';
import { AppFetchBaseQuery } from './apiBase';

export const organizationSEOExperienceTypeApi = createApi({
  reducerPath: 'organizationSEOExperienceType',
  baseQuery: AppFetchBaseQuery,
  endpoints: (build) => ({
    fetchAllOrganizationSEOExperienceTypes: build.query<OrganizationSEOExperienceTypeDto[], any>({
      query: () => ({
        url: '/organizationSEOExperienceType',
        method: 'GET',
      }),
    }),
    fetchOrganizationSEOExperienceTypeById: build.query<OrganizationSEOExperienceTypeDto, string>({
      query: (id) => ({
        url: `/organizationSEOExperienceType/${id}`,
        method: 'GET',
      }),
    }),
    createOrganizationSEOExperienceType: build.mutation<
      OrganizationSEOExperienceTypeDto,
      OrganizationTypeFormData
    >({
      query: (body) => ({
        url: `/organizationSEOExperienceType/create`,
        method: 'POST',
        body: body,
      }),
    }),
    updateOrganizaionSEOExperienceType: build.mutation<
      OrganizationSEOExperienceTypeDto,
      OrganizationTypeEditFormData
    >({
      query: (body) => ({
        url: `/organizationSEOExperienceType/update/${body.id}`,
        method: 'PUT',
        body: body,
      }),
    }),
    deleteOrganizationSEOExperienceType: build.mutation<OrganizationSEOExperienceTypeDto, string>({
      query: (id) => ({
        url: `/organizationSEOExperienceType/delete/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useCreateOrganizationSEOExperienceTypeMutation,
  useDeleteOrganizationSEOExperienceTypeMutation,
  useFetchAllOrganizationSEOExperienceTypesQuery,
  useFetchOrganizationSEOExperienceTypeByIdQuery,
  useUpdateOrganizaionSEOExperienceTypeMutation,
} = organizationSEOExperienceTypeApi;
