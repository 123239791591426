import { Domain, DomainListItem, ScrapeErrorTypes } from '@backlinkit/shared';
export const getTransformedDomainData = (domains: Domain[]) => {
  const transformedData: DomainListItem[] = domains.map((item) => {
    let errorCount = 0;
    item.backlinks?.forEach((backlink) => {
      const errorList = backlink?.errors?.filter(
        (error) => error.errorType === ScrapeErrorTypes.error
      );

      errorCount = errorList ? errorCount + errorList.length : errorCount;
    });
    return {
      ...item,
      backLinkCount: item.backlinks ? item.backlinks.length : 0,
      errorCount: errorCount,
    } as DomainListItem;
  });

  return transformedData;
};
