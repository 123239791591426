import { createApi } from '@reduxjs/toolkit/query/react';
import { FAQ, FAQForm, EditFAQForm } from '@backlinkit/shared';
import { AppFetchBaseQuery } from './apiBase';

export const faqApi = createApi({
  reducerPath: 'faq',
  baseQuery: AppFetchBaseQuery,
  endpoints: (build) => ({
    fetchAllFaqs: build.query<FAQ[], any>({
      query: () => ({
        url: '/faq',
        method: 'GET',
      }),
      transformResponse: (response: FAQ[]) => {
        return response;
      },
    }),
    fetchFeaturedFaqs: build.query<FAQ[], string>({
      query: () => ({
        url: '/faq/featured',
        method: 'GET',
      }),
      transformResponse: (response: FAQ[]) => {
        return response;
      },
    }),
    fetchFaqById: build.query<FAQ, string>({
      query: (id) => ({
        url: `/faq/${id}`,
        method: 'GET',
      }),
      transformResponse: (response: FAQ) => {
        return response;
      },
    }),
    createFaq: build.mutation<FAQ, FAQForm>({
      query: (body) => ({
        url: '/faq/create',
        method: 'POST',
        body: body,
      }),
    }),
    faqUpdate: build.mutation<FAQ, EditFAQForm>({
      query: (payload) => ({
        url: `/faq/update/${payload.id}`,
        method: 'PUT',
        body: payload,
      }),
    }),
    faqDelete: build.mutation<FAQ, string>({
      query: (id) => ({
        url: `/faq/delete/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useFetchAllFaqsQuery,
  useFetchFaqByIdQuery,
  useFetchFeaturedFaqsQuery,
  useCreateFaqMutation,
  useFaqUpdateMutation,
  useFaqDeleteMutation,
} = faqApi;
