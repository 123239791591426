import {
  Flex,
  useColorModeValue,
  Image,
  Tag,
  Text,
  TagLabel,
  Box,
  useToast,
  Menu,
  MenuButton,
  Button,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  useDeleteDomainMutation,
  useEditDomainMutation,
  useFetchAllDomainsQuery,
  useSaveDomainMutation,
} from '../../store/api/domainApi';
import BaseLayout from '../../components/layouts/base-layout';
import { ArrowRightIcon, ChevronDownIcon, DeleteIcon, SettingsIcon } from '@chakra-ui/icons';
import { RoutesList } from '../../router/router';
import { useNavigate } from 'react-router-dom';
import { setSelectedDomain } from '../../store/slices/domain.slice';
import {
  SortParam,
  SearchParam,
  DomainListItem,
  PageChangeEvent,
  Pagination,
  ColumnDefinitionType,
  AppText,
  ColumnSpecialTypes,
  AppColors,
  AppButton,
  TableSearchEventProps,
  searchingColumnsToSearchParam,
  AppPaginator,
  AppDataTable,
  usePanel,
  Domain,
  useLoading,
  AppConfirm,
} from '@backlinkit/shared';
import DomainCreateForm, { DomainFormData } from '../../components/forms/domain-form';

const DomainsPage: React.FC = () => {
  const title = 'Domains';
  const { setLoading } = useLoading();
  const toast = useToast();
  const panel = usePanel();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState<number>();
  const [currentPageSize, setCurrentPageSize] = useState<number>();
  const [currentSortList, setCurrentSortList] = useState<SortParam[]>([]);
  const [currentSearchParam, setCurrentSearchParam] = useState<SearchParam>();

  const { data: domains, refetch: refetchDomains } = useFetchAllDomainsQuery({
    refetchOnMountArgChange: true,
  });
  const [currentDomains, setCurrentDomains] = useState<DomainListItem[]>();

  const [saveDomainTrigger] = useSaveDomainMutation();
  const [editDomainTrigger] = useEditDomainMutation();
  const [deleteDomainTrigger] = useDeleteDomainMutation();

  const upsertDomain = async (formData: DomainFormData) => {
    let isEdit = formData.id ? true : false;
    setLoading(true);
    try {
      if (!formData?.id) {
        await saveDomainTrigger({
          name: formData.name,
          organizationId: formData.organizationId!,
          url: formData.url,
        }).unwrap();
      } else {
        await editDomainTrigger({
          id: formData.id,
          form: {
            name: formData.name,
            url: formData.url,
            organizationId: formData.organizationId!.toString(),
          },
        }).unwrap();
      }
      toast({
        title: `Domain ${isEdit ? 'updated' : 'created'}`,
        description: `We've ${
          isEdit ? 'updated' : 'created'
        } your domain, come back soon to check the status`,
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
    } catch (e) {
      toast({
        title: `Domain ${isEdit ? 'updating' : 'saving'} issue`,
        description: `We've run into an issue ${
          isEdit ? 'updating' : 'saving'
        } your domain, contact support for help`,
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
    refetchDomains();
    setLoading(false);
  };

  const handleDelete = async (domain: Domain) => {
    setLoading(true);
    try {
      const deletePromise = deleteDomainTrigger(domain.id).unwrap();
      await deletePromise;
    } catch (e) {
      toast({
        title: "We've run into an issue deleting the domain you selected",
        description:
          "We've run into a problem deleting the domain you selected. Contact support for help",
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
    toast({
      title: 'Domain deleted',
      description: "We've deleted the domain you selected",
      duration: 9000,
      status: 'success',
      isClosable: true,
    });
    refetchDomains();
    setLoading(false);
  };

  const handleDeleteConfirm = (item: DomainListItem) => {
    panel({
      title: 'Delete Domain',
      size: 'lg',
      render: (onSubmit, onCancel) => {
        return (
          <AppConfirm
            message="Are you sure you want to delete the selected domain?"
            onConfirm={async () => {
              onSubmit();
              await handleDelete(item);
            }}
            onCancel={() => {
              onCancel();
            }}
          />
        );
      },
    });
  };

  const handleAdd = () => {
    panel({
      title: 'Add Domain',
      size: 'lg',
      render: (onSubmit) => {
        return (
          <DomainCreateForm
            onSubmit={async (formData) => {
              onSubmit();
              await upsertDomain(formData);
            }}
          />
        );
      },
    });
  };

  const handleEdit = (formData: DomainListItem) => {
    const editForm = {
      id: formData.id ?? '',
      name: formData.name,
      organizationId: formData.organizationId,
      url: formData.url,
    };
    panel({
      title: 'Edit Domain',
      size: 'lg',
      render: (onSubmit) => {
        return (
          <DomainCreateForm
            form={editForm}
            onSubmit={async (formData) => {
              onSubmit();
              await upsertDomain(formData);
            }}
          />
        );
      },
    });
  };

  const handleDomainSelection = async (domain: DomainListItem, route: RoutesList) => {
    await dispatch(setSelectedDomain(domain));
    navigate(route);
  };

  const handleOnPageChange = (pageChangeEvent: PageChangeEvent) => {
    console.log(pageChangeEvent);
    setCurrentPage(pageChangeEvent.currentPage);
    setCurrentPageSize(pageChangeEvent.currentPageSize);
  };

  const handleBacklinkItClick = async (domain: DomainListItem) => {
    handleDomainSelection(domain, RoutesList.Links);
  };

  const [pagination, setPagination] = useState<Pagination>();

  const renderDefaultTableActions = (domain: DomainListItem) => {
    return (
      <Menu>
        <MenuButton
          color={AppColors.primary}
          border={`1px solid ${AppColors.appBorder}`}
          size="xs"
          bgColor={'white'}
          as={Button}
          rightIcon={<ChevronDownIcon />}
          borderRadius="full"
          _hover={{
            transform: 'translateY(-2px)',
            boxShadow: 'lg',
          }}
        >
          Actions
        </MenuButton>
        <MenuList>
          <MenuItem
            icon={<ArrowRightIcon />}
            onClick={() => {
              handleBacklinkItClick(domain);
            }}
          >
            Backlinkit
          </MenuItem>
          <MenuItem
            icon={<SettingsIcon />}
            onClick={() => {
              handleEdit(domain);
            }}
          >
            Edit
          </MenuItem>
          <MenuItem
            icon={<DeleteIcon />}
            onClick={() => {
              handleDeleteConfirm(domain);
            }}
          >
            Delete
          </MenuItem>
        </MenuList>
      </Menu>
    );
  };

  const domainColumns: ColumnDefinitionType<DomainListItem>[] = [
    {
      key: 'organizationId',
      header: 'Organization',
      headerSortable: true,
      columnSearchable: true,
      render: (item) => {
        return <AppText>{item?.organization?.name}</AppText>;
      },
    },
    {
      key: 'name',
      header: 'Name',
      headerSortable: true,
      columnSearchable: true,
    },
    {
      key: 'url',
      header: 'Domain',
      cellDataClickable: true,
      headerSortable: true,
      columnSearchable: true,
      render: (item) => {
        return (
          <Flex direction={'row'}>
            <Image src={`https://www.google.com/s2/favicons?domain=${item?.url}`} alt="" />
            <AppText ml={2}>{item.url}</AppText>
          </Flex>
        );
      },
    },
    {
      type: ColumnSpecialTypes.date,
      key: 'dateCreated',
      headerMeta: 'The date the specific link was added to be monitored.',
      header: 'Date Added',
      headerSortable: true,
    },
    {
      key: 'backLinkCount',
      header: 'Backlinks',
      headerSortable: false,
      render: (item) => {
        return (
          <Tag borderRadius="full" bgColor={AppColors.secondary} textColor={'white'}>
            <TagLabel>{item.backlinks?.length}</TagLabel>
          </Tag>
        );
      },
    },
    {
      key: 'custom',
      header: 'Actions',
      headerSortable: false,
      render: renderDefaultTableActions,
    },
  ];

  const handleSearchChange = (e: TableSearchEventProps<DomainListItem>) => {
    const searchObjects = searchingColumnsToSearchParam(e);
    setCurrentSearchParam(searchObjects);
  };

  useEffect(() => {
    setCurrentDomains(domains);
  }, [domains]);

  return (
    <BaseLayout>
      <Box w={'100%'} display={'flex'} flexDir={'column'}>
        <Flex minH={'65px'} align={'center'} m={4} justify={'space-between'}>
          <Text color={AppColors.primary} fontWeight="bold" fontSize="2xl">
            {title}
          </Text>
          <AppButton borderRadius={'full'} size={'sm'} onClick={handleAdd}>
            Add Domain
          </AppButton>
        </Flex>
        <Flex
          bg={useColorModeValue('white', 'gray.800')}
          justify={'space-evenly'}
          rounded={'2xl'}
          overflow={'hidden'}
          padding={4}
          m={4}
          flexDir={'column'}
          flex={1}
        >
          <AppDataTable
            data={currentDomains || []}
            columns={domainColumns}
            noDataMessage="No domains added, start by adding some!"
            onSearchInputChange={(event: TableSearchEventProps<DomainListItem>) => {
              handleSearchChange(event);
            }}
          />
          <AppPaginator
            pagination={pagination}
            onPageChange={(e: PageChangeEvent) => handleOnPageChange(e)}
          ></AppPaginator>
        </Flex>
      </Box>
    </BaseLayout>
  );
};
export default DomainsPage;
