import React from 'react';
import {
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import {
  AppDataTable,
  AppText,
  AppButton,
  AppColors,
  NicheType,
  usePanel,
  NicheTypeForm,
  NicheTypeEditForm,
  useLoading,
  ColumnDefinitionType,
  ColumnSpecialTypes,
} from '@backlinkit/shared';
import {
  useCreateNicheTypeMutation,
  useUpdateNicheTypeMutation,
  useDeleteNicheTypeMutation,
  useFetchAllNicheTypesQuery,
} from '../../store/api/nicheTypesApi';
import BaseLayout from '../../components/layouts/base-layout';
import { ChevronDownIcon, SettingsIcon, DeleteIcon } from '@chakra-ui/icons';
import DomainNicheTypeForm from '../../components/forms/domain-types-form';

const NicheTypes: React.FC = () => {
  const [createNicheTrigger] = useCreateNicheTypeMutation();
  const [updateNicheTrigger] = useUpdateNicheTypeMutation();
  const [deleteNicheTrigger] = useDeleteNicheTypeMutation();
  const { data: domainNicheTypes, refetch: refetchDomainNicheTypes } = useFetchAllNicheTypesQuery({
    refetchOnMountArgChange: true,
  });
  const panel = usePanel();
  const toast = useToast();
  const { setLoading } = useLoading();

  const handleCreate = () => {
    panel({
      title: 'Create  Niche Type',
      size: 'lg',
      render: (onSubmit) => {
        return (
          <DomainNicheTypeForm
            onSubmit={async (formData) => {
              onSubmit();
              await upsertDomainNicheType(formData);
            }}
          />
        );
      },
    });
  };

  const handleEdit = (item: NicheType) => {
    const formValues: NicheTypeEditForm = {
      id: item.id ?? '',
      name: item.name,
      description: item.description,
    };
    panel({
      title: 'Edit  Niche Type',
      size: 'lg',
      render: (onSubmit) => {
        return (
          <DomainNicheTypeForm
            form={formValues}
            onSubmit={async (formData) => {
              onSubmit();
              await upsertDomainNicheType(formData);
            }}
          />
        );
      },
    });
  };

  const upsertDomainNicheType = async (formData: any) => {
    setLoading(true);
    let edditted = formData.id ? true : false;
    try {
      if (!edditted) {
        const createForm = formData as NicheTypeForm;
        await createNicheTrigger({
          name: createForm.name,
          description: createForm.description,
        }).unwrap();
      } else {
        const eddittedForm = formData as NicheTypeEditForm;
        await updateNicheTrigger({
          id: eddittedForm.id ?? '',
          name: eddittedForm.name,
          description: eddittedForm.description,
        }).unwrap();
        edditted = true;
      }
    } catch (e) {
      toast({
        title: `Niche ${edditted ? 'updating' : 'saving'} issue. Error = ${e}`,
        description: `We've run into an issue ${
          edditted ? 'updating' : 'saving'
        } the niche you created. Contact support for help`,
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
      setLoading(false);
    }
    toast({
      title: `We've ${edditted ? 'updated' : 'saved'} your  niche`,
      description: `We've ${
        edditted ? 'updated' : 'saved'
      } the  niche you created. Come back soon to check the status`,
      status: 'success',
      duration: 9000,
      isClosable: true,
    });
    refetchDomainNicheTypes();
    setLoading(false);
  };

  const handleDelete = async (item: NicheType) => {
    setLoading(true);
    try {
      const deletePromise = deleteNicheTrigger(item.id).unwrap();
      await deletePromise;
    } catch (e) {
      toast({
        title: `Niche Type deleting error. Error :${e}`,
        description:
          "We've run into an issue deleting the  niche type you selected. COntact support for help",
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
      setLoading(false);
    }
    toast({
      title: "We've deleted the niche type you selected",
      description: "We've deleted the  niche type you selected",
      status: 'success',
      duration: 9000,
      isClosable: true,
    });
    setLoading(false);
    refetchDomainNicheTypes();
  };

  const renderDefaultActions = (item: NicheType) => {
    return (
      <Menu>
        <MenuButton
          color={AppColors.primary}
          border={`1px solid ${AppColors.appBorder}`}
          size="xs"
          bgColor={'white'}
          as={Button}
          rightIcon={<ChevronDownIcon />}
          borderRadius="full"
          _hover={{
            transform: 'translateY(-2px)',
            boxShadow: 'lg',
          }}
        >
          Actions
        </MenuButton>
        <MenuList>
          <MenuItem
            icon={<SettingsIcon />}
            onClick={() => {
              handleEdit(item);
            }}
          >
            Edit
          </MenuItem>
          <MenuItem
            icon={<DeleteIcon />}
            onClick={() => {
              handleDelete(item);
            }}
          >
            Delete
          </MenuItem>
        </MenuList>
      </Menu>
    );
  };

  const nicheColumns: ColumnDefinitionType<NicheType>[] = [
    {
      key: 'dateCreated',
      type: ColumnSpecialTypes.date,
      header: 'Date Added',
      columnSearchable: false,
    },
    {
      key: 'name',
      header: 'Name',
      columnSearchable: false,
    },
    {
      key: 'description',
      header: 'Description',
      columnSearchable: false,
    },
    {
      key: 'custom',
      header: 'Actions',
      render: renderDefaultActions,
    },
  ];

  return (
    <BaseLayout>
      <Flex flex={1} direction={'column'} w={'full'}>
        <Flex m={4} justify={'space-between'} align={'center'} minH={'65px'}>
          <AppText fontSize={'2xl'} fontWeight={'bold'} color={AppColors.primary}>
            {' Niche Types'}
          </AppText>
          <AppButton
            size={'sm'}
            bgColor={'white'}
            border={`1px solid ${AppColors.appBorder}`}
            borderRadius={'full'}
            onClick={handleCreate}
          >
            Add Niche
          </AppButton>
        </Flex>
        <Flex
          bg={useColorModeValue('white', 'gray.800')}
          justify={'space-evenly'}
          rounded={'2xl'}
          overflow={'hidden'}
          padding={4}
          m={4}
          flexDir={'column'}
          flex={1}
        >
          <AppDataTable
            data={domainNicheTypes || []}
            noDataMessage="No  Niche Types yet, start by adding some!"
            columns={nicheColumns || []}
          />
        </Flex>
      </Flex>
    </BaseLayout>
  );
};

export default NicheTypes;
