import { Flex, useColorModeValue, Text } from '@chakra-ui/react';
import LastModifiedCell from './components/cells/last-modified-cell';
import LinkTypeCell from './components/cells/link-type-cell';
import LinkStatusCell from './components/cells/link-status-cell';
import { ErrorCountCell } from './components/cells/error-count-cell';
import LastLiveCell from './components/cells/last-live-cell';
import UrlCell from './components/cells/url-cell';
import LandinPageCell from './components/cells/landing-page-cell';
import AnchorCell from './components/cells/anchor-cell';
import GoogleIndexCell from './components/cells/google-index-cell';
import GoogleCacheCell from './components/cells/google-cache-cell';
import RelCell from './components/cells/rel-cell';
import IpCell from './components/cells/ip-cell';
import TldCell from './components/cells/tld-cell';
import EflCell from './components/cells/efl-cell';
import { ReactNode, useState } from 'react';
import LastScrapeCell from './components/cells/last-scrape-cell';
import LinkNotesCell from './components/cells/link-notes-cell';
import { useSelector } from 'react-redux';
import { selectDomain } from '../../store/slices/domain.slice';
import DifferenceCountCell from './components/cells/differences-count-cell';
import {
  BackLinkListItem,
  ColumnDefinitionType,
  BacklinkFormData,
  SearchParam,
  BacklinkFilter,
  useDialog,
  TableSearchEventProps,
  searchingColumnsToSearchParam,
  ColumnSpecialTypes,
  AppText,
  AppDataTable,
} from '@backlinkit/shared';
import { FaCheck } from 'react-icons/fa';
import { MdClose } from 'react-icons/md';

export type BacklinkMenuItems = {
  icon: any;
  onClick: (item: BackLinkListItem) => void;
  label: string;
};

type BacklinksTableProps = {
  title: string;
  parentUrl: string;
  data: BackLinkListItem[];
  tempBacklinkCount?: number;
  tempGoogleDataCount?: number;
  additionalBacklinkColumns: ColumnDefinitionType<BackLinkListItem>[];
  additionalMenuItems: BacklinkMenuItems[];
  onAddClick: (backlinkFormsData: BacklinkFormData[], isNote: boolean) => void;
  onSearchChange?: (searchParams: SearchParam) => void;
  onSortByColumnClick?: (columns: ColumnDefinitionType<BackLinkListItem>[]) => void;
  onFiltersChange: (params: BacklinkFilter) => void;
  onRowSelectedChange?: (selection: BackLinkListItem[]) => void;
  onSelectedBacklinksRescrape: (selection: BackLinkListItem[]) => void;
  onSelectedBacklinksDelete: (selection: BackLinkListItem[]) => void;
  onBackButtonClick: () => void;
  refetch: () => void;
  children: ReactNode;
};

const BacklinksTable: React.FC<BacklinksTableProps> = ({
  title,
  parentUrl,
  data = [],
  additionalBacklinkColumns,
  onRowSelectedChange,
  onSearchChange,
  onSortByColumnClick,
  onFiltersChange,
  refetch,
  children,
}) => {
  const domain = useSelector(selectDomain);
  const [saved, setSaved] = useState<boolean>(false);

  const [currentSearchParam, setCurrentSearchParam] = useState<SearchParam>();

  const [selectedBacklinks, setSelectedBacklinks] = useState<BackLinkListItem[]>([]);

  const handleSearchChange = (event: TableSearchEventProps<BackLinkListItem>) => {
    const searchObjects = searchingColumnsToSearchParam(event);
    if (searchObjects) {
      setCurrentSearchParam(searchObjects);
      onSearchChange && onSearchChange(searchObjects);
    }
  };

  const handleFiltersChange = (backlinkFilter: BacklinkFilter) => {
    onFiltersChange(backlinkFilter);
  };

  const handleTableRowsSelectedChange = (selection: BackLinkListItem[]) => {
    setSelectedBacklinks(selection);
    onRowSelectedChange && onRowSelectedChange(selection);
  };

  const handleBacklinkAlertsCleared = () => {
    refetch && refetch();
  };

  const backLinkColumns: ColumnDefinitionType<BackLinkListItem>[] = [
    {
      key: 'custom',
      header: 'LM',
      headerMeta: 'Last modified indicates the last date the link was amended and by which user.',
      headerSortable: false,
      render: (item) => <LastModifiedCell item={item} />,
    },
    {
      type: ColumnSpecialTypes.date,
      key: 'dateCreated',
      headerMeta: 'The date the specific link was added to be monitored.',
      header: 'Date Added',
      headerSortable: true,
    },
    {
      key: 'custom',
      header: 'PC',
      headerMeta: 'Processed Count - the amount of times the system has processed the backlink',
      headerSortable: false,
      render: (item) => (
        <LastScrapeCell updatedDate={item.lastModified} scrapeCount={item.scrapeCount} />
      ),
    },
    {
      key: 'custom',
      header: 'Type',
      headerMeta: 'We track 3 link types; text link, image and redirect.',
      headerSortable: true,
      isVisible: true,
      render: (item) => {
        return <LinkTypeCell item={item} />;
      },
    },
    {
      key: 'custom',
      headerSortable: true,
      header: 'Status',
      headerMeta: 'Indicates the HTTP status code of a link',
      render: (item) => (
        <LinkStatusCell item={item} onErrorsCleared={handleBacklinkAlertsCleared} />
      ),
    },
    {
      key: 'errorCount',
      header: 'Link Status',
      headerMeta: 'Shows differences related to the specific link.',
      headerSortable: false,
      render: (item) => (
        <DifferenceCountCell
          backlinkUrl={item.url}
          onClick={() => {
            console.log('SAVED', saved);
            setSaved(!saved);
          }}
          icon={saved ? FaCheck : MdClose}
          color={saved ? 'green' : 'red'}
        />
      ),
    },
    {
      key: 'url',
      header: 'Url',
      headerMeta: 'Indicates the referring page your link is located on.',
      headerSortable: true,
      columnSearchable: true,
      cellDataClickable: true,
      render: (item) => <UrlCell url={item.url} />,
    },
    {
      key: 'landingPage',
      headerSortable: true,
      header: 'Landing',
      headerMeta: 'The destination URL your link points to.',
      columnSearchable: true,
      render: (item) => <LandinPageCell item={item} domainUrl={domain?.url ?? ''} />,
    },
    {
      key: 'anchor',
      headerSortable: true,
      header: 'Anchor',
      headerMeta:
        'The anchor text for text links, empty anchor for image links and the URL for redirects.',
      columnSearchable: true,
      render: (item) => <AnchorCell item={item} />,
    },
    {
      key: 'custom',
      headerSortable: false,
      header: 'Notes',
      headerMeta: 'Your specific notes related to a link',
      cellDataClickable: true,
      render: (item) => (
        <span>
          <LinkNotesCell item={item} />
        </span>
      ),
    },
    {
      key: 'custom',
      headerSortable: false,
      header: 'GI',
      headerMeta:
        'Google index indicator; Green - Page indexed, Orange - Domain indexed - Transparent - Not index',
      cellDataClickable: true,
      render: (item) => (
        <GoogleIndexCell
          url={item.url}
          hasDomainIndex={item.backlinkGoogle?.hasDomainIndex}
          hasPageIndex={item.backlinkGoogle?.hasPageIndex}
          googleCheckDate={item.backlinkGoogle?.lastGoogleCheckDate}
        />
      ),
    },
    {
      key: 'custom',
      headerSortable: false,
      cellDataClickable: true,
      header: 'GC',
      headerMeta: ' Google cache; Google icon - URL is cached, Error icon - Url not cached.',
      render: (item) => (
        <GoogleCacheCell
          url={item.url}
          cacheState={item?.backlinkGoogle?.cacheState}
          cacheDate={item?.backlinkGoogle?.cacheCreatedDate}
          googleCheckDate={item.backlinkGoogle?.lastGoogleCheckDate}
        />
      ),
    },
    {
      key: 'custom',
      headerSortable: false,
      header: 'Rel',
      headerMeta:
        'The “rel” attribute for the specific link - follow, nofollow (ugc and sponsored).',
      render: (item) => <RelCell item={item} />,
    },
    {
      key: 'custom',
      headerSortable: false,
      header: 'IP',
      headerMeta: 'The IP address retrieved from the server the website was served from.',
      render: (item) => <IpCell item={item} />,
    },
    {
      key: 'custom',
      header: 'TLD',
      headerMeta: 'Top level domain - .com, .org, .co, .co.uk (etc.)',
      headerSortable: false,
      render: (item) => <TldCell item={item} />,
    },
    {
      key: 'custom',
      headerSortable: false,
      header: 'EFL',
      headerMeta:
        'External follow links - Outbound links located on the same URL your link is located.',
      render: (item) => <EflCell parentUrl={parentUrl} item={item} />,
    },
    ...additionalBacklinkColumns,
  ];

  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');

  return (
    <>
      <Flex justifyContent={'space-between'} align={'center'} minH={'65px'}>
        <Text color={textColorPrimary} fontWeight="bold" fontSize="2xl">
          {title}
        </Text>
      </Flex>

      <Flex py={2} flex={1}>
        <Flex
          w={'full'}
          bg={useColorModeValue('white', 'gray.800')}
          rounded={'2xl'}
          overflow={'hidden'}
          padding={'4'}
          flexDir={'column'}
          flex={1}
        >
          <AppDataTable
            data={data}
            noDataMessage={'No links added, start by adding some!'}
            columns={backLinkColumns}
            selectableRows={true}
            onRowSelected={handleTableRowsSelectedChange}
            onSearchInputChange={handleSearchChange}
            onSortByColumnClick={onSortByColumnClick}
          />
          {children}
        </Flex>
      </Flex>
    </>
  );
};

export default BacklinksTable;
