import { Icon, Tooltip } from '@chakra-ui/react';
import React, { ReactNode } from 'react';
import { BsCardImage } from 'react-icons/bs';
import { FiExternalLink } from 'react-icons/fi';
import { MdLink } from 'react-icons/md';
import { BaseBacklinkCell } from './base-backlink-cell';
import { LinkType } from '@backlinkit/shared';

type LinkTypeCellProps = BaseBacklinkCell & {};

const LinkTypeCell: React.FC<LinkTypeCellProps> = ({ item }) => {
  const getComponent = (): ReactNode => {
    switch (item?.backlinkMeta?.linkType) {
      case LinkType.Link:
        return (
          <Tooltip label="Link">
            <span>
              <Icon as={MdLink} />
            </span>
          </Tooltip>
        );

      case LinkType.Image:
        return (
          <Tooltip label="Image">
            <span>
              <Icon as={BsCardImage} />
            </span>
          </Tooltip>
        );
      case LinkType.Redirect:
        return (
          <Tooltip label="Redirect">
            <span>
              <Icon as={FiExternalLink} />
            </span>
          </Tooltip>
        );
      default:
        <></>;
    }
  };

  return <>{getComponent()}</>;
};

export default LinkTypeCell;
