import { Button, Flex, Menu, MenuButton, MenuItem, MenuList, useToast } from '@chakra-ui/react';
import BaseLayout from '../../components/layouts/base-layout';
import {
  AppColors,
  AppConfirm,
  AppDataTable,
  ColumnDefinitionType,
  ColumnSpecialTypes,
  OrganizationTypeEditFormData,
  OrganizationTypeFormData,
  useLoading,
  usePanel,
  AppButton,
  useDataTable,
  AppPaginator,
  OrganizationSEOExperienceTypeDto
} from '@backlinkit/shared';
import {
  useCreateOrganizationSEOExperienceTypeMutation,
  useDeleteOrganizationSEOExperienceTypeMutation,
  useFetchAllOrganizationSEOExperienceTypesQuery,
  useUpdateOrganizaionSEOExperienceTypeMutation,
} from '../../store/api/organizationSEOExperienceTypeApi';
import OrganizationTypeForm from '../../components/forms/orgnization-type-form';
import { ChevronDownIcon, SettingsIcon, DeleteIcon } from '@chakra-ui/icons';
import { Text, useColorModeValue } from '@chakra-ui/react';

const OrganizationSEOExperienceTypes: React.FC = () => {
  const title = 'Organization SEO Experience Types';
  const { setLoading } = useLoading();
  const toast = useToast();
  const panel = usePanel();
  const { data: organizationSEOExperienceTypes, refetch: refetchOrganizationSEOExperienceTypes } =
    useFetchAllOrganizationSEOExperienceTypesQuery({
      refetchOnMountArgChange: true,
    });
  const [createOrganizationSEOExperienceType] = useCreateOrganizationSEOExperienceTypeMutation();
  const [updateOrganizationSEOExperienceType] = useUpdateOrganizaionSEOExperienceTypeMutation();
  const [deleteOrganizationSEOExperienceType] = useDeleteOrganizationSEOExperienceTypeMutation();

  const {
    handleSearchChange,
    handleSortByColumnClick,
    handleOnPageChange,
    pagination,
    setPagination,
    nextPaginationQueryParams,
  } = useDataTable<OrganizationSEOExperienceTypeDto>();

  const upsertOrganizationSEOExperienceType = async (formData: any) => {
    debugger
    setLoading(true);
    let eddittedOrganizationSEOExperienceType = formData.id ? true : false;
    try {
      if (!eddittedOrganizationSEOExperienceType) {
        const createFormData = formData as OrganizationTypeFormData;
        await createOrganizationSEOExperienceType({
          name: createFormData.name,
          description: createFormData.description,
        }).unwrap();
      } else {
        const editFormData = formData as OrganizationTypeEditFormData;
        await updateOrganizationSEOExperienceType({
          id: editFormData.id ?? '',
          name: editFormData.name,
          description: editFormData.description,
        }).unwrap();
      }
      toast({
        title: `Organization SEO Experience Type ${!eddittedOrganizationSEOExperienceType ? 'added' : 'updated'}`,
        description: `We've ${
          !eddittedOrganizationSEOExperienceType ? 'added' : 'updated'
        } your Organization SEO Experience Type for you, come back soon to monitor the status`,
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
      refetchOrganizationSEOExperienceTypes();
    } catch (err) {
      toast({
        title: `Organization SEO Experience Type ${
          !eddittedOrganizationSEOExperienceType ? 'adding' : 'updating'
        }. error= ${err}`,
        description: `We've run into a problem ${
          !eddittedOrganizationSEOExperienceType ? 'adding' : 'updating'
        } your Organization SEO Experience Type, Contact support for help`,
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
    refetchOrganizationSEOExperienceTypes();
    setLoading(false);
  };

  const handleCreateOrganizationSEOExperienceType = () => {
    panel({
      title: 'Create Organization SEO Experience Type',
      size: 'lg',
      render: (onSubmit) => {
        return (
          <OrganizationTypeForm
            onSubmit={async (formData) => {
              await upsertOrganizationSEOExperienceType(formData);
              onSubmit();
            }}
          />
        );
      },
    });
  };

  const handleEditOrganizationSEOExperienceType = (item: OrganizationSEOExperienceTypeDto) => {
    const editForm: OrganizationTypeEditFormData = {
      id: item.id,
      name: item.name,
      description: item.description,
    };
    panel({
      title: 'Edit Organization SEO Experience Type',
      size: 'lg',
      render: (onSubmit) => {
        return (
          <OrganizationTypeForm
            form={editForm}
            onSubmit={async (formData) => {
              await upsertOrganizationSEOExperienceType(formData);
              onSubmit();
            }}
          />
        );
      },
    });
  };

  const handleDeleteOrganizationSEOExperienceType = async (item: OrganizationSEOExperienceTypeDto) => {
    setLoading(true);
    try {
      const handleDelete = deleteOrganizationSEOExperienceType(item.id).unwrap();
      await handleDelete;
    } catch (err) {
      toast({
        title: 'Organization SEO Experience Type delete error',
        description:
          "We've run into a problem deleting the selected Organization SEO Experience Type, contact support for help",
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }

    toast({
      title: 'Selected Organization SEO Experienece Type deleted',
      description: "We've deleted the Organization SEO Experience Type you selected",
      status: 'success',
      duration: 9000,
      isClosable: true,
    });
    refetchOrganizationSEOExperienceTypes();
    setLoading(false);
  };

  const handleDeleteConfirm = (item: OrganizationSEOExperienceTypeDto) => {
    panel({
      title: 'Delete Organization SEO Experience Type',
      size: 'lg',
      render: (onSubmit, onCancel) => {
        return (
          <AppConfirm
            message="Are you sure you want to delete the selected Organization SEO Experience Type?"
            onConfirm={async () => {
              onSubmit();
              await handleDeleteOrganizationSEOExperienceType(item);
            }}
            onCancel={() => {
              onCancel();
            }}
          />
        );
      },
    });
  };

  const renderDefaultTableActions = (item: OrganizationSEOExperienceTypeDto) => {
    return (
      <Menu>
        <MenuButton
          color={AppColors.primary}
          border={`1px solid ${AppColors.appBorder}`}
          size="xs"
          bgColor={'white'}
          as={Button}
          rightIcon={<ChevronDownIcon />}
          borderRadius="full"
          _hover={{
            transform: 'translateY(-2px)',
            boxShadow: 'lg',
          }}
        >
          Actions
        </MenuButton>
        <MenuList>
          <MenuItem icon={<SettingsIcon />} onClick={() => handleEditOrganizationSEOExperienceType(item)}>
            Edit
          </MenuItem>
          <MenuItem icon={<DeleteIcon />} onClick={() => handleDeleteConfirm(item)}>
            Delete
          </MenuItem>
        </MenuList>
      </Menu>
    );
  };

  const organizationSEOExperienceTypeColumns: ColumnDefinitionType<OrganizationSEOExperienceTypeDto>[] = [
    {
      type: ColumnSpecialTypes.date,
      key: 'dateCreated',
      header: 'Date Added',
      headerSortable: true,
      columnSearchable: true,
    },
    {
      header: 'Name',
      key: 'name',
      headerSortable: true,
      columnSearchable: true,
    },
    {
      header: 'Description',
      key: 'description',
      headerSortable: true,
      columnSearchable: true,
    },
    {
      key: 'custom',
      header: 'Actions',
      headerSortable: false,
      render: renderDefaultTableActions,
    },
  ];

  return (
    <BaseLayout>
      <Flex flex={1} direction={'column'}>
        <Flex m={4} justifyContent={'space-between'} align={'center'} minH={'65px'}>
          <Text fontSize={'2xl'} fontWeight={'bold'} color={AppColors.primary}>
            {title}
          </Text>
          <AppButton
            bg={'white'}
            size={'sm'}
            border={`1px solid ${AppColors.appBorder}`}
            borderRadius={'full'}
            onClick={handleCreateOrganizationSEOExperienceType}
          >
            Add Organization SEO Experience Type
          </AppButton>
        </Flex>
        <Flex
          bg={useColorModeValue('white', 'gray.800')}
          rounded={'2xl'}
          overflow={'hidden'}
          padding={'4'}
          m={4}
          flexDir={'column'}
          flex={1}
        >
          <AppDataTable
            data={organizationSEOExperienceTypes || []}
            noDataMessage="No Organization SEO Experience Type's yet, start by adding some!"
            columns={organizationSEOExperienceTypeColumns}
          />
          <AppPaginator pagination={pagination} onPageChange={handleOnPageChange}></AppPaginator>
        </Flex>
      </Flex>
    </BaseLayout>
  );
};

export default OrganizationSEOExperienceTypes;