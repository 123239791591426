import React from 'react';
import {
  Flex,
  Button,
  Text,
  useColorModeValue,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useToast,
} from '@chakra-ui/react';
import BaseLayout from '../../components/layouts/base-layout';
import { ChevronDownIcon, DeleteIcon, SettingsIcon } from '@chakra-ui/icons';
import FAQCategoryForm from '../../components/forms/faq-category-form';
import {
  useFetchAllFaqCategoriesQuery,
  useCreateFaqCategoryMutation,
  useDeleteFaqCategoryMutation,
  useUpdateFaqCategoryMutation,
} from '../../store/api/faqCategories';
import {
  AppButton,
  AppColors,
  AppConfirm,
  AppDataTable,
  ColumnDefinitionType,
  ColumnSpecialTypes,
  FAQTypeEditForm,
  FAQTypeForm,
  FAQTypes,
  useLoading,
  usePanel,
} from '@backlinkit/shared';

const FAQCategoryPage: React.FC = () => {
  const title = 'FAQ Categories';
  const { setLoading } = useLoading();
  const panel = usePanel();
  const toast = useToast();
  const { data: faqCategories, refetch: refetchFaqCategories } = useFetchAllFaqCategoriesQuery('');
  const [saveFaqCategoryTrigger] = useCreateFaqCategoryMutation();
  const [editFaqCategoryTrigger] = useUpdateFaqCategoryMutation();
  const [deleteFaqCategoryTrigger, { isLoading: isDeletingFaqCategory }] =
    useDeleteFaqCategoryMutation();

  const handleCreateCategory = () => {
    return panel({
      title: 'Add Category',
      size: 'lg',
      render: (onSubmit) => {
        return (
          <FAQCategoryForm
            onSubmit={async (formData) => {
              onSubmit();
              await upsertFaqCategory(formData);
            }}
          />
        );
      },
    });
  };

  const handleEditCategory = (category: FAQTypes) => {
    const categoryForm: FAQTypeEditForm = {
      id: category.id,
      name: category.name,
      description: category.description,
    };
    panel({
      title: 'Edit Category',
      size: 'lg',
      render: (onSubmit) => {
        return (
          <FAQCategoryForm
            form={categoryForm}
            onSubmit={async (formData) => {
              onSubmit();
              await upsertFaqCategory(formData);
            }}
          ></FAQCategoryForm>
        );
      },
    });
  };

  const handleCategoryDelete = async (item: FAQTypes) => {
    setLoading(true);
    try {
      const deletePromise = deleteFaqCategoryTrigger(item.id).unwrap();
      await deletePromise;
    } catch (err) {
      toast({
        title: 'Category Delete Error',
        status: 'error',
        description:
          "We've run into a problem deleting the selected category. Contact support for help",
        duration: 9000,
        isClosable: true,
      });
    }
    toast({
      title: 'Category deleted',
      description: "We've deleted the category you selected",
      status: 'success',
      duration: 9000,
      isClosable: true,
    });
    refetchFaqCategories();
    setLoading(false);
  };

  const upsertFaqCategory = async (formData: any) => {
    setLoading(true);
    let edittedCategory = formData.id ? true : false;
    try {
      if (!edittedCategory) {
        const createFormData = formData as FAQTypeForm;
        await saveFaqCategoryTrigger({
          name: createFormData.name,
          description: createFormData.description,
        }).unwrap();
      } else {
        const editFormData = formData as FAQTypeEditForm;
        await editFaqCategoryTrigger({
          id: editFormData.id,
          name: editFormData.name,
          description: editFormData.description,
        }).unwrap();
        edittedCategory = true;
      }

      toast({
        title: `Category ${!edittedCategory ? 'added' : 'updated'}`,
        description: `We've ${
          !edittedCategory ? 'added' : 'updated'
        } your categories for you, come back soon to check the status`,
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
      refetchFaqCategories();
    } catch (err) {
      toast({
        title: `Category ${!edittedCategory ? 'adding' : 'updating'} error = ${err}`,
        description: `We've run into a problem ${
          !edittedCategory ? 'adding' : 'updating'
        } your faq category, Contact support for help`,
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
      setLoading(false);
    }
    refetchFaqCategories();
    setLoading(false);
  };

  const handleDeleteConfirm = (item: FAQTypes) => {
    panel({
      title: 'Delete FAQ category',
      size: 'lg',
      render: (onSubmit, onCancel) => {
        return (
          <AppConfirm
            message="Are you sure you want to delete the selected FAQ category?"
            onConfirm={async () => {
              onSubmit();
              await handleCategoryDelete(item);
            }}
            onCancel={() => {
              onCancel();
            }}
          />
        );
      },
    });
  };

  const renderDefaultActions = (item: FAQTypes) => {
    return (
      <Menu>
        <MenuButton
          color={AppColors.primary}
          border={`1px solid ${AppColors.appBorder}`}
          size="xs"
          bgColor={'white'}
          as={Button}
          rightIcon={<ChevronDownIcon />}
          borderRadius="full"
          _hover={{
            transform: 'translateY(-2px)',
            boxShadow: 'lg',
          }}
        >
          Actions
        </MenuButton>
        <MenuList>
          <MenuItem
            icon={<SettingsIcon />}
            onClick={() => {
              handleEditCategory(item);
            }}
          >
            Edit
          </MenuItem>
          <MenuItem
            icon={<DeleteIcon />}
            onClick={() => {
              handleDeleteConfirm(item);
            }}
          >
            Delete
          </MenuItem>
        </MenuList>
      </Menu>
    );
  };
  const categoryColumns: ColumnDefinitionType<FAQTypes>[] = [
    {
      key: 'dateCreated',
      header: 'Date Added',
      type: ColumnSpecialTypes.date,
      headerSortable: false,
      columnSearchable: false,
    },
    {
      key: 'name',
      header: 'Name',
      headerSortable: false,
      columnSearchable: false,
    },
    {
      key: 'description',
      header: 'Description',
      headerSortable: false,
      columnSearchable: false,
    },
    {
      key: 'custom',
      header: 'Actions',
      render: renderDefaultActions,
    },
  ];

  return (
    <BaseLayout>
      <Flex flex={1} direction={'column'} w={'full'}>
        <Flex m={4} justify={'space-between'} align={'center'} minH={'65px'}>
          <Text fontSize={'2xl'} fontWeight={'bold'} color={AppColors.primary}>
            {title}
          </Text>
          <AppButton
            size={'sm'}
            bgColor={'white'}
            border={`1px solid ${AppColors.appBorder}`}
            borderRadius={'full'}
            onClick={handleCreateCategory}
          >
            Add Category
          </AppButton>
        </Flex>
        <Flex
          bg={useColorModeValue('white', 'gray.800')}
          justify={'space-evenly'}
          rounded={'2xl'}
          overflow={'hidden'}
          padding={4}
          m={4}
          flexDir={'column'}
          flex={1}
        >
          <AppDataTable
            data={faqCategories || []}
            noDataMessage="No FAQ categories yet, start by adding some!"
            columns={categoryColumns}
          />
        </Flex>
      </Flex>
    </BaseLayout>
  );
};
export default FAQCategoryPage;
