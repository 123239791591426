import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { AppInput, AppButton, SelectOption, SelectListFilter, BackLink } from '@backlinkit/shared';
import { BaseFormProps } from './base-forms';
import { useSelector } from 'react-redux';
import { selectUser } from '../../store/slices/authentication.slice';
import { useFetchTagsByOrganizationIdQuery } from '../../store/api/tagApi';
import { FormLabel, Stack } from '@chakra-ui/react';
import { selectDomain } from '../../store/slices/domain.slice';

export type BacklinkBatchFormData = {
  domainId: string;
  name: string;
  tagIds?: string[];
  backlinks?: BackLink[];
  batchTypeId?: string;
  batchDescriptionTypeId?: string;
  nicheTypeId?: string;
  notes?: string;
  price?: number;
};

const backlinkBatchFormDefaultValues: BacklinkBatchFormData = {
  name: '',
  domainId: '',
  tagIds: undefined,
  backlinks: undefined,
  batchTypeId: undefined,
  nicheTypeId: undefined,
  batchDescriptionTypeId: undefined,
  notes: undefined,
  price: undefined,
};

const backlinkBatchFormDataSchema = yup.object({
  name: yup.string().required('Field is required'),
  tagIds: yup.string(),
  notes: yup.string(),
  price: yup.number(),
});

type BacklinkBatchFormProps<T> = {
  form?: BacklinkBatchFormData;
  backlinks?: BackLink[];
} & BaseFormProps<T>;

const BacklinkBatchForm: React.FC<BacklinkBatchFormProps<BacklinkBatchFormData>> = ({
  form,
  backlinks,
  onSubmit,
}) => {
  const {
    control: batchControl,
    formState: { isValid, errors },
    setValue,
    getValues,
    handleSubmit,
  } = useForm<BacklinkBatchFormData>({
    defaultValues: backlinkBatchFormDefaultValues,
    resolver: yupResolver(backlinkBatchFormDataSchema),
    mode: 'onChange',
  });
  const [currentTags, setCurrentTags] = useState<SelectOption[]>([]);
  const user = useSelector(selectUser);
  const { data: tags, refetch: refetchTags } = useFetchTagsByOrganizationIdQuery(
    user!.organizationId
  );
  const domain = useSelector(selectDomain);

  const [selectedTags, setSelectedTags] = useState<string[]>();

  useEffect(() => {
    if (tags) {
      const selectors: SelectOption[] = tags.map((x) => {
        return {
          label: x.name,
          value: x.id,
        };
      });
      setCurrentTags(selectors);
    }
  }, [tags]);

  const handleAddTags = (items: SelectOption[]) => {
    const tags: string[] = items.map((x) => {
      return x.value;
    });
    setSelectedTags(tags);
  };

  const handleFormSubmit = () => {
    const formData = getValues();
    const batchDetails: BacklinkBatchFormData = {
      domainId: domain?.id ?? '',
      name: formData.name,
      tagIds: selectedTags,
      batchTypeId: formData.batchTypeId,
      batchDescriptionTypeId: formData.batchDescriptionTypeId,
      notes: formData.notes,
      nicheTypeId: formData.nicheTypeId,
    };
    onSubmit(batchDetails);
  };

  return (
    <form>
      <Stack spacing={4}>
        <AppInput<BacklinkBatchFormData>
          name="name"
          control={batchControl}
          error={errors.name}
          label="Batch Name"
        />
        <FormLabel p={0} m={0}>
          Tags
        </FormLabel>
        <SelectListFilter
          name="batchTags"
          options={currentTags}
          isInModal
          isMulti
          onSelectionChange={(items: SelectOption[]) => {
            handleAddTags(items);
          }}
        />
        <FormLabel p={0} m={0}>
          Batch Type
        </FormLabel>
        <SelectListFilter
          name="batchType"
          options={currentTags}
          isInModal
          onSelectionChange={(items: SelectOption[]) => {
            setValue('batchTypeId', items[0].value);
          }}
        />
        <FormLabel p={0} m={0}>
          Batch Description
        </FormLabel>
        <SelectListFilter
          name="batchDescriptionType"
          options={currentTags}
          isInModal
          onSelectionChange={(items: SelectOption[]) => {
            setValue('batchDescriptionTypeId', items[0].value);
          }}
        />
        <FormLabel m={0} p={0}>
          Niche
        </FormLabel>
        <SelectListFilter
          name="batchNicheType"
          options={currentTags}
          isInModal
          onSelectionChange={(items: SelectOption[]) => {
            setValue('nicheTypeId', items[0].value);
          }}
        />
        <AppInput<BacklinkBatchFormData>
          name="notes"
          control={batchControl}
          error={errors.notes}
          label={'Notes'}
        />
        <AppInput<BacklinkBatchFormData>
          name="price"
          error={errors.price}
          control={batchControl}
          label={'Batch Price'}
        />
        <AppButton isDisabled={!isValid} onClick={handleFormSubmit}>
          Save
        </AppButton>
      </Stack>
    </form>
  );
};

export default BacklinkBatchForm;
