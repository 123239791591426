import React from 'react';
import { Card, CardProps, Text, useColorModeValue } from '@chakra-ui/react';

export type AppCardProps = {
  title?: string;
} & CardProps;

export const AppCard: React.FC<AppCardProps> = ({ title, children, ...props }) => {
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
  return (
    <Card {...props} variant="filled" bg={'white'} p="4" rounded={'lg'} mb={'20px'}>
      {title && (
        <Text color={textColorPrimary} fontWeight="bold" fontSize="2xl" mt="10px" mb="4px">
          {title}
        </Text>
      )}

      {children}
    </Card>
  );
};
