import { Tooltip, Image } from '@chakra-ui/react';
import React from 'react';
import ReactCountryFlag from 'react-country-flag';
import { BaseBacklinkCell } from './base-backlink-cell';
import GlobalIcon from '../../../../assets/globalIcon.png';

type TldCellProps = BaseBacklinkCell & {};

const TldCell: React.FC<TldCellProps> = ({ item }) => {
  if (!item.backlinkMeta) {
    return <></>;
  }

  return (
    <Tooltip
      label={
        item?.backlinkMeta?.tldCountry
          ? item?.backlinkMeta?.tldCountry
          : item?.backlinkMeta?.tld
      }
    >
      {item?.backlinkMeta?.tldCountry &&
      item?.backlinkMeta?.tldCountry.length > 0 ? (
        <span>
          <ReactCountryFlag countryCode={item?.backlinkMeta?.tld ?? ''} />
        </span>
      ) : (
        <Image src={GlobalIcon} />
      )}
    </Tooltip>
  );
};

export default TldCell;
