import { BackLink, BacklinkScraperValueDifference, LinkMeta, ScraperValueDifference } from '@backlinkit/shared';
import { format } from 'date-fns';

const differenceExclusions = ['id', 'additionalDomainAnchors', 'sslValidFor', 'lastLiveDate'];

export const getBacklinkScrapeDataDifferences = (
  item: BackLink
): BacklinkScraperValueDifference => {
  const difference: BacklinkScraperValueDifference = {};
  difference.differenceCount = 0;

  if (item.previousLinkMeta) {
    const isLandingPageEqual =
      item.landingPage?.toString().toLocaleLowerCase() ===
      item.previousLinkMeta.landingPage?.toString().toLocaleLowerCase();

    difference.landingPage = {
      currentValue: item.landingPage ?? '',
      previousValue: item.previousLinkMeta.landingPage ?? '',
      isEqual: isLandingPageEqual,
    };

    difference.differenceCount = isLandingPageEqual
      ? difference.differenceCount
      : difference.differenceCount + 1;

    const isAnchorPageEqual =
      item.anchor?.toString().toLocaleLowerCase() ===
      item.previousLinkMeta.anchor?.toString().toLocaleLowerCase();

    difference.anchor = {
      currentValue: item.anchor ?? '',
      previousValue: item.previousLinkMeta.anchor ?? '',
      isEqual: isAnchorPageEqual,
    };

    difference.differenceCount = isAnchorPageEqual
      ? difference.differenceCount
      : difference.differenceCount + 1;

    if (item.backlinkMeta) {
      const isRelStatusEqual =
        item.backlinkMeta.relStatus?.toString().toLocaleLowerCase() ===
        item.previousLinkMeta.relStatus?.toString().toLocaleLowerCase();

      difference.relStatus = {
        currentValue: item.backlinkMeta.relStatus ?? '',
        previousValue: item.previousLinkMeta.relStatus ?? '',
        isEqual: isRelStatusEqual,
      };

      difference.differenceCount = isRelStatusEqual
        ? difference.differenceCount
        : difference.differenceCount + 1;

      const isRelValueEqual =
        item.backlinkMeta.relValue?.toString().toLocaleLowerCase() ===
        item.previousLinkMeta.relValue?.toString().toLocaleLowerCase();

      difference.relValue = {
        currentValue: item.backlinkMeta.relValue ?? '',
        previousValue: item.previousLinkMeta.relValue ?? '',
        isEqual: isRelValueEqual,
      };

      difference.differenceCount = isRelValueEqual
        ? difference.differenceCount
        : difference.differenceCount + 1;

      const isAnchorContextInfoEqual =
        item.backlinkMeta.anchorContextInfo?.toString().toLocaleLowerCase() ===
        item.previousLinkMeta.anchorContextInfo?.toString().toLocaleLowerCase();

      difference.anchorContextInfo = {
        currentValue: item.backlinkMeta.anchorContextInfo ?? '',
        previousValue: item.previousLinkMeta.anchorContextInfo ?? '',
        isEqual: isAnchorContextInfoEqual,
      };

      difference.differenceCount = isAnchorContextInfoEqual
        ? difference.differenceCount
        : difference.differenceCount + 1;

      const isRobotsIndexStatusEqual =
        item.backlinkMeta.robotsIndexStatus?.toString().toLocaleLowerCase() ===
        item.previousLinkMeta.robotsIndexStatus?.toString().toLocaleLowerCase();

      difference.robotsIndexStatus = {
        currentValue: item.backlinkMeta.robotsIndexStatus ?? '',
        previousValue: item.previousLinkMeta.robotsIndexStatus ?? '',
        isEqual: isRobotsIndexStatusEqual,
      };

      difference.differenceCount = isRobotsIndexStatusEqual
        ? difference.differenceCount
        : difference.differenceCount + 1;
    }

    if (item.backlinkGoogle) {
      const isCacheCreatedDateEqual =
        item.backlinkGoogle.cacheState?.toString().toLocaleLowerCase() ===
        item.previousLinkMeta.cacheState?.toString().toLocaleLowerCase();

      difference.cacheCreatedDate = {
        currentValue:
          item.backlinkGoogle.cacheState &&
          item.backlinkGoogle.cacheCreatedDate &&
          item.backlinkGoogle.cacheCreatedDate.toString().length > 0
            ? format(new Date(item.backlinkGoogle.cacheCreatedDate), 'dd MMM yyyy')
            : '',
        previousValue:
          item.previousLinkMeta.cacheCreatedDate &&
          item.previousLinkMeta.cacheCreatedDate.toString().length > 0
            ? format(new Date(item.previousLinkMeta.cacheCreatedDate), 'dd MMM yyyy')
            : '',
        isEqual: isCacheCreatedDateEqual,
      };

      difference.differenceCount = isCacheCreatedDateEqual
        ? difference.differenceCount
        : difference.differenceCount + 1;

      const isHasDomainIndexEqual =
        item.backlinkGoogle.hasDomainIndex?.toString().toLocaleLowerCase() ===
        item.previousLinkMeta.hasDomainIndex?.toString().toLocaleLowerCase();

      difference.hasDomainIndex = {
        currentValue: item.backlinkGoogle.hasDomainIndex?.toString() ?? '',
        previousValue: item.previousLinkMeta.hasDomainIndex?.toString() ?? '',
        isEqual: isHasDomainIndexEqual,
      };

      difference.differenceCount = isHasDomainIndexEqual
        ? difference.differenceCount
        : difference.differenceCount + 1;

      const isHasPageIndexEqual =
        item.backlinkGoogle.hasPageIndex?.toString().toLocaleLowerCase() ===
        item.previousLinkMeta.hasPageIndex?.toString().toLocaleLowerCase();

      difference.hasPageIndex = {
        currentValue: item.backlinkGoogle.hasPageIndex?.toString() ?? '',
        previousValue: item.previousLinkMeta.hasPageIndex?.toString() ?? '',
        isEqual: isHasPageIndexEqual,
      };

      difference.differenceCount = isHasPageIndexEqual
        ? difference.differenceCount
        : difference.differenceCount + 1;
    }
  }

  return difference;
};
