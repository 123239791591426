import { Icon, Tooltip } from '@chakra-ui/react';
import { format } from 'date-fns';
import React from 'react';
import { FaUserCircle } from 'react-icons/fa';
import { selectUser } from '../../../../store/slices/authentication.slice';
import { useAppSelector } from '../../../../store/store';
import { AppColors } from '@backlinkit/shared';
import { BaseBacklinkCell } from './base-backlink-cell';

type LastModifiedCellProps = BaseBacklinkCell & {};

const LastModifiedCell: React.FC<LastModifiedCellProps> = ({ item }) => {
  const user = useAppSelector(selectUser);

  if (item.lastModified) {
    const lmDate = format(new Date(item.lastModified), 'dd MMM yyyy');

    return (
      <Tooltip label={`Last modified - ${lmDate} by ${user?.email}`}>
        <span>
          <Icon as={FaUserCircle} boxSize={'1em'} color={AppColors.secondary} />
        </span>
      </Tooltip>
    );
  } else {
    return <></>;
  }
};

export default LastModifiedCell;
