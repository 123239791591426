import React, { useEffect, useState } from 'react';
import {
  Box,
  Flex,
  FormLabel,
  Input,
  Table,
  TableContainer,
  Tag,
  TagLabel,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { domainURLRegex } from '../../constants/regex';
import { yupResolver } from '@hookform/resolvers/yup';
import { BaseFormProps } from './base-forms';
import {
  AppInput,
  AppButton,
  Organization,
  AppColors,
  AppText,
  SelectOption,
  SelectListFilter,
} from '@backlinkit/shared';
import { useFetchAllOrganizationsQuery } from '../../store/api/organizationApi';
import { SearchIcon } from '@chakra-ui/icons';
import { useFetchAllNicheTypesQuery } from '../../store/api/nicheTypesApi';
import { useFetchAllDomainUserRoleTypesQuery } from '../../store/api/domainUserRoleApi';

export type DomainFormData = {
  id?: string;
  name: string;
  url: string;
  domainUserRoleTypeId?: string;
  domainNicheTypeId?: string;
  organization?: Organization;
  organizationId?: string;
};

export const domainFormDataDefaultValues: DomainFormData = {
  name: '',
  url: '',
  organization: undefined,
  organizationId: '',
  domainUserRoleTypeId: undefined,
  domainNicheTypeId: undefined,
};

const domainFormDataSchema = yup.object({
  name: yup.string().required('Field is required'),
  url: yup.string().required('Field is required').matches(domainURLRegex, 'Not a valid Url'),
  organizationId: yup.string(),
  domainUserRoleId: yup.string(),
  domainNicheTypedId: yup.string(),
});

type DomainCreateFormProps<T> = {
  form?: DomainFormData;
} & BaseFormProps<T>;

const DomainCreateForm: React.FC<DomainCreateFormProps<DomainFormData>> = ({ form, onSubmit }) => {
  const {
    control: domainControl,
    formState: { isValid, errors },
    setValue,
    handleSubmit,
  } = useForm<DomainFormData>({
    defaultValues: form || domainFormDataDefaultValues,
    resolver: yupResolver(domainFormDataSchema),
    mode: 'onChange',
  });

  const { data: organizations, refetch: refetchOrganizations } = useFetchAllOrganizationsQuery({
    refetchOnMountArgChange: true,
  });
  const [selectedOrganization, setSelectedOrganization] = useState<Organization>();
  const [organizationSearchResponse, setOrganizationSearchResponse] = useState<Organization[]>([]);
  const [searchParam, setSearchParam] = useState<string>();

  const postSearch = (name: string) => {
    if (!name) {
      setOrganizationSearchResponse(organizations!);
    }

    if (!organizations) return;
    if (name) {
      const lowerCaseSearchParam = name.toLowerCase();
      const foundOrganizations = organizations.filter((x) => {
        return x.name.toLowerCase().includes(lowerCaseSearchParam);
      });
      setOrganizationSearchResponse(foundOrganizations);
    }
  };

  const { data: domainUserRoles } = useFetchAllDomainUserRoleTypesQuery({
    refetchOnMountArgChange: true,
  });
  const { data: domainNicheTypes } = useFetchAllNicheTypesQuery({
    refetchOnMountArgChange: true,
  });

  const [userRoleOptions, setUserRoleOptions] = useState<SelectOption[]>();
  const [domainNicheTypeOptions, setDomainNicheTypeOptions] = useState<SelectOption[]>();

  useEffect(() => {
    const roleSelectors: SelectOption[] = [];
    domainUserRoles?.map((x) => {
      roleSelectors.push({
        label: x.name,
        value: x.id,
      } as SelectOption);
    });
    setUserRoleOptions(roleSelectors);
    const nicheSelectors: SelectOption[] = [];
    domainNicheTypes?.map((x) => {
      nicheSelectors.push({
        label: x.name,
        value: x.id,
      });
    });
    setDomainNicheTypeOptions(nicheSelectors);
  }, []);

  return (
    <Flex direction={'column'}>
      <form
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: 10,
          paddingBottom: 18,
          width: '100%',
        }}
      >
        <FormLabel m={0}>Search Organizations</FormLabel>
        <Flex w={'full'} gap={3}>
          <Input
            w={'full'}
            placeholder="Search Organizations"
            onChange={(e) => {
              e.preventDefault();
              setSearchParam(e.currentTarget.value);
            }}
          />
          <AppButton
            rightIcon={<SearchIcon />}
            bgColor={AppColors.iconColorOne}
            color={'white'}
            onClick={() => {
              postSearch(searchParam!);
            }}
          >
            Search
          </AppButton>
        </Flex>
        {organizationSearchResponse && organizationSearchResponse.length > 0 && (
          <Box maxH={'200px'} overflowY={'scroll'}>
            <TableContainer>
              <Table variant={'simple'}>
                <Thead>
                  <Tr>
                    <Th>Name</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {organizationSearchResponse.map((selectedOrg, index) => {
                    return (
                      <Tr key={index}>
                        <Td>
                          <AppText>{selectedOrg.name}</AppText>
                        </Td>
                        <Td>
                          <AppButton
                            bgColor={AppColors.iconColorOne}
                            color={'white'}
                            variant={'solid'}
                            size={'xs'}
                            borderRadius={'full'}
                            onClick={() => {
                              setSelectedOrganization(selectedOrg);
                              setValue('organization', selectedOrg);
                              setValue('organizationId', selectedOrg.id);
                            }}
                          >
                            Select
                          </AppButton>
                        </Td>
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
            </TableContainer>
          </Box>
        )}
        {selectedOrganization && (
          <Flex w={'full'} align={'center'} justify={'start'}>
            <AppText>Selected Organziation:</AppText>
            <Tag borderRadius={'full'} colorScheme="grey" alignItems={'center'}>
              <TagLabel>{selectedOrganization.name}</TagLabel>
            </Tag>
          </Flex>
        )}
      </form>
      <form>
        <AppInput<DomainFormData>
          control={domainControl}
          name="name"
          label="Domain Name"
          placeHolder="Domain Name"
          error={errors.name}
        />
        <AppInput<DomainFormData>
          mt={6}
          control={domainControl}
          error={errors.url}
          label="Url"
          placeHolder="https://example.com"
          name="url"
        />
        <FormLabel p={0} m={0}>
          Domain Niche Type
        </FormLabel>
        <SelectListFilter
          name="domainNicheType"
          w={'full'}
          showClear={false}
          isInModal
          isMulti={false}
          options={domainNicheTypeOptions}
          onSelectionChange={(item: SelectOption[]) => {
            setValue('domainUserRoleTypeId', item[0].value);
          }}
        />
        <FormLabel p={0} m={0}>
          Domain User Role Type
        </FormLabel>
        <SelectListFilter
          name="userRole"
          w={'full'}
          showClear={false}
          isInModal
          isMulti={false}
          options={userRoleOptions}
          onSelectionChange={(item: SelectOption[]) => {
            setValue('domainUserRoleTypeId', item[0].value);
          }}
        />
        <AppButton w={'full'} mt={8} disabled={!isValid} onClick={handleSubmit(onSubmit)}>
          Save
        </AppButton>
      </form>
    </Flex>
  );
};

export default DomainCreateForm;
