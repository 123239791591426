import { createApi } from '@reduxjs/toolkit/dist/query/react';
import {
  DomainUserRoleTypeDto,
  DomainUserRoleEditFormData,
  DomainUserRoleFormData,
} from '@backlinkit/shared';
import { AppFetchBaseQuery } from './apiBase';

export const domainUserRoleApi = createApi({
  reducerPath: 'domainUserRoleApi',
  baseQuery: AppFetchBaseQuery,
  endpoints: (build) => ({
    fetchAllDomainUserRoleTypes: build.query<DomainUserRoleTypeDto[], any>({
      query: () => ({
        url: '/domainuserroletype',
        method: 'GET',
      }),
    }),
    fetchDomainUserRoleTypeById: build.query<DomainUserRoleTypeDto, string>({
      query: (id) => ({
        url: `/domainuserroletype/${id}`,
        method: 'GET',
      }),
    }),
    createDomainUserRoleType: build.mutation<DomainUserRoleTypeDto, DomainUserRoleFormData>({
      query: (body) => ({
        url: 'domainuserroletype/create',
        method: 'POST',
        body: body,
      }),
    }),
    updateDomainUserRoleType: build.mutation<DomainUserRoleTypeDto, DomainUserRoleEditFormData>({
      query: (body) => ({
        url: `/domainuserroletype/update/${body.id}`,
        method: 'PUT',
        body: body,
      }),
    }),
    deleteDomainUserRoleType: build.mutation<string, string>({
      query: (id) => ({
        url: `/domainuserroletype/delete/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useCreateDomainUserRoleTypeMutation,
  useDeleteDomainUserRoleTypeMutation,
  useFetchAllDomainUserRoleTypesQuery,
  useFetchDomainUserRoleTypeByIdQuery,
  useLazyFetchAllDomainUserRoleTypesQuery,
  useLazyFetchDomainUserRoleTypeByIdQuery,
  useUpdateDomainUserRoleTypeMutation,
} = domainUserRoleApi;
