import { Base } from './base';
import { Organization } from './organization';

export enum OrganizationRoleType {
  ADMIN,
  BACKLINKER,
}

export interface UserAuth {
  refreshToken: string;
  token: string;
  user: User;
}

export interface User extends Base {
  email: string;
  password: string;
  organizationRoleId: OrganizationRoleType;
  organizationId: string;
  organization: Organization;
  hasSignedIn: boolean;
}

export interface UserRegisterForm {
  email: string;
  password: string;
  organizationName: string;
}

export interface UserCreateForm {
  email: string;
  password: string;
  organizationId: string;
  organizationRoleId: OrganizationRoleType;
}

export interface UserEditForm {
  id: string;
  email: string;
  organizationRoleId: OrganizationRoleType;
  organizationId: string;
}

export interface UserSearchRequest {
  name: string;
}

export interface UserSearchResponse {
  email: string;
  organizationId: string;
  id: string;
}

export interface PasswordUpsertRequest {
  id: string;
  password: string;
}
