import { createApi } from '@reduxjs/toolkit/query/react';
import { FAQTypes, FAQTypeForm, FAQTypeEditForm } from '@backlinkit/shared';
import { AppFetchBaseQuery } from './apiBase';

export const faqCategoryApi = createApi({
  reducerPath: 'faqCategoryApi',
  baseQuery: AppFetchBaseQuery,
  endpoints: (build) => ({
    fetchFaqCategoryById: build.query<FAQTypes, string>({
      query: (id) => ({
        url: `/faqCategory/${id}`,
        method: 'GET',
      }),
    }),
    fetchAllFaqCategories: build.query<FAQTypes[], any>({
      query: () => ({
        url: '/faqCategory',
        method: 'GET',
      }),
    }),
    createFaqCategory: build.mutation<FAQTypes, FAQTypeForm>({
      query: (body) => ({
        url: '/faqCategory/create',
        method: 'POST',
        body: body,
      }),
    }),
    updateFaqCategory: build.mutation<FAQTypes, FAQTypeEditForm>({
      query: (payload) => ({
        url: `/faqCategory/update/${payload.id}`,
        method: 'PUT',
        body: payload,
      }),
    }),
    deleteFaqCategory: build.mutation<FAQTypes, string>({
      query: (id) => ({
        url: `/faqCategory/delete/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useFetchFaqCategoryByIdQuery,
  useFetchAllFaqCategoriesQuery,
  useLazyFetchAllFaqCategoriesQuery,
  useCreateFaqCategoryMutation,
  useUpdateFaqCategoryMutation,
  useDeleteFaqCategoryMutation,
} = faqCategoryApi;
