import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { NicheType, NicheTypeEditForm, NicheTypeForm } from '@backlinkit/shared';
import { AppFetchBaseQuery } from './apiBase';

export const nicheTypeApi = createApi({
  reducerPath: 'nicheTypeApi',
  baseQuery: AppFetchBaseQuery,
  endpoints: (build) => ({
    fetchAllNicheTypes: build.query<NicheType[], any>({
      query: () => ({
        url: '/nichetype',
        method: 'GET',
      }),
    }),
    fetchNicheTypeById: build.query<NicheType, string>({
      query: (id) => ({
        url: `/nichetype/${id}`,
        method: 'GET',
      }),
    }),
    createNicheType: build.mutation<NicheType, NicheTypeForm>({
      query: (body) => ({
        url: '/nichetype/create',
        method: 'POST',
        body: body,
      }),
    }),
    updateNicheType: build.mutation<NicheType, NicheTypeEditForm>({
      query: (body) => ({
        url: `/nichetype/update/${body.id}`,
        method: 'PUT',
        body: body,
      }),
    }),
    deleteNicheType: build.mutation<NicheType, string>({
      query: (id) => ({
        url: `/nichetype/delete/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useCreateNicheTypeMutation,
  useDeleteNicheTypeMutation,
  useFetchAllNicheTypesQuery,
  useUpdateNicheTypeMutation,
  useFetchNicheTypeByIdQuery,
  useLazyFetchAllNicheTypesQuery,
  useLazyFetchNicheTypeByIdQuery,
} = nicheTypeApi;
