import { createApi } from '@reduxjs/toolkit/query/react';
import { PaginatedData } from '@backlinkit/shared';
import { AppFetchBaseQuery } from './apiBase';
import { UserCheckerLinkBulkCreateRequest, UserGoogleCheckerLink } from '@backlinkit/shared';

export const googleCheckerApi = createApi({
  reducerPath: 'googleCheckerApi',
  baseQuery: AppFetchBaseQuery,
  endpoints: (build) => ({
    fetchGoogleCheckerItemsByDomain: build.query<PaginatedData<UserGoogleCheckerLink>, string>({
      query: (userId) => ({
        url: `/usercheckerlink/by/user/${userId}`,
        method: 'GET',
      }),
      transformResponse: (response: UserGoogleCheckerLink[]) => {
        return { data: response } as PaginatedData<UserGoogleCheckerLink>;
      },
    }),
    userCheckerLinkBulkCreate: build.query<any, UserCheckerLinkBulkCreateRequest>({
      query: (data) => ({
        url: `/usercheckerlink/create/bulk`,
        method: 'POST',
        body: JSON.stringify(data),
      }),
    }),
    userCheckerLinkBulkDelete: build.query<any, string>({
      query: (userId) => ({
        url: `/usercheckerlink/delete/by/userId/${userId}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useLazyFetchGoogleCheckerItemsByDomainQuery,
  useLazyUserCheckerLinkBulkCreateQuery,
  useLazyUserCheckerLinkBulkDeleteQuery,
} = googleCheckerApi;
