import { createApi } from '@reduxjs/toolkit/dist/query/react';
import {
  OrganizationLinkEstimationTypeDto,
  OrganizationTypeEditFormData,
  OrganizationTypeFormData,
} from '@backlinkit/shared';
import { AppFetchBaseQuery } from './apiBase';

export const organizationLinkEstimationTypeApi = createApi({
  reducerPath: 'organizationLinkEstimationType',
  baseQuery: AppFetchBaseQuery,
  endpoints: (build) => ({
    fetchAllOrganizationLinkEstimationTypes: build.query<OrganizationLinkEstimationTypeDto[], any>({
      query: () => ({
        url: '/organizationLinkEstimationType',
        method: 'GET',
      }),
    }),
    fetchOrganizationLinkEstimationTypeById: build.query<OrganizationLinkEstimationTypeDto, string>(
      {
        query: (id) => ({
          url: '/organizationLinkEstimationType',
          method: 'GET',
        }),
      }
    ),
    createOrganizationLinkEstimationType: build.mutation<
      OrganizationLinkEstimationTypeDto,
      OrganizationTypeFormData
    >({
      query: (body) => ({
        url: '/organizationLinkEstimationType/create',
        method: 'POST',
        body: body,
      }),
      transformResponse: (response: OrganizationLinkEstimationTypeDto) => {
        return response;
      },
    }),
    updateOrganizationLinkEstimationType: build.mutation<
      OrganizationLinkEstimationTypeDto,
      OrganizationTypeEditFormData
    >({
      query: (body) => ({
        url: `/organizationLinkEstimationType/update/${body.id}`,
        method: 'PUT',
        body: body,
      }),
    }),
    deleteOrganizationLinkEstimationType: build.mutation<OrganizationLinkEstimationTypeDto, string>(
      {
        query: (id) => ({
          url: `/organizationLinkEstimationType/delete/${id}`,
          method: 'DELETE',
        }),
      }
    ),
  }),
});

export const {
  useCreateOrganizationLinkEstimationTypeMutation,
  useDeleteOrganizationLinkEstimationTypeMutation,
  useFetchAllOrganizationLinkEstimationTypesQuery,
  useFetchOrganizationLinkEstimationTypeByIdQuery,
  useUpdateOrganizationLinkEstimationTypeMutation,
} = organizationLinkEstimationTypeApi;
