import { createApi } from '@reduxjs/toolkit/query/react';
import { AppFetchBaseQuery } from './apiBase';
import { OrganizationTag, OrganizationTagEditForm, OrganizationTagForm } from '@backlinkit/shared';

export const tagApi = createApi({
  reducerPath: 'tagApi',
  baseQuery: AppFetchBaseQuery,
  endpoints: (build) => ({
    fetchTagsByOrganizationId: build.query<OrganizationTag[], string>({
      query: (orgId) => ({
        url: `/tag/by/organization/${orgId}`,
        method: 'GET',
      }),
      transformResponse: (response: OrganizationTag[]) => {
        const tagsData = response;
        return tagsData;
      },
    }),
    createTag: build.mutation<OrganizationTag, OrganizationTagForm>({
      query: (payload) => ({
        url: `/tag/create`,
        method: 'POST',
        body: payload,
      }),
    }),
    editTag: build.mutation<OrganizationTag, OrganizationTagEditForm>({
      query: (payload) => ({
        url: `/tag/update/${payload.id}`,
        method: 'PUT',
        body: payload,
      }),
    }),
    deleteTag: build.mutation<OrganizationTag, string>({
      query: (tagId) => ({
        url: `/tag/delete/${tagId}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useLazyFetchTagsByOrganizationIdQuery,
  useFetchTagsByOrganizationIdQuery,
  useCreateTagMutation,
  useEditTagMutation,
  useDeleteTagMutation,
} = tagApi;
