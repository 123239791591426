import BaseLayout from '../../components/layouts/base-layout';
import { Box } from '@chakra-ui/react';
import UsersTable from '../../components/app/app-organization-users/organizationUsers';

export const UserPage: React.FC = () => {
  return (
    <BaseLayout>
      <Box w={'100%'} display={'flex'} flexDir={'column'}>
        <UsersTable />
      </Box>
    </BaseLayout>
  );
};
