import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { ProductDto, ProductForm, ProductEditForm } from '@backlinkit/shared';
import { AppFetchBaseQuery } from './apiBase';

export const productsApi = createApi({
  reducerPath: 'productsApi',
  baseQuery: AppFetchBaseQuery,
  endpoints: (build) => ({
    fetchAllProducts: build.query<ProductDto[], any>({
      query: () => ({
        url: '/product',
        method: 'GET',
      }),
    }),
    fetchProductById: build.query<ProductDto, string>({
      query: (id) => ({
        url: `/product/${id}`,
        method: 'GET',
      }),
    }),
    createProduct: build.mutation<ProductDto, ProductForm>({
      query: (body) => ({
        url: '/product/create',
        method: 'POST',
        body: body,
      }),
      transformResponse: (response: ProductDto) => {
        return response;
      },
    }),
    editProduct: build.mutation<ProductDto, ProductEditForm>({
      query: (body) => ({
        url: `/product/update/${body.id}`,
        method: 'PUT',
        body: body,
      }),
      transformResponse: (response: ProductDto) => {
        return response;
      },
    }),
    deleteProduct: build.mutation<ProductDto, string>({
      query: (id) => ({
        url: `/product/delete/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useFetchAllProductsQuery,
  useFetchProductByIdQuery,
  useCreateProductMutation,
  useEditProductMutation,
  useDeleteProductMutation,
} = productsApi;
