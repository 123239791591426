import {
  IconButton,
  Box,
  CloseButton,
  Flex,
  HStack,
  VStack,
  Icon,
  useColorModeValue,
  Text,
  Drawer,
  DrawerContent,
  useDisclosure,
  BoxProps,
  FlexProps,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Image,
  Link,
} from '@chakra-ui/react';
import { FiMenu, FiBell, FiChevronDown } from 'react-icons/fi';
import Logo from '../../assets/backlinkit_logo.svg';
import { useDispatch, useSelector } from 'react-redux';
import { logout, selectUser } from '../../store/slices/authentication.slice';
import { RoutesList } from '../../router/router';
import { useNavigate } from 'react-router-dom';
import { NavLink as RouterLink } from 'react-router-dom';
import { FaUserCircle } from 'react-icons/fa';
import { ReactNode } from 'react';
import { NavItemCollapse } from './nav-item-collapse';
import { HeaderNavItems } from '../../constants/headerNav';
import { AppColors, NavItem } from '@backlinkit/shared';
var packageJson = require('../../../package.json');

interface MobileProps extends FlexProps {
  onOpen: () => void;
}

interface SidebarProps extends BoxProps {
  onClose: () => void;
}

export type BaseLayoutProps = {
  children?: ReactNode | ReactNode[];
};

const BaseLayout: React.FC<BaseLayoutProps> = ({ children }) => {
  const version = packageJson.version;
  const { isOpen, onOpen, onClose } = useDisclosure();

  const userDisplayName = useSelector(selectUser)?.email;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onLogout = () => {
    dispatch(logout());
    navigate(RoutesList.Index);
  };

  const handleUserProfileClick = () => {
    navigate(RoutesList.UserProfile);
  };

  const SidebarContent = ({ onClose, ...rest }: SidebarProps) => {
    return (
      <Box
        transition="3s ease"
        bg={'white'}
        borderRight="1px"
        borderRightColor={AppColors.appBorder}
        w={{ base: 'full', md: '80' }}
        pos="fixed"
        h="full"
        {...rest}
        overflowY={'scroll'}
      >
        <Flex
          h="20"
          alignItems="center"
          justifyContent="space-between"
          pos={'fixed'}
          bgColor={'white'}
          p={4}
        >
          <Image src={Logo} alt="" height={'10'} objectFit={'contain'} objectPosition={'left'} />
          <CloseButton
            size={'lg'}
            color={`${AppColors.primary}`}
            display={{ base: 'flex', md: 'none' }}
            onClick={onClose}
          />
        </Flex>
        <Flex flexDir={'column'} marginTop={'20'}>
          {HeaderNavItems.map((link) =>
            link.children ? (
              <NavItemCollapse key={link.label} name={link.label} children={link.children} />
            ) : (
              <NavItemComponent key={link.label} label={link.label} href={link.href} />
            )
          )}
        </Flex>
      </Box>
    );
  };

  const NavItemComponent: React.FC<NavItem> = ({ label, href }) => {
    return (
      <Link
        as={RouterLink}
        to={href ?? ''}
        style={{ textDecoration: 'none' }}
        _focus={{ boxShadow: 'none' }}
      >
        <Flex
          align="center"
          p="4"
          mx="4"
          borderRadius="lg"
          role="group"
          cursor="pointer"
          _hover={{
            bg: 'white',
            color: AppColors.primary,
          }}
        >
          <Box
            color={`${AppColors.primary}`}
            _groupHover={{
              color: AppColors.secondary,
            }}
            fontWeight={'500'}
          >
            {label}
          </Box>
        </Flex>
      </Link>
    );
  };

  const MobileNav = ({ onOpen, ...rest }: MobileProps) => {
    return (
      <Flex
        ml={{ base: 0, md: 60 }}
        px={{ base: 4, md: 4 }}
        height="20"
        alignItems="center"
        bg={useColorModeValue('white', 'gray.900')}
        borderBottomWidth="1px"
        borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
        justifyContent={{ base: 'space-between', md: 'flex-end' }}
        {...rest}
      >
        <IconButton
          display={{ base: 'flex', md: 'none' }}
          onClick={onOpen}
          variant="outline"
          aria-label="open menu"
          icon={<FiMenu />}
        />

        <Text
          display={{ base: 'flex', md: 'none' }}
          fontSize="2xl"
          fontFamily="monospace"
          fontWeight="bold"
        >
          Logo
        </Text>

        <HStack spacing={{ base: '0', md: '6' }}>
          <IconButton size="lg" variant="ghost" aria-label="open menu" icon={<FiBell />} />
          <Flex alignItems={'center'}>
            <Menu>
              <MenuButton py={2} transition="all 0.3s" _focus={{ boxShadow: 'none' }}>
                <HStack>
                  <Flex
                    h={'10'}
                    w={'10'}
                    borderRadius={'100%'}
                    bg={AppColors.secondary}
                    border={`1px solid ${AppColors.appBorder}`}
                    color={'white'}
                    alignItems={'center'}
                    justifyContent={'center'}
                  >
                    <Icon as={FaUserCircle} boxSize={'1em'} color={'white'} />
                  </Flex>
                  <VStack
                    display={{ base: 'none', md: 'flex' }}
                    alignItems="flex-start"
                    spacing="1px"
                    ml="2"
                  >
                    <Text fontSize="sm">{userDisplayName}</Text>
                    <Text fontSize="xs" color="gray.600">
                      Admin
                    </Text>
                  </VStack>
                  <Box display={{ base: 'none', md: 'flex' }}>
                    <FiChevronDown />
                  </Box>
                </HStack>
              </MenuButton>
              <MenuList
                bg={useColorModeValue('white', 'gray.900')}
                borderColor={useColorModeValue('gray.200', 'gray.700')}
              >
                <MenuItem onClick={handleUserProfileClick}>Profile</MenuItem>
                <MenuDivider />
                <MenuItem onClick={onLogout}>Sign out</MenuItem>
                <MenuDivider />

                <Text fontSize={'small'} pr={2} textAlign={'end'}>
                  Version: {version}
                </Text>
              </MenuList>
            </Menu>
          </Flex>
        </HStack>
      </Flex>
    );
  };

  return (
    <Box minH="100vh" bg={`${AppColors.appBackground}`} overflowY={'scroll'}>
      <SidebarContent onClose={() => onClose} display={{ base: 'none', md: 'block' }} />
      <Drawer
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>
      {/* mobilenav */}
      <MobileNav onOpen={onOpen} />
      <Flex ml={{ base: 0, md: 80 }} id="base-layout-container">
        {children}
      </Flex>
    </Box>
  );
};

export default BaseLayout;
