import { createApi } from '@reduxjs/toolkit/dist/query/react';
import {
  OrganizationSourceEntityType,
  CreateOrganizationSourceEntityTypeForm,
  EditOrganizationSourceEntityTypeForm,
} from '@backlinkit/shared';
import { AppFetchBaseQuery } from './apiBase';

export const organizationSourceEntityTypeApi = createApi({
  reducerPath: 'organizationSourceTypeApi',
  baseQuery: AppFetchBaseQuery,
  endpoints: (build) => ({
    fetchAllOrganizationSourceTypes: build.query<OrganizationSourceEntityType[], any>({
      query: () => ({
        url: 'organizationsourcetype',
        method: 'GET',
      }),
    }),
    fetchOrganizationSourceTypeById: build.query<OrganizationSourceEntityType, string>({
      query: (id) => ({
        url: `/organizationsourcetype/${id}`,
        method: 'GET',
      }),
    }),
    createOrganizationSourceType: build.mutation<
      OrganizationSourceEntityType,
      CreateOrganizationSourceEntityTypeForm
    >({
      query: (body) => ({
        url: `/organizationsourcetype/create`,
        method: 'POST',
        body: body,
      }),
    }),
    updateOrganizationSourceType: build.mutation<
      OrganizationSourceEntityType,
      EditOrganizationSourceEntityTypeForm
    >({
      query: (body) => ({
        url: `/organizationsourcetype/update/${body.id}`,
        method: 'PUT',
        body: body,
      }),
    }),
    deleteOrganizationSourceType: build.mutation<string, string>({
      query: (id) => ({
        url: `/organizationsourcetype/delete/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useCreateOrganizationSourceTypeMutation,
  useDeleteOrganizationSourceTypeMutation,
  useFetchAllOrganizationSourceTypesQuery,
  useFetchOrganizationSourceTypeByIdQuery,
  useUpdateOrganizationSourceTypeMutation,
} = organizationSourceEntityTypeApi;
