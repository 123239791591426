import React from 'react';
import { Flex } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { BaseFormProps } from './base-forms';
import { AppButton, AppInput } from '@backlinkit/shared';
import { NicheTypeForm } from '@backlinkit/shared';

export const domainNIcheTypeDefaultValues: NicheTypeForm = {
  name: '',
  description: '',
};

export const domainNicheTypeFormDataSchema = yup.object({
  name: yup.string().required('Field is required'),
  description: yup.string(),
});

type DomainNicheTypeFormProps<T> = {
  form?: NicheTypeForm;
} & BaseFormProps<T>;

const DomainNicheTypeForm: React.FC<DomainNicheTypeFormProps<NicheTypeForm>> = ({
  form,
  onSubmit,
}) => {
  const {
    control: nicheControl,
    formState: { isValid, errors },
    handleSubmit,
  } = useForm<NicheTypeForm>({
    defaultValues: form || domainNIcheTypeDefaultValues,
    resolver: yupResolver(domainNicheTypeFormDataSchema),
    mode: 'onChange',
  });

  return (
    <Flex direction={'column'} gap={3}>
      <AppInput<NicheTypeForm>
        name="name"
        control={nicheControl}
        error={errors.name}
        label="Niche Name"
      />
      <AppInput<NicheTypeForm>
        name="description"
        control={nicheControl}
        error={errors.description}
        label="Description"
      />
      <AppButton w={'full'} mt={8} disabled={!isValid} onClick={handleSubmit(onSubmit)}>
        Save
      </AppButton>
    </Flex>
  );
};

export default DomainNicheTypeForm;
