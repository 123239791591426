import { createApi } from '@reduxjs/toolkit/dist/query/react';
import {
  OrganizationTypeDto,
  OrganizationTypeFormData,
  OrganizationTypeEditFormData,
} from '@backlinkit/shared';
import { AppFetchBaseQuery } from './apiBase';

export const organizationTypeApi = createApi({
  reducerPath: 'organizationTypeApi',
  baseQuery: AppFetchBaseQuery,
  endpoints: (build) => ({
    fetchAllOrganizationTypes: build.query<OrganizationTypeDto[], any>({
      query: () => ({
        url: '/organizationtype',
        method: 'GET',
      }),
    }),
    fetchOrganizationTypeById: build.query<OrganizationTypeDto, string>({
      query: (id) => ({
        url: `/organizationtype/${id}`,
        method: 'GET',
      }),
    }),
    createOrganizationType: build.mutation<OrganizationTypeDto, OrganizationTypeFormData>({
      query: (body) => ({
        url: `/organizationtype/create`,
        method: 'POST',
        body: body,
      }),
    }),
    updateOrganizationType: build.mutation<OrganizationTypeDto, OrganizationTypeEditFormData>({
      query: (body) => ({
        url: `/organizationtype/update/${body.id}`,
        method: 'PUT',
        body: body,
      }),
    }),
    deleteOrganizationType: build.mutation<OrganizationTypeDto, string>({
      query: (id) => ({
        url: `/organizationtype/delete/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useCreateOrganizationTypeMutation,
  useDeleteOrganizationTypeMutation,
  useFetchAllOrganizationTypesQuery,
  useFetchOrganizationTypeByIdQuery,
  useUpdateOrganizationTypeMutation,
} = organizationTypeApi;
