import { ProductDto, AppText } from '@backlinkit/shared';
import { Flex } from '@chakra-ui/react';

type PlanCellProps = {
  item: ProductDto;
};

const FaqCategoryCell: React.FC<PlanCellProps> = ({ item }) => {
  return (
    <Flex>
      <AppText fontSize={'sm'}>{item?.type === 0 ? 'Subscription' : 'Checker Credits'}</AppText>
    </Flex>
  );
};

export default FaqCategoryCell;
